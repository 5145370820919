import {
  ChatBubbleLeftRightIcon,
  MegaphoneIcon,
  LightBulbIcon,
} from "@heroicons/vue/24/solid";
import { ref, computed } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { useWePanelStore } from "@/stores/wePanel";
import api from "@/router/api";
import _ from "lodash";

export const useWeDiscussionTopicFormStore = defineStore(
  "weDiscussionTopicForm",
  () => {
    const wePanelStore = useWePanelStore();
    const { activeHubId } = storeToRefs(wePanelStore);
    const parentCategory = ref(null);
    const topicName = ref("");
    const privateTopic = ref(false);
    const topicTypes = ref([
      {
        name: "Text",
        description: "Send messages, questions, links, opinions, etc.",
        selected: true,
        icon: LightBulbIcon,
      },
      {
        name: "Spotlight",
        description: "Organize discussions around specific topics.",
        selected: false,
        icon: ChatBubbleLeftRightIcon,
      },
      {
        name: "Announcement",
        description: "Important updates for people in and out of the Hub.",
        selected: false,
        icon: MegaphoneIcon,
      },
    ]);
    const selectedTopicType = ref(topicTypes.value[0]);
    const roles = ref([]);
    const members = ref([]);

    const canSave = computed(() => {
      return selectedTopicType.value && _.trim(topicName.value) !== "";
    });

    function setSelectedType(topic) {
      selectedTopicType.value = topic;
    }

    async function save() {
      if (canSave.value) {
        const payload = {
          hubId: activeHubId.value,
          categoryId: parentCategory.value?.id,
          name: topicName.value,
          topicType: selectedTopicType.value.name,
          isPrivate: privateTopic.value,
          roleIds: roles.value.map(({ id }) => id),
          userIds: members.value.map(({ userId }) => userId),
        };

        const topicResponse = await api.post(`discussion_topics`, payload);

        wePanelStore.patchChannel(topicResponse.data);
        wePanelStore.closeSecondaryContentPanel();
        reset();
      }
    }

    function reset() {
      parentCategory.value = null;
      topicName.value = "";
      privateTopic.value = false;
      selectedTopicType.value = topicTypes.value[0];
      roles.value = [];
      members.value = [];
    }

    return {
      parentCategory,
      topicName,
      privateTopic,
      topicTypes,
      selectedTopicType,
      roles,
      members,
      canSave,
      setSelectedType,
      reset,
      save,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useWeDiscussionTopicFormStore, import.meta.hot),
  );
}
