<template>
  <div class="h-full w-full flex flex-col divide-y divide-gray-300">
    <div class="p-2 flex items-center justify-between gap-4">
      <div class="flex items-center gap-x-2">
        <ChatBubbleLeftRightIcon class="size-5 text-gray-500" />
        <span class="font-semibold text-gray-900">Threads</span>
      </div>
      <div v-if="expandedView" class="grid w-full grid-cols-1 max-w-xs">
        <input
          type="search"
          name="search"
          class="col-start-1 row-start-1 block w-full rounded-md bg-white py-1 pl-10 pr-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
          placeholder="Search"
        />
        <MagnifyingGlassIcon
          class="pointer-events-none col-start-1 row-start-1 ml-3 size-5 self-center text-gray-400"
          aria-hidden="true"
        />
      </div>
      <div class="flex items-center gap-x-2">
        <button
          v-close-popper
          type="button"
          class="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="create"
        >
          Create
        </button>
        <button
          v-close-popper
          type="button"
          class="flex items-center justify-center text-gray-900 hover:text-gray-700"
          @click="close"
        >
          <XMarkIcon class="size-6" aria-hidden="true" />
        </button>
      </div>
    </div>
    <div class="p-2 space-y-4">
      <ol
        v-for="group in displayableThreadGroups"
        :key="group.name"
        class="space-y-2"
      >
        <h2 class="text-xs font-semibold uppercase text-gray-700">
          {{ pluralize(group.label, group.threads.length, true) }}
        </h2>
        <ThreadSearchResult
          v-for="thread in group.threads"
          :key="thread.id"
          :thread="thread"
          :members="threadsData.members"
        />
      </ol>
    </div>
  </div>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import { useUserStore } from "@/stores/user";
import { ChatBubbleLeftRightIcon } from "@heroicons/vue/20/solid";
import { useWeMessageInputStore } from "@/stores/weMessageInput";
import pluralize from "pluralize";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import ThreadSearchResult from "@/components/we/channels-format/ThreadSearchResult.vue";
import moment from "moment";

const userStore = useUserStore();
const { currentUser, signedIn } = storeToRefs(userStore);
const wePanelStore = useWePanelStore();
const { expandedView } = storeToRefs(wePanelStore);
const weMessageInputStore = useWeMessageInputStore();

const threadsData = ref({
  members: [],
  threads: [],
});

const joinedThreadIds = computed(() => {
  if (signedIn.value) {
    return threadsData.value.members
      .filter(({ userId }) => userId === currentUser.value.id)
      .map(({ threadId }) => threadId);
  } else {
    return [];
  }
});

const joinedThreads = computed(() =>
  threadsData.value.threads.filter(({ id }) =>
    _.includes(joinedThreadIds.value, id),
  ),
);

const unjoinedThreads = computed(() =>
  threadsData.value.threads.filter(
    ({ id }) => !_.includes(joinedThreadIds.value, id),
  ),
);

const olderThreads = computed(() =>
  unjoinedThreads.value.filter(({ createdAt }) =>
    moment(createdAt).isBefore(moment().subtract(30, "days")),
  ),
);

const threadGroups = computed(() => {
  return [
    {
      name: "joined",
      label: `Joined Thread`,
      threads: joinedThreads.value,
    },
    {
      name: "active",
      label: `Active Thread`,
      threads: [],
    },
    {
      name: "older",
      label: `Older Thread`,
      threads: olderThreads.value,
    },
  ];
});

const displayableThreadGroups = computed(() =>
  threadGroups.value.filter(({ threads }) => threads.length > 0),
);

onMounted(() => {
  search();
});

async function search() {
  const maybeThreadsData = await wePanelStore.searchThreads();

  if (maybeThreadsData) {
    const { members, threads } = maybeThreadsData;

    console.log(members, threads);

    threadsData.value = {
      members,
      threads,
    };
  }
}

function create() {
  weMessageInputStore.createThread();

  if (!expandedView.value) {
    wePanelStore.closeBottomEdgeLevelTwoPanel();
    wePanelStore.closeBottomEdgePanel();
  }
}

function close() {
  if (!expandedView.value) {
    wePanelStore.closeBottomEdgeLevelTwoPanel();
  }
}
</script>
