<template>
  <TransitionRoot
    v-if="!expandedView"
    as="div"
    class="absolute top-1/2 w-full h-1/2 flex z-[125] shadow-inner"
    :show="
      !!bottomEdgeLevelTwoComponent && bottomEdgeLevelTwoPosition === 'half'
    "
  >
    <!-- Transition Child: from hidden to half -->
    <TransitionChild
      as="template"
      enter="transition ease-in-out duration-300 transform"
      enter-from="translate-y-full"
      enter-to="translate-y-1/2"
      leave="transition ease-in-out duration-300 transform"
      leave-from="translate-y-1/2"
      leave-to="translate-y-full"
    >
      <div
        class="relative w-full flex flex-col rounded-t-2xl bg-white"
        @touchstart.stop="handleBottomEdgeTouchStart"
        @touchmove.stop="handleBottomEdgeTouchMove"
        @touchend.stop="handleBottomEdgeTouchEnd"
      >
        <!-- Bottom edge component -->
        <button
          v-if="!expandedView && isDesktop"
          type="button"
          class="absolute top-2 right-6 rounded-full p-2 flex items-center justify-center text-gray-900 shadow-sm hover:text-gray-700 z-[126]"
          @click="cancel"
        >
          <XMarkIcon class="size-6" aria-hidden="true" />
        </button>
        <component
          :is="bottomEdgeLevelTwoComponent"
          v-bind="bottomEdgeLevelTwoProps"
        />
      </div>
    </TransitionChild>
  </TransitionRoot>

  <TransitionRoot
    v-if="!expandedView"
    as="div"
    class="absolute top-[15%] w-full h-[85%] flex z-[125] shadow-inner"
    :show="
      !!bottomEdgeLevelTwoComponent && bottomEdgeLevelTwoPosition === 'full'
    "
  >
    <!-- Transition Child: from half to full -->
    <TransitionChild
      as="template"
      enter="transition ease-in-out duration-300 transform"
      enter-from="translate-y-1/2"
      enter-to="translate-y-0"
      leave="transition ease-in-out duration-300 transform"
      leave-from="translate-y-0"
      leave-to="translate-y-1/2"
    >
      <div
        class="relative w-full flex flex-col rounded-t-2xl bg-white"
        @touchstart.stop="handleBottomEdgeTouchStart"
        @touchmove.stop="handleBottomEdgeTouchMove"
        @touchend.stop="handleBottomEdgeTouchEnd"
      >
        <!-- Right edge level two panel -->
        <WeRightEdgeLevelThreePanel />
        <!-- Bottom edge component -->
        <button
          v-if="!expandedView && isDesktop"
          type="button"
          class="absolute top-2 right-6 rounded-full p-2 flex items-center justify-center text-gray-900 shadow-sm hover:text-gray-700 z-[126]"
          @click="cancel"
        >
          <XMarkIcon class="size-6" aria-hidden="true" />
        </button>
        <component
          :is="bottomEdgeLevelTwoComponent"
          v-bind="bottomEdgeLevelTwoProps"
        />
      </div>
    </TransitionChild>
  </TransitionRoot>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import WeRightEdgeLevelThreePanel from "@/components/we/settings-views/WeRightEdgeLevelThreePanel.vue";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const wePanelStore = useWePanelStore();
const {
  expandedView,
  bottomEdgeLevelTwoComponent,
  bottomEdgeLevelTwoProps,
  bottomEdgeLevelTwoPosition,
} = storeToRefs(wePanelStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const touchThreshold = ref(50); // Adjust as needed for sensitivity
let touchStartY = ref(0);
let touchEndY = ref(0);

function handleBottomEdgeTouchStart(event) {
  touchStartY.value = event.touches[0].clientY;
}

function handleBottomEdgeTouchMove(event) {
  touchEndY.value = event.touches[0].clientY;
}

function handleBottomEdgeTouchEnd() {
  if (touchEndY.value === 0) return;

  if (touchStartY.value - touchEndY.value < -touchThreshold.value) {
    // Swipe from top to bottom detected
    if (bottomEdgeLevelTwoPosition.value === "full") {
      bottomEdgeLevelTwoPosition.value = "half";
    } else if (bottomEdgeLevelTwoPosition.value === "half") {
      cancel();
    }
  } else if (touchStartY.value - touchEndY.value > touchThreshold.value) {
    // Swipe from bottom to top detected
    if (bottomEdgeLevelTwoPosition.value === "half") {
      bottomEdgeLevelTwoPosition.value = "full";
    }
  }
  // Reset values
  touchStartY.value = 0;
  touchEndY.value = 0;
}

function cancel() {
  wePanelStore.closeBottomEdgeLevelTwoPanel();
}
</script>
