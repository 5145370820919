<template>
  <div class="relative flex h-full w-full pt-10 overflow-hidden">
    <div
      class="w-[200px] flex-shrink-0 px-4 border-r border-gray-300 h-full flex flex-col space-y-2 overflow-y-auto"
    >
      <div class="w-full flex items-center justify-between">
        <div class="text-sm uppercase text-gray-500">Roles/Members</div>
        <a
          v-if="!editableEveryonePlaceholder"
          href=""
          class="flex items-center text-gray-500"
          @click.prevent="weTopicSettingsStore.addPermissionMembers()"
        >
          <PlusIcon class="size-5" />
        </a>
      </div>
      <ol class="flex-grow w-full flex flex-col gap-y-2 overflow-y-auto">
        <li v-for="permission in withoutOwnerPermissions" :key="permission.id">
          <button
            class="w-full rounded-lg p-1 flex items-center space-x-2"
            type="button"
            :class="[
              selectedPermissionId === permission.id ? 'bg-gray-300' : '',
            ]"
            @click="weTopicSettingsStore.viewPermissionDetails(permission)"
          >
            <div
              v-if="permission.targetType === 'DiscussionRole'"
              class="size-3 rounded-full bg-current"
              :class="[
                weHubSettingsStore.colorFor(
                  weTopicSettingsStore.roleFor(permission),
                ),
              ]"
            />
            <AvatarPhoto
              v-else-if="permission.targetType === 'User'"
              :person="{ name: permission.targetName }"
              shape-size="h-6 w-6"
              icon-size="h-4 w-4"
              text-size="xs"
            />
            <div class="text-sm font-medium text-gray-500">
              <template v-if="permission.targetName === 'everyone'">@</template
              >{{ permission.targetName }}
            </div>
          </button>
        </li>
      </ol>
    </div>
    <div class="flex-grow flex flex-col px-4">
      <div class="flex-grow overflow-y-auto">
        <PermissionOverwriteEditor />
      </div>
    </div>
  </div>
</template>

<script setup>
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import { useWeHubSettingsStore } from "@/stores/weHubSettings";
import { useWeTopicSettingsStore } from "@/stores/weTopicSettings";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";
import PermissionOverwriteEditor from "@/components/we/settings-views/discussion-topic/PermissionOverwriteEditor.vue";

const weHubSettingsStore = useWeHubSettingsStore();
const weTopicSettingsStore = useWeTopicSettingsStore();
const {
  permissions,
  selectedPermissionId,
  everyonePermission,
  editableEveryonePlaceholder,
} = storeToRefs(weTopicSettingsStore);

const withoutOwnerPermissions = computed(() =>
  permissions.value.filter(({ targetIsHubOwner }) => !targetIsHubOwner),
);

onMounted(() => {
  if (!selectedPermissionId.value && everyonePermission.value) {
    selectedPermissionId.value = everyonePermission.value.id;
  }
});
</script>
