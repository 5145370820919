<template>
  <form
    class="relative h-full overflow-y-auto"
    :class="[expandedView ? 'px-2 py-6' : 'p-6']"
    @submit.prevent
  >
    <div class="space-y-12">
      <div class="border-b border-gray-900/10 pb-12">
        <fieldset aria-label="Choose a memory option">
          <div class="flex items-center justify-between">
            <div class="font-semibold text-gray-900">Start with a template</div>
          </div>

          <RadioGroup
            v-model="selectedTemplate"
            class="mt-2 grid gap-3"
            :class="[expandedView ? 'grid-cols-4' : 'grid-cols-2']"
          >
            <RadioGroupOption
              v-for="template in permissionTemplates"
              :key="template.name"
              v-slot="{ active, checked }"
              as="template"
              :value="template"
            >
              <button
                :class="[
                  active
                    ? `ring-2 ring-${template.color}-${template.primaryColorShade} ring-offset-2`
                    : '',
                  checked
                    ? `bg-${template.color}-${template.primaryColorShade} text-white ring-0 hover:bg-${template.color}-${template.primaryColorShade - 100}`
                    : 'bg-white text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50',
                  !active && !checked ? 'ring-inset' : '',
                  active && checked ? 'ring-2' : '',
                  'flex items-center justify-center rounded-md px-3 py-3 text-sm font-semibold uppercase sm:flex-1',
                ]"
              >
                {{ template.name }}
              </button>
            </RadioGroupOption>
          </RadioGroup>
        </fieldset>

        <div
          class="mt-4 flex flex-col justify-between rounded-xl bg-white p-3 shadow-lg ring-1 ring-gray-900/10"
        >
          <div>
            <h3 class="text-lg/7 font-semibold text-indigo-600">
              {{ selectedTemplate.name }}
            </h3>
            <p class="mt-2 text-base/7 text-gray-600 whitespace-pre-line">
              {{ selectedTemplate.description }}
            </p>
            <ul role="list" class="mt-4 space-y-2 text-sm/6 text-gray-600">
              <li
                v-for="feature in selectedTemplate.bullets"
                :key="feature"
                class="flex gap-x-3"
              >
                <CheckIcon
                  class="h-6 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                {{ feature }}
              </li>
            </ul>
          </div>
          <a
            href=""
            :aria-describedby="selectedTemplate.name"
            class="mt-8 block rounded-md bg-indigo-600 px-3.5 py-2 text-center text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click.prevent="applyTemplatePermissions(false)"
            >Apply</a
          >
        </div>
      </div>

      <div
        v-for="(grouping, index) in rolePermissionGroupings"
        :key="grouping.name"
        class="border-b border-gray-900/10 pb-12 flex flex-col space-y-3"
      >
        <button
          v-if="index === 0"
          type="button"
          class="w-full rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
          @click="applyTemplatePermissions(true)"
        >
          Clear permissions
        </button>

        <label
          :for="`${_.snakeCase(grouping.name)}-heading`"
          class="flex space-x-0.5 font-semibold"
        >
          <span class="text-gray-900">{{ grouping.name }}</span>
        </label>
        <SettingsToggleWithDescription
          v-for="permission in grouping.permissions"
          :key="permission"
          v-model="editableRole[permission]"
          :label="permissionCopy[permission]?.label"
          :description="permissionCopy[permission]?.description"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
import { CheckIcon } from "@heroicons/vue/20/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useWeHubSettingsStore } from "@/stores/weHubSettings";
import { storeToRefs } from "pinia";
import { RadioGroup, RadioGroupOption } from "@headlessui/vue";
import { ref } from "vue";
import _ from "lodash";
import SettingsToggleWithDescription from "@/components/we/settings-views/SettingsToggleWithDescription.vue";

const wePanelStore = useWePanelStore();
const { expandedView } = storeToRefs(wePanelStore);
const weHubSettingsStore = useWeHubSettingsStore();
const {
  editableRole,
  rolePermissionGroupings,
  permissionCopy,
  permissionTemplates,
} = storeToRefs(weHubSettingsStore);

const selectedTemplate = ref(permissionTemplates.value[0]);

function applyTemplatePermissions(clearAll = false) {
  _.forEach(selectedTemplate.value.permissions, (bool, permissionKey) => {
    editableRole.value[permissionKey] = clearAll ? false : bool;
  });
}
</script>
