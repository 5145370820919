<template>
  <div class="p-2 h-full space-y-6">
    <div v-for="role in displayableRoles" :key="role.id || role.name" class="">
      <div class="font-semibold" :class="[weHubSettingsStore.colorFor(role)]">
        {{ role.name }}&nbsp;&mdash;&nbsp;{{ _.size(membersFor(role)) }}
      </div>
      <template v-if="expandedView">
        <ChannelMemberListElement
          v-for="member in membersFor(role)"
          :key="member.id"
          :membership="member"
        />
      </template>
      <template v-else>
        <CompactChannelMemberListElement
          v-for="member in membersFor(role)"
          :key="member.id"
          :membership="member"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed, onMounted, watch } from "vue";
import ChannelMemberListElement from "@/components/we/channels-format/ChannelMemberListElement.vue";
import CompactChannelMemberListElement from "@/components/we/channels-format/CompactChannelMemberListElement.vue";
import { useWeHubSettingsStore } from "@/stores/weHubSettings";

const wePanelStore = useWePanelStore();
const {
  expandedView,
  activeTopicId,
  mainContent,
  hubRoles,
  hubMembers,
  topicPermissionOverwrites,
} = storeToRefs(wePanelStore);
const weHubSettingsStore = useWeHubSettingsStore();

const members = computed(() =>
  hubMembers.value.filter(
    (member) => member.hubId && member.hubId === hubId.value,
  ),
);
const topicId = computed(() => activeTopicId.value);
const hubId = computed(() => mainContent.value?.content?.hubId);
const roles = computed(() =>
  hubRoles.value.filter(({ hubId: localHubId }) => localHubId === hubId.value),
);
const permissionOverwrites = computed(() =>
  topicPermissionOverwrites.value.filter(
    ({ topicId: localTopicId }) => localTopicId === topicId.value,
  ),
);
const roleOverwrites = computed(() =>
  permissionOverwrites.value.filter(
    ({ targetType }) => targetType === "DiscussionRole",
  ),
);
const everyoneDenied = computed(
  () =>
    _.find(roleOverwrites.value, ({ target }) => target.name === "everyone")
      ?.viewChannels === "deny",
);
const allowedRoles = computed(() => {
  const allowedIds = roleOverwrites.value
    .filter(({ viewChannels }) => viewChannels === "allow")
    .map(({ targetId }) => targetId);
  return roles.value.filter(({ id }) => _.includes(allowedIds, id));
});
const allowedRoleMembers = computed(() => {
  const allowedOverwrites = roleOverwrites.value
    .filter(({ viewChannels }) => viewChannels === "allow")
    .flatMap(({ target }) => target.memberIds);

  return members.value.filter(({ userId }) =>
    _.includes(_.uniq(allowedOverwrites), userId),
  );
});
const allowedUserMembers = computed(() => {
  const allowedOverwrites = permissionOverwrites.value
    .filter(
      ({ targetType, viewChannels }) =>
        targetType === "User" && viewChannels === "allow",
    )
    .map(({ targetId }) => targetId);

  return members.value.filter(({ userId }) =>
    _.includes(allowedOverwrites, userId),
  );
});
const allowedMembers = computed(() => {
  if (everyoneDenied.value) {
    return _.unionBy(
      allowedRoleMembers.value,
      allowedUserMembers.value,
      "userId",
    );
  } else {
    return members.value;
  }
});
const hoistedRoles = computed(() => {
  const actionableRoles = everyoneDenied.value
    ? allowedRoles.value
    : roles.value;

  return actionableRoles.filter(({ hoist, name }) => hoist && name !== "owner");
});
const displayableRoles = computed(() => {
  const online = {
    hubId: hubId.value,
    name: "Online",
    color: null,
  };
  const offline = {
    hubId: hubId.value,
    name: "Offline",
    color: null,
  };

  return [...hoistedRoles.value, online, offline];
});

watch(topicId, (val, oldVal) => {
  if (val && val !== oldVal) {
    wePanelStore.fetchTopicPermissionOverwrites();
  }
});

onMounted(() => {
  wePanelStore.fetchTopicPermissionOverwrites();
});

function membersFor(role) {
  let output = [];
  if (role.id) {
    output = allowedMembers.value.filter((user) => {
      return (
        user.roleId === role.id && wePanelStore.presenceFor(user) !== "offline"
      );
    });
  } else if (role.name === "Online") {
    output = allowedMembers.value.filter((user) => {
      const { roleId } = user;
      const roleIds = hoistedRoles.value.map(({ id }) => id);
      return (
        !_.includes(roleIds, roleId) &&
        wePanelStore.presenceFor(user) !== "offline"
      );
    });
  } else {
    output = allowedMembers.value.filter(
      (user) => wePanelStore.presenceFor(user) === "offline",
    );
  }

  return _.sortBy(output, [
    function (member) {
      return member.linkedContactDataField?.fieldContent?.name || member.name;
    },
  ]);
}
</script>
