<template>
  <TransitionRoot
    v-if="!expandedView"
    as="div"
    class="absolute top-1/2 w-full h-1/2 flex z-[100] shadow-inner"
    :show="!!bottomEdgeComponent && bottomEdgePosition === 'half'"
  >
    <!-- Transition Child: from hidden to half -->
    <TransitionChild
      as="template"
      enter="transition ease-in-out duration-300 transform"
      enter-from="translate-y-full"
      enter-to="translate-y-1/2"
      leave="transition ease-in-out duration-300 transform"
      leave-from="translate-y-1/2"
      leave-to="translate-y-full"
    >
      <div
        class="w-full flex flex-col rounded-t-2xl bg-gray-300"
        @touchstart.stop="handleBottomEdgeTouchStart"
        @touchmove.stop="handleBottomEdgeTouchMove"
        @touchend.stop="handleBottomEdgeTouchEnd"
      >
        <!-- Bottom edge component -->
        <component :is="bottomEdgeComponent" v-bind="bottomEdgeProps" />
      </div>
    </TransitionChild>
  </TransitionRoot>

  <TransitionRoot
    v-if="!expandedView"
    as="div"
    class="absolute top-[10%] w-full h-[90%] flex z-[100] shadow-inner"
    :show="!!bottomEdgeComponent && bottomEdgePosition === 'full'"
  >
    <!-- Transition Child: from half to full -->
    <TransitionChild
      as="template"
      enter="transition ease-in-out duration-300 transform"
      enter-from="translate-y-1/2"
      enter-to="translate-y-0"
      leave="transition ease-in-out duration-300 transform"
      leave-from="translate-y-0"
      leave-to="translate-y-1/2"
    >
      <div
        class="w-full flex flex-col rounded-t-2xl bg-gray-300"
        @touchstart.stop="handleBottomEdgeTouchStart"
        @touchmove.stop="handleBottomEdgeTouchMove"
        @touchend.stop="handleBottomEdgeTouchEnd"
      >
        <!-- Bottom edge component -->
        <component :is="bottomEdgeComponent" v-bind="bottomEdgeProps" />
      </div>
    </TransitionChild>
  </TransitionRoot>
</template>

<script setup>
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const wePanelStore = useWePanelStore();
const {
  expandedView,
  bottomEdgeComponent,
  bottomEdgeProps,
  bottomEdgePosition,
} = storeToRefs(wePanelStore);

const touchThreshold = ref(50); // Adjust as needed for sensitivity
let touchStartY = ref(0);
let touchEndY = ref(0);

function handleBottomEdgeTouchStart(event) {
  touchStartY.value = event.touches[0].clientY;
}

function handleBottomEdgeTouchMove(event) {
  touchEndY.value = event.touches[0].clientY;
}

function handleBottomEdgeTouchEnd() {
  if (touchEndY.value === 0) return;

  if (touchStartY.value - touchEndY.value < -touchThreshold.value) {
    // Swipe from top to bottom detected
    if (bottomEdgePosition.value === "full") {
      bottomEdgePosition.value = "half";
    } else if (bottomEdgePosition.value === "half") {
      wePanelStore.closeBottomEdgePanel();
    }
  } else if (touchStartY.value - touchEndY.value > touchThreshold.value) {
    // Swipe from bottom to top detected
    if (bottomEdgePosition.value === "half") {
      bottomEdgePosition.value = "full";
    }
  }
  // Reset values
  touchStartY.value = 0;
  touchEndY.value = 0;
}
</script>
