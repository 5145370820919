<template>
  <!-- Global notification live region, render this permanently at the start of the document -->
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6"
    style="z-index: 99999"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end max-h-96 overflow-y-auto">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <component
        :is="notification.component"
        v-for="(notification, index) in activeNotifications"
        :key="index"
        v-bind="notification.props"
      />
    </div>
  </div>
</template>

<script setup>
import { useNotificationsStore } from "@/stores/notifications";
import { storeToRefs } from "pinia";

const notificationsStore = useNotificationsStore();
const { activeNotifications } = storeToRefs(notificationsStore);
</script>
