<template>
  <div class="flex flex-col max-w-2xl" :class="[expandedView ? 'rounded-md shadow-lg bg-gray-300' : '']">
    <div class="p-2">
      <h2 class="text-base/6 font-semibold text-gray-900">
        Select From Network
      </h2>
      <h3 class="text-xs text-gray-700">
        You can add {{ 9 - invitees.length }} more
      </h3>
      <SearchableInput
        v-model="networkSearch"
        :selected-items="displayableInvitees"
        :chip-component="AutocompleteNameChip"
        placeholder="Search your network"
        class="mt-2 block w-full rounded-md bg-white border border-gray-300"
        @remove-item="removeInvitee"
      />
    </div>
    <fieldset
      class="p-2 flex-grow max-h-48 overflow-y-auto"
      :class="[expandedView ? 'w-96' : '']"
    >
      <legend class="sr-only">My Network Contacts</legend>
      <div class="">
        <div
          v-if="networkings.length === 0"
          class="p-8 flex flex-col items-center gap-y-4"
        >
          <UsersIcon class="size-10 text-gray-500" />
          <h3 class="text-center text-sm text-gray-600">
            People make real estate come alive.
          </h3>
        </div>
        <div
          v-for="networking in networkings"
          :key="networking.id"
          class="relative rounded-md px-2 py-1 flex items-center gap-3 hover:bg-gray-300"
        >
          <label :for="`networking-${networking.id}`" class="flex-grow">
            <NetworkingListElement :networking="networking" :injected="true" />
          </label>
          <div class="flex h-6 shrink-0 items-center">
            <div class="group grid size-4 grid-cols-1">
              <input
                :id="`networking-${networking.id}`"
                v-model="invitees"
                :name="`networking-${networking.id}`"
                type="checkbox"
                :value="networking"
                class="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
              />
              <svg
                class="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  class="opacity-0 group-has-[:checked]:opacity-100"
                  d="M3 8L6 11L11 3.5"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  class="opacity-0 group-has-[:indeterminate]:opacity-100"
                  d="M3 7H11"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="px-2 py-4 border-t border-gray-400 flex items-center gap-x-2">
      <button
        type="button"
        class="flex-grow rounded bg-white p-2 text-xs font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        @click="wePanelStore.closeSecondaryContentPanel()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="flex-grow rounded bg-indigo-600 p-2 text-xs font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        @click="wePanelStore.groupDirectMessage(persistPayload)"
      >
        Create DM
      </button>
    </div>
  </div>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";
import { UsersIcon } from "@heroicons/vue/24/outline";
import SearchableInput from "@/components/we/SearchableInput.vue";
import AutocompleteNameChip from "@/components/we/AutocompleteNameChip.vue";
import NetworkingListElement from "@/components/we/direct-messages/NetworkingListElement.vue";

const wePanelStore = useWePanelStore();
const { expandedView, networkings } = storeToRefs(wePanelStore);

const invitees = ref([]);
const persistPayload = computed(() => {
  return invitees.value.map(wePanelStore.networkingCounterpart);
});
const displayableInvitees = computed(() => {
  return invitees.value.map((networking) => {
    const contact = wePanelStore.networkingCounterpart(networking);
    const name =
      contact.linkedContactDataField?.fieldContent?.name || contact.name;

    return {
      id: networking.id,
      name,
    };
  });
});
const networkSearch = ref("");

onMounted(() => {
  wePanelStore.fetchNetworkings();
});

function removeInvitee(item) {
  invitees.value = invitees.value.filter(({ id }) => id !== item.id);
}
</script>
