<template>
  <VMenu>
    <div
      :class="`${outerSize}`"
      class="relative flex-shrink-0 flex items-center justify-center rounded-md"
    >
      <AvatarPresenceIndicator
        v-if="geographyIntent.user"
        :person="geographyIntent.user"
      />
      <img
        :class="`${markerSize} ${reputationColor}`"
        class="inline-block rounded-md ring-2 ring-white"
        src="https://assets.towerhunt.com/site/Transparent_White_T0.png"
        alt="Tower Hunt logo"
      />
    </div>
    <template #popper>
      <div class="flex flex-col p-1">
        <div class="flex space-x-1">
          <div class="text-sm text-gray-700 font-bold">
            {{ reputationHelp }}
          </div>
          <button @click="getHelp" type="button" v-tooltip="'Learn more'">
            <QuestionMarkCircleIcon class="h-4 w-4 text-gray-700" />
          </button>
        </div>
      </div>
    </template>
  </VMenu>
</template>

<script setup>
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import { storeToRefs } from "pinia";
import { mapMarkers } from "@/assets/documentation/articles/mapMarkers";
import { computed } from "vue";
import { defaultBoundaryMeta } from "@/components/maps/defaultBoundaryMeta";
import _ from "lodash";
import AvatarPresenceIndicator from "@/components/users/AvatarPresenceIndicator.vue";

const props = defineProps([
  "geographyIntent",
  "mapStore",
  "documentationStore",
]);
const { mapBoundaryMeta } = storeToRefs(props.mapStore);

const reputationHelp = computed(() => `User on the hunt`);
const reputationColor = computed(() => {
  switch (reputationLevel.value) {
    case "bronze":
      return "bg-gradient-to-r from-yellow-500 to-orange-700 text-white hover:to-orange-800";
    case "silver":
      return "bg-gradient-to-r from-slate-200 to-gray-400 text-gray-900 hover:to-gray-500";
    case "gold":
      return "bg-gradient-to-r from-yellow-300 to-yellow-500 text-white hover:to-yellow-600";
    default:
      return "text-white bg-gray-800 hover:bg-gray-700";
  }
});
const zoom = computed(() => {
  return _.get(mapBoundaryMeta.value, "zoom") || defaultBoundaryMeta.zoom;
});
const outerSize = computed(() => {
  if (zoom.value > 15) {
    return "h-7 w-7";
  } else if (zoom.value > 14) {
    return "h-6 w-6";
  } else {
    return "h-5 w-5";
  }
});
const markerSize = computed(() => {
  if (zoom.value > 15) {
    return "h-6 w-6";
  } else if (zoom.value > 14) {
    return "h-5 w-5";
  } else {
    return "h-4 w-4";
  }
});
const reputationLevel = computed(() =>
  _.get(props.geographyIntent, "user.reputationLevel"),
);

function getHelp() {
  props.documentationStore.viewArticle(mapMarkers, "User markers");
}
</script>
