import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { ref, computed } from "vue";
import { defineStore, storeToRefs, acceptHMRUpdate } from "pinia";
import { useRouter, useRoute } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useMainMapStore } from "@/stores/mainMap";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import DataFieldDetails from "@/components/crowdsourcing/DataFieldDetails.vue";
import _ from "lodash";

export const useSecondaryPanelStore = defineStore("secondaryPanel", () => {
  const layoutStore = useWorkspaceLayoutStore();
  const { workspaceResized, workspaceLayout, isDesktop } =
    storeToRefs(layoutStore);
  const userStore = useUserStore();
  const { signedIn } = storeToRefs(userStore);
  const propertyDiagramStore = usePropertyDiagramStore();
  const { propertyIdParam } = storeToRefs(propertyDiagramStore);
  const companyDetailStore = useCompanyDetailStore();
  const { companyIdParam } = storeToRefs(companyDetailStore);
  const contactDetailStore = useContactDetailStore();
  const { contactIdParam } = storeToRefs(contactDetailStore);
  const analyzePanelStore = useAnalyzePanelStore();
  const { analyzeDisplayable } = storeToRefs(analyzePanelStore);
  const dealBuilderStore = useDealBuilderStore();
  const { dealBuilder } = storeToRefs(dealBuilderStore);
  const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
  const { spaceUsageBuilder } = storeToRefs(spaceUsageBuilderStore);
  const changeGroupStore = useCrowdsourcedChangeGroupStore();
  const { existingChangeGroupId } = storeToRefs(changeGroupStore);
  const mapStore = useMainMapStore();
  const { map } = storeToRefs(mapStore);
  const router = useRouter();
  const route = useRoute();
  const query = computed(() => route.query);
  const verticalTab = computed(() => _.get(query.value, "verticalTab"));

  const secondaryPanelTabs = [
    {
      name: "We",
      tabQuery: "We",
      href: "",
    },
    {
      name: "Me",
      tabQuery: "Me",
      href: "",
    },
  ];
  const selectedSecondaryPanelTab = ref({
    name: "We",
    tabQuery: "We",
    href: "",
  });
  const validationsActive = ref(false);
  const authenticateActive = ref(false);
  const helpActive = ref(false);
  const safezoneActive = computed(
    () =>
      selectedSecondaryPanelTab.value.name === "Me" &&
      verticalTab.value === "Safezone",
  );
  const secondaryPanelTypedTabs = computed(() => {
    return _.compact(
      _.concat(
        secondaryPanelTabs,
        authenticateTab.value,
        validationsTab.value,
        analyzeTab.value,
        dealBuilderTab.value,
        spaceUsageBuilderTab.value,
        detailsTab.value,
        helpTab.value,
      ),
    );
  });
  const helpTab = computed(() => {
    if (helpActive.value) {
      return [{ name: "Help", tabQuery: "Help", href: "" }];
    } else {
      return null;
    }
  });
  const dealBuilderTab = computed(() => {
    if (dealBuilder.value) {
      return [{ name: "Deal Hub", tabQuery: "Deals", href: "" }];
    } else {
      return null;
    }
  });
  const spaceUsageBuilderTab = computed(() => {
    if (spaceUsageBuilder.value) {
      return [{ name: "Space Hub", tabQuery: "Spaces", href: "" }];
    } else {
      return null;
    }
  });
  const detailsTab = computed(() => {
    if (secondaryPanelDetailsComponent.value) {
      return [{ name: "Details", tabQuery: "Details", href: "" }];
    } else {
      return null;
    }
  });
  const validationsTab = computed(() => {
    if (validationsActive.value) {
      return [{ name: "Validations", tabQuery: "Validations", href: "" }];
    } else {
      return null;
    }
  });
  const authenticateTab = computed(() => {
    if (authenticateActive.value && !signedIn.value) {
      return [{ name: "Sign in", tabQuery: "Authenticate", href: "" }];
    } else {
      return null;
    }
  });
  const analyzeTab = computed(() => {
    if (analyzeDisplayable.value) {
      return [{ name: "Analyze", tabQuery: "Analyze", href: "" }];
    } else {
      return null;
    }
  });
  const secondaryPanelDetailsComponent = computed(() => {
    if (propertyIdParam.value || companyIdParam.value || contactIdParam.value) {
      return DataFieldDetails;
    } else {
      return null;
    }
  });
  function closeValidations() {
    if (validationsActive.value) {
      validationsActive.value = false;
      existingChangeGroupId.value = null;
      revertPanel();
    }
  }
  function setSecondaryPanelWidth(columns = "3fr 10px 450px") {
    if (workspaceLayout.value === "sideBySide") {
      const bounds = map.value?.getBounds();
      document.getElementById("workspace-shell-grid-container").style[
        "grid-template-columns"
      ] = columns;

      workspaceResized.value = bounds;
    }
  }
  function setAuthenticate(val = false) {
    if (!signedIn.value) {
      authenticateActive.value = val;
    } else {
      authenticateActive.value = false;
    }

    router.push({
      name: route.name,
      query: {
        ...route.query,
        teamInvitationToken: undefined,
        userInvitationToken: undefined,
      },
    });
  }
  function revertPanel() {
    setTimeout(() => {
      setSecondaryPanelWidth();
    }, 250);

    const needsTabRedirect = !_.includes(
      ["Me", "We"],
      _.get(selectedSecondaryPanelTab.value, "name"),
    );

    if (needsTabRedirect) {
      setQueryFromTab({
        name: "Me",
        tabQuery: "Me",
        href: "",
      });
    }
  }

  function setQueryFromTab(newTab) {
    if (isDesktop.value) {
      router.push({
        name: route.name,
        query: {
          ...route.query,
          horizontalTab: newTab.tabQuery,
          verticalTab: undefined,
        },
      });
    }
  }

  function setTabsFromQuery() {
    const { horizontalTab } = query.value;
    if (isDesktop && horizontalTab) {
      switch (horizontalTab) {
        case "Authenticate":
          setAuthenticate(true);
          break;
        case "Validations":
          validationsActive.value = true;
          break;
        case "Help":
          helpActive.value = true;
          break;
        case "Me":
        case "We":
        case "Deals":
        case "Spaces":
        case "Details":
        default:
      }

      activateHorizontalTab(horizontalTab);
    }
  }

  function activateHorizontalTab(tabQuery) {
    const newTab = _.find(secondaryPanelTypedTabs.value, {
      tabQuery,
    });
    selectedSecondaryPanelTab.value = newTab;
    if (workspaceLayout.value === "fullScreen")
      layoutStore.matchLayoutToScreen();
  }

  function promptToRegister() {
    if (isDesktop.value) {
      setAuthenticate({ name: "Register" });
      router.push({
        name: route.name,
        query: {
          ...route.query,
          horizontalTab: "Authenticate",
          verticalTab: "Register",
        },
      });
    } else {
      router.push({
        name: "Register",
        query: {
          teamInvitationToken: route.query.teamInvitationToken,
          userInvitationToken: route.query.userInvitationToken,
          easyDataInputJobInvitationToken:
            route.query.easyDataInputJobInvitationToken,
        },
      });
    }
  }

  return {
    selectedSecondaryPanelTab,
    validationsActive,
    safezoneActive,
    authenticateActive,
    helpActive,
    secondaryPanelDetailsComponent,
    secondaryPanelTypedTabs,
    setSecondaryPanelWidth,
    setAuthenticate,
    revertPanel,
    setTabsFromQuery,
    promptToRegister,
    closeValidations,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useSecondaryPanelStore, import.meta.hot),
  );
}
