<template>
  <div v-if="topic" class="relative h-full p-8 space-y-4 overflow-y-auto">
    <button
      v-if="!expandedView && isDesktop"
      type="button"
      class="absolute top-2 right-2 rounded-full p-2 flex items-center justify-center text-gray-900 shadow-sm hover:text-gray-700"
      @click="cancel"
    >
      <XMarkIcon class="size-6" aria-hidden="true" />
    </button>
    <div class="w-full flex flex-col items-center space-y-2">
      <TopicTypeIcon :topic="topic" lock-size="size-4" icon-size="size-7" />
      <h1 class="text-sm font-medium text-center text-gray-500">
        {{ topic.name }}
      </h1>
    </div>
    <div
      v-for="optionGroup in primaryNavigationOptions"
      :key="optionGroup.name"
      class="space-y-2"
    >
      <h2
        v-if="optionGroup.name !== 'Confirmatory'"
        class="text-xs font-medium text-gray-500"
      >
        {{ optionGroup.name }}
      </h2>
      <ul class="divide-y divide-gray-300">
        <li
          v-for="(option, index) in optionGroup.options"
          :key="option.name"
          class="w-full flex p-3"
          :class="[
            optionGroup.options.length === 1 ? 'rounded-md' : '',
            optionGroup.options.length > 1 && index === 0 ? 'rounded-t-md' : '',
            optionGroup.options.length > 1 &&
            index === optionGroup.options.length - 1
              ? 'rounded-b-md'
              : '',
            option.grouping === 'Confirmatory' ? 'bg-red-100' : 'bg-gray-100',
          ]"
        >
          <button
            type="button"
            class="w-full flex items-center font-medium gap-x-3"
            :class="[
              option.grouping === 'Confirmatory'
                ? 'text-red-400'
                : 'text-gray-500',
            ]"
            @click.prevent="select(option)"
          >
            <component :is="option.icon" class="size-6" aria-hidden="true" />
            <div class="">{{ option.name }}</div>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { storeToRefs } from "pinia";
import { useWeTopicSettingsStore } from "@/stores/weTopicSettings";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import TopicTypeIcon from "@/components/we/channels-format/TopicTypeIcon.vue";
import { computed, onMounted } from "vue";

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const wePanelStore = useWePanelStore();
const {
  expandedView,
  mainContent,
  bottomEdgeLevelTwoComponent,
  bottomEdgeLevelTwoPosition,
  bottomEdgeLevelTwoProps,
} = storeToRefs(wePanelStore);
const weTopicSettingsStore = useWeTopicSettingsStore();
const { primaryNavigationOptions } = storeToRefs(weTopicSettingsStore);

const topic = computed(() => mainContent.value?.content);

onMounted(async () => {
  await wePanelStore.fetchTopicPermissionOverwrites();
});

function select(option) {
  if (option.grouping === "Confirmatory") {
    option.handler();
  } else {
    view(option.component);
  }
}

function view(component) {
  bottomEdgeLevelTwoPosition.value = "full";
  bottomEdgeLevelTwoComponent.value = component;
  bottomEdgeLevelTwoProps.value = {};
}

function cancel() {
  wePanelStore.closeSecondaryContentPanel();
}
</script>
