<template>
  <TransitionRoot
    v-if="!expandedView"
    as="div"
    class="absolute inset-0 flex z-[75]"
    :show="!!rightEdgeLevelTwoComponent"
  >
    <TransitionChild
      as="template"
      enter="transition ease-in-out duration-300 transform"
      enter-from="translate-x-full"
      enter-to="translate-x-0"
      leave="transition ease-in-out duration-300 transform"
      leave-from="translate-x-0"
      leave-to="translate-x-full"
    >
      <div
        class="w-full flex flex-col bg-gray-200"
        @touchstart="handleRightEdgeTouchStart"
        @touchmove="handleRightEdgeTouchMove"
        @touchend="handleRightEdgeTouchEnd"
      >
        <WePreviewModeBanner v-if="activeOption.previewMode" />
        <!-- Sidebar component -->
        <component
          :is="rightEdgeLevelTwoComponent"
          v-bind="rightEdgeLevelTwoProps"
        />
      </div>
    </TransitionChild>
  </TransitionRoot>
</template>

<script setup>
import WePreviewModeBanner from "@/components/we/WePreviewModeBanner.vue";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const wePanelStore = useWePanelStore();
const {
  expandedView,
  activeOption,
  rightEdgeLevelTwoComponent,
  rightEdgeLevelTwoProps,
} = storeToRefs(wePanelStore);

// Right edge panel touch event handling
let touchStartX = ref(0);
let touchEndX = ref(0);
const touchThreshold = ref(50); // Adjust as needed for sensitivity

function handleRightEdgeTouchStart(event) {
  touchStartX.value = event.touches[0].clientX;
}

function handleRightEdgeTouchMove(event) {
  touchEndX.value = event.touches[0].clientX;
}

function handleRightEdgeTouchEnd() {
  if (touchStartX.value - touchEndX.value < -touchThreshold.value) {
    // Swipe from left to right detected
    wePanelStore.closeRightEdgeLevelTwoPanel();
  }
  // Reset values
  touchStartX.value = 0;
  touchEndX.value = 0;
}
</script>
