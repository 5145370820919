<template>
  <transition
    enter-active-class="transition ease-out duration-300"
    enter-from-class="translate-y-24"
    enter-to-class="translate-y-0"
    leave-active-class="transition ease-in duration-200"
    leave-from-class="translate-y-0"
    leave-to-class="translate-y-24"
  >
    <div
      v-show="changes"
      class="pointer-events-none z-[150]"
      :class="[
        expandedView
          ? 'fixed inset-x-0 bottom-0 flex justify-center pb-5 px-8'
          : 'w-full absolute inset-x-0 bottom-0',
      ]"
    >
      <div
        class="pointer-events-auto flex items-center justify-between transition ease-in-out duration-500"
        :class="[
          unsavedChangesNavigationGuard
            ? 'animate-pulse bg-red-600'
            : 'bg-gray-900',
          unsavedChangesNavigationGuard && expandedView ? 'scale-110' : '',
          expandedView ? 'rounded-xl py-3 pl-4 pr-3.5 gap-x-6 px-6' : 'p-2',
        ]"
      >
        <div
          class="text-white"
          :class="[expandedView ? 'text-sm/6 mr-16' : 'text-xs mr-3']"
        >
          <div>
            <strong class="font-semibold">Careful</strong
            ><svg
              viewBox="0 0 2 2"
              class="mx-2 inline size-0.5 fill-current"
              aria-hidden="true"
            >
              <circle cx="1" cy="1" r="1" />
            </svg>
            You have unsaved changes!
          </div>
        </div>
        <div
          class="flex items-center"
          :class="[expandedView ? 'space-x-6' : 'space-x-3']"
        >
          <a
            href=""
            class="text-white font-semibold"
            :class="[expandedView ? 'text-sm' : 'text-xs']"
            @click.prevent="reset"
            >Reset</a
          >
          <button
            type="button"
            class="rounded-md bg-indigo-500 font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            :class="[expandedView ? 'text-sm px-2.5 py-1.5' : 'text-xs p-2']"
            @click="save"
          >
            {{ expandedView ? "Save Changes" : "Save" }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { useWeHubSettingsStore } from "@/stores/weHubSettings";
import { storeToRefs } from "pinia";
import { useWePanelStore } from "@/stores/wePanel";

defineProps(["changes"]);
const emit = defineEmits(["reset", "save"]);

const wePanelStore = useWePanelStore();
const { expandedView } = storeToRefs(wePanelStore);
const weHubSettingsStore = useWeHubSettingsStore();
const { unsavedChangesNavigationGuard } = storeToRefs(weHubSettingsStore);

function reset() {
  unsavedChangesNavigationGuard.value = false;
  emit("reset");
}

function save() {
  unsavedChangesNavigationGuard.value = false;
  emit("save");
}
</script>
