import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import _ from "lodash";

export const useWeThreadFormStore = defineStore("weThreadForm", () => {
  const draftThreads = ref([]);

  function patchDrafts(updatedDraft) {
    draftThreads.value = _.unionBy(
      [updatedDraft],
      draftThreads.value,
      "parentTopicId",
    );
  }

  function dropDraftThread(parentTopicId) {
    if (parentTopicId) {
      draftThreads.value = draftThreads.value.filter(
        (thread) => thread.parentTopicId !== parentTopicId,
      );
    }
  }

  return {
    draftThreads,
    patchDrafts,
    dropDraftThread,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useWeThreadFormStore, import.meta.hot),
  );
}
