<template>
  <header class="w-full p-2 border-b border-gray-300">
    <template v-if="expandedView">
      <div
        v-if="activeOption.matchContent?.dataField"
        class="w-full flex items-center justify-between"
      >
        <DataField
          v-if="activeOption.matchContent?.dataField"
          :data-field="activeOption.matchContent?.dataField"
          text-classes="font-semibold"
          :text-wrap="true"
          :no-image="true"
          dropdown-placement="left-start"
          text-styles=""
        />
        <VDropdown class="flex items-center" placement="bottom">
          <button
            type="button"
            class="flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          >
            <ChevronDownIcon class="size-6" aria-hidden="true" />
          </button>
          <template #popper>
            <TeamDropdownOptions />
          </template>
        </VDropdown>
      </div>
      <VDropdown
        v-else
        class="w-full mr-2 flex items-center"
        placement="bottom"
      >
        <button
          type="button"
          class="w-full flex items-center justify-between text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
        >
          <div class="text-gray-700 font-semibold">
            {{ activeOption.name }}
          </div>
          <ChevronDownIcon class="size-6" aria-hidden="true" />
        </button>
        <template #popper>
          <TeamDropdownOptions />
        </template>
      </VDropdown>
    </template>
    <button
      v-else
      class="flex items-center space-x-2"
      @click="viewBottomEdgeOptions"
    >
      <DataField
        v-if="activeOption.matchContent?.dataField"
        :data-field="activeOption.matchContent.dataField"
        text-classes="font-semibold"
        :text-wrap="true"
        :no-image="true"
        :no-dropdown="true"
        dropdown-placement="left-start"
        text-styles=""
      />
      <div v-else class="text-gray-700 font-semibold">
        {{ activeOption.name }}
      </div>
      <ChevronRightIcon class="size-5" aria-hidden="true" />
    </button>
  </header>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
import { storeToRefs } from "pinia";
import { markRaw } from "vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import TeamBottomEdgeOptions from "@/components/we/selection-panel/TeamBottomEdgeOptions.vue";
import TeamDropdownOptions from "@/components/we/selection-panel/TeamDropdownOptions.vue";

const wePanelStore = useWePanelStore();
const {
  activeOption,
  expandedView,
  bottomEdgeComponent,
  bottomEdgeProps,
  bottomEdgePosition,
} = storeToRefs(wePanelStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

function viewBottomEdgeOptions() {
  if (!expandedView.value) {
    bottomEdgePosition.value = "full";
    bottomEdgeComponent.value = markRaw(TeamBottomEdgeOptions);
    bottomEdgeProps.value = {
      hub: activeOption.value?.matchContent,
    };
  }
}
</script>
