<template>
  <ul
    v-for="(groupPermissions, targetType) in displayablePermissions"
    :key="targetType"
    class="mt-2 flex flex-col"
  >
    <div v-if="nonOwner(groupPermissions).length > 0" class="text-gray-700 font-semibold mb-2">
      {{ targetType === "DiscussionRole" ? "Roles" : "Members" }}
    </div>
    <li
      v-for="(permission, index) in nonOwner(groupPermissions)"
      :key="permission.id"
      class="w-full flex border border-gray-200 hover:bg-gray-100"
      :class="[
        index !== 0 ? '-mt-px' : '',
        groupPermissions.length === 1 ? 'rounded-md' : '',
        groupPermissions.length > 1 && index === 0 ? 'rounded-t-md' : '',
        groupPermissions.length > 1 && index === groupPermissions.length - 1
          ? 'rounded-b-md'
          : '',
      ]"
    >
      <button
        type="button"
        class="p-3 w-full flex items-center justify-between font-medium gap-x-3"
        @click.prevent="navigateDeeper(permission)"
      >
        <div class="text-gray-500">{{ permission.targetName }}</div>
        <ChevronRightIcon
          class="size-5 flex-none text-gray-400"
          aria-hidden="true"
        />
      </button>
    </li>
  </ul>
</template>

<script setup>
import { ChevronRightIcon } from "@heroicons/vue/20/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useWeTopicSettingsStore } from "@/stores/weTopicSettings";
import { storeToRefs } from "pinia";
import _ from "lodash";
import PermissionOverwriteEditor from "@/components/we/settings-views/discussion-topic/PermissionOverwriteEditor.vue";

const wePanelStore = useWePanelStore();
const { rightEdgeLevelThreeComponent } = storeToRefs(wePanelStore);
const weTopicSettingsStore = useWeTopicSettingsStore();
const {
  selectedPermissionId,
  selectedTabLayeredComponents,
  displayablePermissions,
} = storeToRefs(weTopicSettingsStore);

function navigateDeeper(permission) {
  selectedPermissionId.value = permission.id;
  weTopicSettingsStore.addLayeredComponent(PermissionOverwriteEditor);

  rightEdgeLevelThreeComponent.value = _.last(
    selectedTabLayeredComponents.value,
  );
}

function nonOwner(permissions) {
  return permissions.filter(({ targetIsHubOwner }) => !targetIsHubOwner);
}
</script>
