<template>
  <div class="h-full flex flex-col px-2 space-y-6">
    <div
      v-for="category in requestCategories"
      :key="category.requestType"
      class="h-1/2 flex flex-col py-1"
    >
      <h2
        class="w-full p-2 flex items-center justify-between border-b border-gray-400"
      >
        <div class="text-gray-700 font-semibold text-sm">
          {{ category.requestType }} &mdash; {{ category.requests.length }}
        </div>
      </h2>
      <div
        v-if="category.requests.length === 0"
        class="mt-8 p-8 flex flex-col items-center gap-y-4"
      >
        <component :is="category.icon" class="size-10 text-gray-500" />
        <h3 class="text-center text-sm text-gray-600">
          {{ category.emptyMessage }}
        </h3>
      </div>
      <ol v-if="category.requestType === 'Sent'" class="py-1 gap-y-1 flex-grow overflow-y-auto">
        <SentNetworkingRequestListElement v-for="networking in category.requests" :key="networking" :networking="networking" />
      </ol>
      <ol v-else-if="category.requestType === 'Received'" class="py-1 gap-y-1 flex-grow overflow-y-auto">
        <ReceivedNetworkingRequestListElement v-for="networking in category.requests" :key="networking" :networking="networking" />
      </ol>
    </div>
  </div>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";
import { UserPlusIcon, ClockIcon } from "@heroicons/vue/24/outline";
import SentNetworkingRequestListElement from "@/components/we/direct-messages/SentNetworkingRequestListElement.vue";
import ReceivedNetworkingRequestListElement from "@/components/we/direct-messages/ReceivedNetworkingRequestListElement.vue";

const wePanelStore = useWePanelStore();
const { networkingRequests } = storeToRefs(wePanelStore);

const requestCategories = computed(() => {
  return [
    {
      requestType: "Sent",
      requests: networkingRequests.value.sent,
      emptyMessage: "You haven't sent any networking requests yet.",
      icon: UserPlusIcon,
    },
    {
      requestType: "Received",
      requests: networkingRequests.value.received,
      emptyMessage: "There are no pending networking requests yet.",
      icon: ClockIcon,
    },
  ];
});

onMounted(() => {
  wePanelStore.fetchNetworkingRequests();
});
</script>
