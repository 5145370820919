import { ref, computed } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { useWePanelStore } from "@/stores/wePanel";
import api from "@/router/api";
import _ from "lodash";

export const useWeDiscussionCategoryFormStore = defineStore(
  "weDiscussionCategoryForm",
  () => {
    const wePanelStore = useWePanelStore();
    const { activeHubId } = storeToRefs(wePanelStore);
    const categoryName = ref("");
    const privateCategory = ref(false);
    const roles = ref([]);
    const members = ref([]);

    const canSave = computed(() => {
      return _.trim(categoryName.value) !== "";
    });

    async function save() {
      if (canSave.value) {
        const payload = {
          hubId: activeHubId.value,
          name: categoryName.value,
          isPrivate: privateCategory.value,
          roleIds: roles.value.map(({ id }) => id),
          userIds: members.value.map(({ userId }) => userId),
        };

        const categoryResponse = await api.post(`discussion_categories`, payload);

        wePanelStore.patchChannel(categoryResponse.data);
        wePanelStore.closeSecondaryContentPanel();
        reset();
      }
    }

    function reset() {
      categoryName.value = "";
      privateCategory.value = false;
      roles.value = [];
      members.value = [];
    }

    return {
      categoryName,
      privateCategory,
      roles,
      members,
      canSave,
      reset,
      save,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useWeDiscussionCategoryFormStore, import.meta.hot),
  );
}
