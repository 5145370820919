<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-blue-600 to-sky-400 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative px-4 py-16 flex flex-col justify-center">
      <h1 class="text-center text-4xl font-bold tracking-tight">
        <span class="block text-white">Discussion</span>
        <span class="block text-sky-200">Invitation</span>
      </h1>
      <DiscussionHubInvitationSummary
        v-if="hubInvitation"
        :invitation="hubInvitation"
        context="card"
      />
      <div
        v-if="hubInvitation"
        class="mx-auto mt-10 max-w-sm flex justify-center"
      >
        <a
          href=""
          class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-sky-700 shadow-sm hover:bg-sky-50 sm:px-8"
          @click.prevent="joinHub"
          >Accept Invitation</a
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import DiscussionHubInvitationSummary from "@/components/we/channels-format/DiscussionHubInvitationSummary.vue";
import { useUserStore } from "@/stores/user";
import { useWePanelStore } from "@/stores/wePanel";
import { useRoute, useRouter } from "vue-router";
import api from "@/router/api";
import { computed, onMounted, ref, watch } from "vue";
import _ from "lodash";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
  "context",
  "timelineOverrideField",
]);


const userStore = useUserStore();
const wePanelStore = useWePanelStore();

const hubInvitation = ref(null);

const isTopCard = computed(
  () =>
    props.stackIndex === props.cardIndex &&
    !_.includes(props.completedCardIds, props.card.id),
);

watch(
  () => props.stackIndex,
  () => {
    fetchTopCardContent();
  },
);

onMounted(() => {
  fetchTopCardContent();
});

async function fetchTopCardContent() {
  if (isTopCard.value) {
    const cardContentsResponse = await api.get(
      `collectible_cards/${props.card.collectibleCardId}/collectible_card_contents?template=discussion_hub_invitation`,
    );

    if (cardContentsResponse?.data) {
      if (cardContentsResponse.data.refetch) {
        userStore.fetchAvailableCollectibleCardCount();
      } else {
        hubInvitation.value = cardContentsResponse?.data?.content;
      }
    }
  }
}

const router = useRouter();
const route = useRoute();

async function joinHub() {
  if (hubInvitation.value) {
    const payload = {
      hubInvitationToken: hubInvitation.value.token,
    };
    await api.post("discussion_hub_joinings", payload);
    await wePanelStore.fetchHubs();

    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "We",
      },
    });
  }
}
</script>
