<template>
  <form
    class="relative h-full overflow-y-auto"
    :class="[expandedView ? 'px-2 py-6' : 'p-6']"
    @submit.prevent
  >
    <div class="space-y-12">
      <div class="border-b border-gray-900/10 pb-12">
        <label for="role_name" class="flex space-x-0.5 text-sm/6 font-medium">
          <span class="text-gray-900">Role Name</span>
          <span class="text-red-600">*</span>
        </label>
        <div class="mt-2">
          <input
            id="role_name"
            v-model="editableRole.name"
            v-focus
            type="text"
            name="role_name"
            class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
            placeholder="new role"
          />
        </div>
      </div>

      <div class="border-b border-gray-900/10 pb-12">
        <fieldset>
          <label for="role_name" class="flex space-x-0.5 text-sm/6 font-medium">
            <span class="text-gray-900">Role Color</span>
            <span class="text-red-600">*</span>
          </label>
          <p class="mt-2 text-sm/6 text-gray-600">
            Members use the color of the highest role they have on the roles
            list.
          </p>
          <RadioGroup
            v-model="editableRole.color"
            class="mt-6 flex items-center flex-wrap gap-3"
          >
            <RadioGroupOption
              v-for="option in roleColorOptions"
              :key="option.name"
              v-slot="{ active, checked }"
              as="template"
              :value="option.name"
              :aria-label="option.name"
            >
              <div
                :class="[
                  option.color,
                  active && checked ? 'ring ring-offset-1' : '',
                  !active && checked ? 'ring-2' : '',
                  'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 ring-current focus:outline-none',
                ]"
              >
                <span
                  aria-hidden="true"
                  class="rounded-full border border-black/10 bg-current"
                  :class="[option.name === 'Default' ? 'size-14' : 'size-8']"
                />
              </div>
            </RadioGroupOption>
          </RadioGroup>
        </fieldset>
      </div>

      <div class="border-b border-gray-900/10 pb-12">
        <SettingsToggleWithDescription
          v-model="editableRole.hoist"
          label="Display role members separately from online members"
          description=""
        />
      </div>

      <div class="border-b border-gray-900/10 pb-12">
        <SettingsToggleWithDescription
          v-model="editableRole.mentionable"
          label="Allow anyone to @mention this role"
          description="Note: Members with the 'Mention @everyone, @here, and All Roles' permission will always be able to ping this role."
        />
      </div>

      <div v-if="!expandedView" class="border-b border-gray-900/10 pb-12">
        <ul
          role="list"
          class="divide-y divide-gray-300 overflow-hidden bg-white shadow-sm ring-1 ring-gray-300 rounded-xl"
        >
          <li
            v-for="tab in compactNavigationTabs"
            :key="tab.name"
            class="relative flex justify-between gap-x-6 py-5 hover:bg-gray-100 px-6"
          >
            <div class="flex min-w-0 gap-x-4">
              <div class="min-w-0 flex-auto">
                <p class="text-sm/6 font-semibold text-gray-900">
                  <a href="" @click.prevent="navigateDeeper(tab)">
                    <span class="absolute inset-x-0 -top-px bottom-0" />
                    {{ tab.name }}
                  </a>
                </p>
              </div>
            </div>
            <div class="flex shrink-0 items-center gap-x-4">
              <ChevronRightIcon
                class="size-6 flex-none text-gray-700"
                aria-hidden="true"
              />
            </div>
          </li>
        </ul>

        <button
          type="button"
          class="mt-6 w-full rounded-xl bg-red-50 px-6 py-5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100 border border-red-300"
          @click="weHubSettingsStore.deleteRole(editableRole)"
        >
          Delete Role
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ChevronRightIcon } from "@heroicons/vue/20/solid";
import { RadioGroup, RadioGroupOption } from "@headlessui/vue";
import { useWePanelStore } from "@/stores/wePanel";
import { useWeHubSettingsStore } from "@/stores/weHubSettings";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import _ from "lodash";
import SettingsToggleWithDescription from "@/components/we/settings-views/SettingsToggleWithDescription.vue";

const wePanelStore = useWePanelStore();
const { expandedView, rightEdgeLevelThreeComponent } =
  storeToRefs(wePanelStore);
const weHubSettingsStore = useWeHubSettingsStore();
const {
  editableRole,
  roleColorOptions,
  roleTabs,
  selectedTabLayeredComponents,
} = storeToRefs(weHubSettingsStore);

const compactNavigationTabs = computed(() =>
  roleTabs.value.filter(({ name }) => name !== "Display"),
);

function navigateDeeper(tab) {
  weHubSettingsStore.addLayeredComponent(tab.component);

  rightEdgeLevelThreeComponent.value = _.last(
    selectedTabLayeredComponents.value,
  );
}
</script>
