<template>
  <li
    class="flex items-center justify-between gap-x-3"
    :class="[injected ? '' : 'p-2 rounded-md hover:bg-gray-300']"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div
      v-if="focalContact.linkedContactDataField"
      class="relative flex items-center gap-2"
    >
      <DataField
        :data-field="focalContact.linkedContactDataField"
        :no-dropdown="injected"
        primary-text-path="fieldContent.name"
        text-classes="text-sm font-semibold"
        text-styles=""
      />
      <div v-if="injected" class="absolute inset-0" />
      <h2
        v-if="injected || isHovered"
        class="text-xs text-gray-500 font-medium"
      >
        {{ focalContact.name }}
      </h2>
    </div>
    <div v-else class="flex items-center gap-2">
      <AvatarPhoto :person="focalContact" shape-size="h-8 w-8" text-size="md" />
      <h2 class="text-sm text-gray-700 font-medium">
        {{ focalContact.name }}
      </h2>
    </div>
    <div v-if="!injected" class="flex items-center gap-1">
      <button
        v-tooltip="'Message'"
        type="button"
        class="rounded-full p-2 text-gray-500 shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
        @click="wePanelStore.directMessage(focalContact)"
      >
        <ChatBubbleOvalLeftIcon class="size-6" aria-hidden="true" />
      </button>
      <button
        type="button"
        class="rounded-full p-2 text-gray-500 shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
      >
        <EllipsisVerticalIcon class="size-6" aria-hidden="true" />
      </button>
    </div>
  </li>
</template>

<script setup>
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useWePanelStore } from "@/stores/wePanel";
import {
  ChatBubbleOvalLeftIcon,
  EllipsisVerticalIcon,
} from "@heroicons/vue/20/solid";
import { computed, onMounted, ref } from "vue";

const props = defineProps(["networking", "injected"]);

const wePanelStore = useWePanelStore();

const isHovered = ref(false);
const focalContact = computed(() =>
  wePanelStore.networkingCounterpart(props.networking),
);

onMounted(() => {
  console.log("mounted networking el");
});
</script>
