<template>
  <div
    v-if="currentUser"
    class="p-4"
    :class="workspaceLayout === 'sideBySide' ? 'space-y-10' : ''"
  >
    <div>
      <h3 class="text-xl font-semibold leading-6 text-gray-900">
        Personal Info
      </h3>
      <p class="max-w-2xl text-sm text-gray-500">Manage your contact info.</p>
      <dl>
        <UpdateName v-if="updatingName" @cancel="updatingName = false" />
        <StepUpAuth
          v-else-if="stepUpName"
          input-id="updateName"
          :cancellable="true"
          @cancel="stepUpName = false"
          @authenticated="updateNameAuthenticated"
        />
        <div
          v-else
          class="py-4"
          :class="
            workspaceLayout === 'sideBySide'
              ? ''
              : 'grid grid-cols-3 gap-4 py-5'
          "
        >
          <dt class="text-sm font-medium text-gray-500">Name</dt>
          <dd
            class="mt-1 flex text-sm text-gray-900"
            :class="workspaceLayout === 'sideBySide' ? '' : 'col-span-2 mt-0'"
          >
            <span class="flex-grow">{{ currentUser.name }}</span>
            <span class="ml-4 flex-shrink-0">
              <button
                @click="updateName"
                type="button"
                class="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                data-test="update-name-button"
              >
                Update
              </button>
            </span>
          </dd>
        </div>
        <ResetEmail v-if="resettingEmail" @cancel="resettingEmail = false" />
        <StepUpAuth
          v-else-if="stepUpEmail"
          input-id="emailReset"
          :cancellable="true"
          @cancel="stepUpEmail = false"
          @authenticated="emailResetAuthenticated"
        />
        <div
          v-else
          class="py-4"
          :class="
            workspaceLayout === 'sideBySide'
              ? ''
              : 'grid grid-cols-3 gap-4 py-5 pt-5'
          "
        >
          <dt class="text-sm font-medium text-gray-500">Email</dt>
          <dd
            class="mt-1 flex text-sm text-gray-900"
            :class="workspaceLayout === 'sideBySide' ? '' : 'col-span-2 mt-0'"
          >
            <span class="flex-grow" data-test="account-email">{{
              currentUser.email
            }}</span>
            <span class="ml-4 flex-shrink-0">
              <button
                @click="updateEmail"
                type="button"
                class="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                data-test="reset-email-button"
              >
                Update
              </button>
            </span>
          </dd>
        </div>
        <DigestConsent />
        <div class="pt-6">
          <h3 class="font-medium leading-6 text-gray-900">
            Networking Requests
          </h3>
          <div class="mt-1 max-w-xl text-sm text-gray-500">
            <p>Who can ask to connect with you.</p>
          </div>
          <NetworkingConsent
            v-model="networkingAllConsent"
            networking-consent-type="Everyone"
          />
          <NetworkingConsent
            v-model="networkingFriendOfFriendsConsent"
            networking-consent-type="Friend of friends"
          />
          <NetworkingConsent
            v-model="networkingHubMembersConsent"
            networking-consent-type="Hub members"
          />
        </div>
      </dl>
    </div>

    <div :class="workspaceLayout === 'sideBySide' ? 'hidden' : 'block'">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div
      :class="workspaceLayout === 'sideBySide' ? '' : 'grid grid-cols-3 gap-6'"
    >
      <div :class="workspaceLayout === 'sideBySide' ? '' : 'col-span-1'">
        <h3
          id="payment-methods"
          class="text-xl font-semibold leading-6 text-gray-900"
        >
          Data Linkages
        </h3>
        <p class="mt-1 text-sm leading-5 text-gray-500">
          Manage linked contact and company data.
        </p>
      </div>
      <div
        :class="workspaceLayout === 'sideBySide' ? 'mt-5' : 'mt-0 col-span-2'"
        class="space-y-5"
      >
        <CompanyMemberships />
      </div>
    </div>

    <div :class="workspaceLayout === 'sideBySide' ? 'hidden' : 'block'">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div
      :class="workspaceLayout === 'sideBySide' ? '' : 'grid grid-cols-3 gap-6'"
    >
      <div :class="workspaceLayout === 'sideBySide' ? '' : 'col-span-1'">
        <h3
          id="payment-methods"
          class="text-xl font-semibold leading-6 text-gray-900"
        >
          Spending on Tower Hunt
        </h3>
        <p class="mt-1 text-sm leading-5 text-gray-500">
          Manage your payment method(s) and spending.
        </p>
      </div>
      <div
        v-if="steppedUp"
        :class="workspaceLayout === 'sideBySide' ? 'mt-5' : 'mt-0 col-span-2'"
        class="space-y-5"
      >
        <TeamSubsidyStatus />
        <PaymentMethods
          v-if="!modalPayload"
          :selectable="false"
          :capturing-payment="false"
        />
        <UserSubscription />
      </div>
      <StepUpAuth
        v-else
        input-id="paymentMethods"
        :cancellable="false"
        @authenticated="authenticated"
      />
    </div>

    <div :class="workspaceLayout === 'sideBySide' ? 'hidden' : 'block'">
      <div class="py-5">
        <div class="border-t border-gray-200"></div>
      </div>
    </div>

    <div
      :class="workspaceLayout === 'sideBySide' ? '' : 'grid grid-cols-3 gap-6'"
    >
      <div :class="workspaceLayout === 'sideBySide' ? '' : 'col-span-1'">
        <h3 id="payouts" class="text-xl font-semibold leading-6 text-gray-900">
          Earning on Tower Hunt
        </h3>
        <p class="mt-1 text-sm leading-5 text-gray-500">
          Manage your Tower Hunt earnings across personal and shared payout
          accounts.
        </p>
      </div>
      <div
        v-if="steppedUp"
        :class="workspaceLayout === 'sideBySide' ? 'mt-5' : 'mt-0 col-span-2'"
        class="space-y-5"
      >
        <PaymentConnectedAccountGateway
          :value="payoutsDashboardObject"
          @connected-account-checked="connectedAccountChecked = true"
        />
        <SharedPayoutAccountsList v-if="defaultPayoutAccountChecked" />
        <div v-if="defaultPayoutAccountChecked">
          <h3 class="text-base leading-6 font-medium text-gray-900">
            Default Payout Account
          </h3>
          <p class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
            Designate where future earnings will flow by default. You can change
            this on a case-by-case basis in
            <a href="" @click.prevent="goToContributions" class="underline"
              >My Contributions</a
            >.
          </p>
          <div class="mt-6">
            <PayoutSelector
              :user-default-account="true"
              :selected-account="defaultPayoutAccount"
              :shared-payout-accounts="sharedPayoutAccounts"
              @update-account="updateDefaultAccount"
            />
          </div>
        </div>
      </div>
      <StepUpAuth
        v-else
        input-id="payouts"
        :cancellable="false"
        @authenticated="authenticated"
      />
    </div>
  </div>
</template>

<script setup>
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useUserStore } from "@/stores/user";
import { useModalStore } from "@/stores/modal";
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import PaymentMethods from "@/components/users/PaymentMethods.vue";
import PaymentConnectedAccountGateway from "@/components/users/PaymentConnectedAccountGateway.vue";
import PaymentConnectedAccountDashboard from "@/components/users/PaymentConnectedAccountDashboard.vue";
import ResetEmail from "@/components/auth/ResetEmail.vue";
import SharedPayoutAccountsList from "@/components/users/SharedPayoutAccountsList.vue";
import StepUpAuth from "@/components/auth/StepUpAuth.vue";
import UpdateName from "@/components/account/UpdateName.vue";
import CompanyMemberships from "@/components/account/CompanyMemberships.vue";
import DigestConsent from "@/components/account/DigestConsent.vue";
import NetworkingConsent from "@/components/account/NetworkingConsent.vue";
import UserSubscription from "@/components/users/UserSubscription.vue";
import TeamSubsidyStatus from "@/components/users/TeamSubsidyStatus.vue";
import PayoutSelector from "@/components/crowdsourcing/contributions/PayoutSelector.vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/router/api";

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const userStore = useUserStore();
const {
  currentUser,
  networkingAllConsent,
  networkingFriendOfFriendsConsent,
  networkingHubMembersConsent,
} = storeToRefs(userStore);
const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);

const steppedUp = ref(false);
const stepUpEmail = ref(false);
const resettingEmail = ref(false);
const stepUpName = ref(false);
const updatingName = ref(false);
const defaultPayoutAccount = ref(null);
const sharedPayoutAccounts = ref([]);
const connectedAccountChecked = ref(false);
const defaultPayoutAccountChecked = ref(false);

watch(connectedAccountChecked, () => {
  if (connectedAccountChecked.value) {
    console.log("Connected account checked");
    setTimeout(() => {
      fetchDefaultPayoutAccount();
      fetchPayoutAccounts();
    }, 1000);
  }
});

async function fetchDefaultPayoutAccount() {
  api.get(`user_default_payout_accounts`).then((json) => {
    defaultPayoutAccount.value = json.data;
    defaultPayoutAccountChecked.value = true;
  });
}
async function fetchPayoutAccounts() {
  if (sharedPayoutAccounts.value.length === 0) {
    api.get(`shared_payout_accounts?teams=true`).then((json) => {
      sharedPayoutAccounts.value = json.data;
    });
  }
}
function updateDefaultAccount(newAccount) {
  defaultPayoutAccount.value = newAccount;
}
function updateNameAuthenticated() {
  stepUpName.value = false;
  updatingName.value = true;
  authenticated();
}
function emailResetAuthenticated() {
  stepUpEmail.value = false;
  resettingEmail.value = true;
  authenticated();
}
function authenticated() {
  steppedUp.value = true;
}
function updateName() {
  if (steppedUp.value) updatingName.value = true;
  else stepUpName.value = true;
}
function updateEmail() {
  if (steppedUp.value) resettingEmail.value = true;
  else stepUpEmail.value = true;
}

const payoutsDashboardObject = computed(() => {
  return {
    component: PaymentConnectedAccountDashboard,
    props: {
      returnUrl: `${currentUser.value.host}/?horizontalTab=Me&verticalTab=Settings`,
    },
  };
});

const router = useRouter();
const route = useRoute();

function goToContributions() {
  router.push({
    name: "MainMap",
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Contributions",
    },
  });
}
</script>
