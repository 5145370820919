<template>
  <div>
    <ExpandedMessageInput
      v-if="isDesktop"
      :context="context"
      :membership="membership"
      @message-sent="emit('message-sent')"
    />
    <MobileMessageInput
      v-else
      :context="context"
      :membership="membership"
      @message-sent="emit('message-sent')"
    />
  </div>
</template>

<script setup>
import MobileMessageInput from "@/components/we/message-input/MobileMessageInput.vue";
import ExpandedMessageInput from "@/components/we/message-input/ExpandedMessageInput.vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";

const props = defineProps(["context", "membership"]);
const emit = defineEmits(["message-sent"]);

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
</script>
