<template>
  <div
    v-if="!signedIn"
    :class="isDesktop ? 'h-full p-4' : 'min-h-screen py-12 px-6'"
    class="flex flex-col items-center justify-center p-4"
  >
    <div :class="isDesktop ? 'max-w-sm' : 'max-w-md'" class="w-full">
      <div>
        <router-link to="/">
          <img
            :class="isDesktop ? 'h-12' : 'h-16'"
            class="mx-auto w-auto"
            src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png"
            alt="Tower Hunt"
          />
        </router-link>
        <DiscussionHubInvitationSummary
          v-if="hubInvitation"
          :invitation="hubInvitation"
        />
        <template v-else>
          <h2
            v-if="teamInvitation"
            :class="isDesktop ? 'text-xl leading-6' : 'text-3xl leading-9'"
            class="mt-6 text-center font-extrabold text-gray-900"
            data-test="register-join-team-name"
          >
            Join {{ teamInvitation.teamName }}
          </h2>
          <h2
            v-else
            :class="isDesktop ? 'text-xl leading-6' : 'text-3xl leading-9'"
            class="mt-6 text-center font-extrabold text-gray-900"
          >
            Sign up for an account
          </h2>
        </template>
        <p class="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
          Already registered?
          <a
            @click.prevent="switchAuth"
            href=""
            class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline"
          >
            Sign in
          </a>
          to your account.
        </p>
      </div>

      <div class="mt-8">
        <form
          @submit.prevent="register"
          class="space-y-6"
          data-test="register-form"
        >
          <div class="space-y-1">
            <label for="name" class="block text-sm font-medium text-gray-700">
              Your name
            </label>
            <div class="mt-1">
              <input
                v-focus
                v-model="form.name"
                id="name"
                name="name"
                type="name"
                autocomplete="name"
                required
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                data-test="register-name"
              />
            </div>
          </div>

          <div class="space-y-1">
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <input
                v-model="form.email"
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                required
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                data-test="register-email"
              />
            </div>
          </div>

          <div class="space-y-1">
            <label
              for="password"
              class="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div class="mt-1">
              <input
                v-model="form.password"
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                data-test="register-password"
              />
            </div>
          </div>

          <span class="block text-sm text-gray-900">
            By clicking Join, you agree to the
            <router-link
              to="/legal/terms-of-service"
              target="_blank"
              class="font-medium text-indigo-600 hover:text-indigo-500"
              >Terms of Service</router-link
            >.
          </span>

          <div>
            <button
              type="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Join
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useUserStore } from "@/stores/user";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useModalStore } from "@/stores/modal";
import { useAuthStore } from "@/stores/auth";
import { useGuestProfileStore } from "@/stores/guestProfile";
import { useNotificationsStore } from "@/stores/notifications";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import DiscussionHubInvitationSummary from "@/components/we/channels-format/DiscussionHubInvitationSummary.vue";
import User from "@/stores/models/user";
import api from "@/router/api";
import _ from "lodash";

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { signedIn } = storeToRefs(userStore);
const secondaryPanelStore = useSecondaryPanelStore();
const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);
const authStore = useAuthStore();
const notificationsStore = useNotificationsStore();
const guestProfileStore = useGuestProfileStore();
const { geographyIntents, collectibleCards, guestTasks, guestTaskLists } =
  storeToRefs(guestProfileStore);

const form = ref({
  name: null,
  email: null,
  password: null,
});
const hubInvitation = ref(null);
const teamInvitation = ref(null);
const userInvitation = ref(null);
const easyDataInputJobInvitation = ref(null);
const validForm = computed(() => {
  return (
    _.trim(form.value.name) !== "" &&
      userStore.emailRegex.test(_.trim(form.value.email)),
    _.trim(form.value.password) !== ""
  );
});

const router = useRouter();
const route = useRoute();
const routeName = computed(() => route.name);

watch(isDesktop, () => {
  secondaryPanelStore.promptToRegister();
});

onMounted(async () => {
  if (route.query.hubInvitationToken) {
    await fetchHubInvitation();
  } else if (route.query.teamInvitationToken) {
    await fetchTeamInvitation();
  } else if (route.query.userInvitationToken) {
    await fetchUserInvitation();
  } else if (route.query.easyDataInputJobInvitationToken) {
    await fetchEasyDataInputJobInvitation();
  }

  if (modalPayload.value) modalStore.closeModal();

  if (signedIn.value) {
    if (isDesktop.value && route.name !== "Register") {
      secondaryPanelStore.setAuthenticate();
      secondaryPanelStore.revertPanel();
    } else {
      router.push({ name: "MainMap" });
    }
  }
});

async function fetchHubInvitation() {
  api
    .get(`discussion_hub_invitations/${route.query.hubInvitationToken}`)
    .then((json) => {
      hubInvitation.value = json.data;
    });
}
async function fetchTeamInvitation() {
  api
    .get(`team_invitations/${route.query.teamInvitationToken}`)
    .then((json) => {
      teamInvitation.value = json.data;
      form.value.email = teamInvitation.value.email;
    });
}
async function fetchUserInvitation() {
  api
    .get(`user_invitations/${route.query.userInvitationToken}`)
    .then((json) => {
      userInvitation.value = json.data;
      form.value.email = userInvitation.value.email;
    });
}
async function fetchEasyDataInputJobInvitation() {
  const response = await api.get(
    `easy_data_input_job_invitations/${route.query.easyDataInputJobInvitationToken}`,
  );

  if (response?.data) {
    if (signedIn.value) {
      await acceptEasyDataInputJobInvitation(response.data.token);
    } else {
      easyDataInputJobInvitation.value = response.data;
      form.value.email = easyDataInputJobInvitation.value.email;
    }
  }
}

async function acceptEasyDataInputJobInvitation(token) {
  const response = await api.patch(`easy_data_input_job_invitations/${token}`);

  notificationsStore.addNotification("easyDataInviteAccepted");

  return response;
}

function switchAuth() {
  if (isDesktop.value && routeName.value === "MainMap") {
    secondaryPanelStore.setAuthenticate({ name: "SignIn" });
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Authenticate",
        verticalTab: "SignIn",
      },
    });
  } else {
    router.push({
      name: "SignIn",
      query: {
        hubInvitationToken: route.query.hubInvitationToken,
        teamInvitationToken: route.query.teamInvitationToken,
        userInvitationToken: route.query.userInvitationToken,
        easyDataInputJobInvitationToken:
          route.query.easyDataInputJobInvitationToken,
      },
    });
  }
}
function register() {
  let payload = form.value;
  payload.geographyIntents = geographyIntents.value;
  payload.collectibleCards = collectibleCards.value;
  payload.tasks = guestTasks.value;
  payload.taskLists = guestTaskLists.value;

  if (hubInvitation.value) {
    payload.hubInvitationToken = hubInvitation.value.token;
  } else if (teamInvitation.value)
    payload.teamInvitationToken = teamInvitation.value.token;
  else if (userInvitation.value)
    payload.userInvitationToken = userInvitation.value.token;
  else if (easyDataInputJobInvitation.value)
    payload.easyDataInputJobInvitationToken =
      easyDataInputJobInvitation.value.token;

  if (validForm.value) {
    // this.$store.commit("isLoading");
    api.post(`registrations`, payload).then(
      (json) => {
        // this.$store.dispatch("flash", `Welcome to Tower Hunt!`);
        secondaryPanelStore.setAuthenticate();
        secondaryPanelStore.revertPanel();

        const token = json.data.id;

        form.value = {
          name: null,
          email: null,
          password: null,
        };
        hubInvitation.value = null;
        teamInvitation.value = null;
        userInvitation.value = null;
        easyDataInputJobInvitation.value = null;
        geographyIntents.value = [];
        localStorage.setItem("token", token);
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        api.get("whoami").then((user) => {
          // this.$cable.connection.connect();
          authStore.signIn(new User(user.data)).then(() => {
            // this.$store.commit("doneLoading");
            if (isDesktop.value && route.name !== "Register") {
              secondaryPanelStore.setAuthenticate();
              secondaryPanelStore.revertPanel();
            } else {
              router.push({ name: "MainMap" });
            }
          });
        });
      },
      (failure) => {
        form.value.password = null;
        notificationsStore.addNotification("accountAlreadyExists");
        // this.$store.commit("doneLoading");
        console.log(failure);
      },
    );
  }
}
</script>
