<template>
  <header class="flex items-center justify-between gap-x-3">
    <div class="flex items-center gap-x-2">
      <template v-if="threadParentTopic">
        <a
          href=""
          class="flex items-center gap-x-2"
          @click.prevent="viewParent"
        >
          <TopicTypeIcon
            :topic="threadParentTopic"
            lock-size="size-3"
            icon-size="size-5"
          />
          <span
            class="text-base font-semibold text-gray-900 hover:text-gray-700"
          >
            {{ threadParentTopic.name }}
          </span>
        </a>
        <ChevronRightIcon class="size-4 text-gray-400" />
      </template>

      <div
        v-if="context?.scenario === 'createThread'"
        class="flex items-center gap-x-2"
      >
        <TopicTypeIcon
          :topic="{
            contentType: 'thread',
            isPrivate: activeThreadDraft?.isPrivate,
          }"
          lock-size="size-3"
          icon-size="size-5"
        />
        <span class="text-base font-semibold text-gray-900">
          {{
            activeThreadDraft?.name === ""
              ? "New Thread"
              : activeThreadDraft?.name
          }}
        </span>
      </div>

      <div v-else class="flex items-center gap-x-2">
        <TopicTypeIcon
          v-if="mainContent.content?.topicType"
          :topic="mainContent.content"
          lock-size="size-3"
          icon-size="size-5"
        />
        <span class="text-base font-semibold text-gray-900">
          {{ mainContent.content?.name }}
        </span>
      </div>
    </div>
    <ol
      v-if="context?.scenario !== 'createThread'"
      class="flex items-center gap-x-4"
    >
      <li
        v-for="option in wePanelStore.topicOptionsFor(
          mainContent.content,
          'header',
        )"
        :key="option.name"
      >
        <VDropdown
          v-if="option.dropdown"
          class="flex items-center"
          placement="bottom-end"
        >
          <button
            v-tooltip.bottom-end="option.name"
            class="flex items-center"
            href=""
          >
            <component
              :is="option.icon"
              class="size-5 text-gray-500 hover:text-gray-600"
            />
          </button>
          <template #popper>
            <component :is="option.menuComponent" />
          </template>
        </VDropdown>
        <a
          v-else
          v-tooltip.bottom-end="option.name"
          class="flex items-center"
          href=""
          @click.prevent="option.handler"
        >
          <component
            :is="option.icon"
            class="size-5 text-gray-500 hover:text-gray-600"
          />
        </a>
      </li>
    </ol>
  </header>
</template>

<script setup>
import TopicTypeIcon from "@/components/we/channels-format/TopicTypeIcon.vue";
import ChannelMessagesContainer from "@/components/we/main-content-views/ChannelMessagesContainer.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { useWeThreadFormStore } from "@/stores/weThreadForm";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed, markRaw } from "vue";

const props = defineProps(["context"]);

const wePanelStore = useWePanelStore();
const { activeTopicId, mainContent, rightEdgeComponent, channels } =
  storeToRefs(wePanelStore);
const weThreadFormStore = useWeThreadFormStore();
const { draftThreads } = storeToRefs(weThreadFormStore);

const threadParentTopic = computed(() => {
  if (mainContent.value.contentType === "thread") {
    const parentId = mainContent.value.content.threadParentTopicId;

    return _.find(channels.value, { id: parentId });
  } else {
    return null;
  }
});

const activeThreadDraft = computed(() => {
  if (mainContent.value?.content) {
    return _.find(draftThreads.value, {
      parentTopicId: activeTopicId.value,
    });
  } else {
    return null;
  }
});

function viewParent() {
  rightEdgeComponent.value = markRaw(ChannelMessagesContainer);
  mainContent.value = {
    contentType: "channel",
    content: threadParentTopic.value,
  };
}
</script>
