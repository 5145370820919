<template>
  <div class="relative">
    <div v-if="isPrivate" class="absolute -right-1 -top-1 rounded bg-gray-300">
      <LockClosedIcon :class="[lockSize]" />
    </div>
    <component
      :is="topicTypeIcon"
      class="text-gray-500"
      :class="[iconSize]"
      aria-hidden="true"
    />
  </div>
</template>

<script setup>
import {
  LockClosedIcon,
  ChatBubbleLeftRightIcon,
  MegaphoneIcon,
  LightBulbIcon,
  HashtagIcon,
} from "@heroicons/vue/24/solid";
import { computed } from "vue";

const props = defineProps(["topic", "lockSize", "iconSize"]);

const contentType = computed(() => props.topic?.contentType);
const topicType = computed(() => props.topic?.topicType);
const isPrivate = computed(() => props.topic?.isPrivate);
const topicTypeIcon = computed(() => {
  switch (contentType.value) {
    case "thread":
      return ChatBubbleLeftRightIcon;
    case "channel":
    case "directMessage": {
      switch (topicType.value) {
        case "Text":
          return LightBulbIcon;
        case "Spotlight":
          return ChatBubbleLeftRightIcon;
        case "Announcement":
          return MegaphoneIcon;
        default:
          return HashtagIcon;
      }
    }
    default:
      return HashtagIcon;
  }
});
</script>
