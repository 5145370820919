<template>
  <header
    class="flex items-center justify-between p-1 border-b border-gray-300"
  >
    <DataField
      :data-field="dataField"
      text-classes="font-semibold"
      :text-wrap="true"
      :no-image="true"
      dropdown-placement="left-start"
      text-styles=""
    />
    <VDropdown class="mr-2 flex items-center">
      <button
        type="button"
        class="inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
      >
        <ChevronDownIcon class="size-6" aria-hidden="true" />
      </button>
      <template #popper>
        <div class="p-3">
          <ol class="flex flex-col gap-y-2">
            <li v-for="option in dataFieldWeOptions" :key="option.name">
              <a
                v-close-popper
                class="flex items-center gap-x-2"
                href=""
                @click.prevent="option.handler"
              >
                <component
                  :is="option.icon"
                  class="size-5 text-gray-400 hover:text-gray-500"
                />
                <span
                  class="text-sm font-semibold text-gray-400 hover:text-gray-500"
                  >{{ option.name }}</span
                >
              </a>
            </li>
          </ol>
        </div>
      </template>
    </VDropdown>
  </header>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import {
  CogIcon,
  ListBulletIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useModalStore } from "@/stores/modal";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import api from "@/router/api";
import _ from "lodash";

const wePanelStore = useWePanelStore();
const { activeOption } = storeToRefs(wePanelStore);
const modalStore = useModalStore();
const { confirmationPayload } = storeToRefs(modalStore);

const dataField = computed(() => activeOption.value?.matchContent);
const dataFieldWeOptions = computed(() => {
  const rawOptions = [];

  return _.compact(rawOptions);
});
</script>
