<template>
  <div class="w-full relative">
    <button
      class="w-full relative p-2 rounded-lg flex items-center justify-between gap-2"
      :class="[
        isDesktop || context === 'addMembersForm' ? '' : 'bg-gray-200',
        context !== 'addMembersForm' ? 'hover:bg-gray-300' : '',
      ]"
      @click="view"
    >
      <div class="flex items-center gap-2">
        <div
          v-if="membership.linkedContactDataField"
          class="absolute inset-0"
        />
        <DataField
          v-if="membership.linkedContactDataField"
          :data-field="membership.linkedContactDataField"
          :no-dropdown="true"
          primary-text-path="fieldContent.name"
          text-classes="text-sm font-semibold"
          text-styles=""
        />
        <template v-else>
          <AvatarPhoto
            :person="membership"
            shape-size="h-8 w-8"
            text-size="md"
          />
          <h2 class="text-sm text-gray-700 font-medium">
            {{ membership.name }}
          </h2>
        </template>
        <div
          v-if="
            context !== 'addMembersForm' &&
            wePanelStore.propertyAppearancesFor(membership).length > 0
          "
          v-tooltip="'Viewing Properties'"
          class="flex items-center flex-wrap gap-1"
        >
          <BuildingOffice2Icon class="size-4 text-indigo-500" />
          <DataField
            v-for="dataField in wePanelStore.propertyAppearancesFor(membership)"
            :key="dataField.localId"
            :data-field="dataField"
            :no-dropdown="true"
            text-classes="text-xs font-medium"
            text-styles=""
          />
        </div>
      </div>
      <button
        v-if="removable"
        v-tooltip="'Remove member'"
        class="z-10"
        @click.stop="emit('remove')"
      >
        <XCircleIcon class="size-5 text-gray-700" />
      </button>
      <div v-else-if="hubOwner" class="text-sm text-gray-500">Hub owner</div>
    </button>
  </div>
</template>

<script setup>
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { BuildingOffice2Icon } from "@heroicons/vue/20/solid";
import { XCircleIcon } from "@heroicons/vue/24/solid";
import { markRaw } from "vue";
import { storeToRefs } from "pinia";
import ChannelMemberDetail from "@/components/we/channels-format/ChannelMemberDetail.vue";

const props = defineProps(["membership", "context", "removable", "hubOwner"]);
const emit = defineEmits(["remove", "select"]);

const wePanelStore = useWePanelStore();
const {
  bottomEdgeLevelTwoComponent,
  bottomEdgeLevelTwoPosition,
  bottomEdgeLevelTwoProps,
} = storeToRefs(wePanelStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

function view() {
  if (props.context === "addMembersForm") {
    emit("select");
  } else {
    bottomEdgeLevelTwoPosition.value = "full";
    bottomEdgeLevelTwoComponent.value = markRaw(ChannelMemberDetail);
    bottomEdgeLevelTwoProps.value = {
      membership: props.membership,
    };
  }
}
</script>
