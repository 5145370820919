import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import notificationComponents from "@/components/notifications/manifest";
import _ from "lodash";

export const useNotificationsStore = defineStore("notifications", () => {
  const activeNotifications = ref([]);

  function addNotification(id, autoHideSeconds = 3, props = {}) {
    let newNotification = _.find(notificationComponents, { id });

    if (newNotification) {
      if (autoHideSeconds) {
        newNotification.props = _.merge({}, newNotification.props, {
          autoHideSeconds,
        }, props);
      }
      activeNotifications.value.push(newNotification);
    }
  }

  return { activeNotifications, addNotification };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNotificationsStore, import.meta.hot),
  );
}
