import {
  DocumentArrowUpIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/vue/20/solid";
import { useUserStore } from "@/stores/user";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useWePanelStore } from "@/stores/wePanel";
import { useWeThreadFormStore } from "@/stores/weThreadForm";
import { defineStore, storeToRefs, acceptHMRUpdate } from "pinia";
import { computed, markRaw, ref } from "vue";
import api from "@/router/api";
import _ from "lodash";
import ChannelMessagesContainer from "@/components/we/main-content-views/ChannelMessagesContainer.vue";

export const useWeMessageInputStore = defineStore("weMessageInput", () => {
  const secondaryPanelStore = useSecondaryPanelStore();
  const userStore = useUserStore();
  const { signedIn } = storeToRefs(userStore);
  const wePanelStore = useWePanelStore();
  const {
    mainContent,
    levelTwoMainContent,
    rightEdgeLevelTwoComponent,
    rightEdgeLevelTwoProps,
    activeTopicId,
  } = storeToRefs(wePanelStore);
  const weThreadFormStore = useWeThreadFormStore();
  const { draftThreads } = storeToRefs(weThreadFormStore);

  const topicMessageDrafts = ref({});

  const activeDraft = computed(() =>
    _.find(draftThreads.value, {
      parentTopicId: activeTopicId.value,
    }),
  );
  const creationOptions = ref([
    {
      name: "Upload a File",
      handler: () => {
        const callback = (fileInputRef) => {
          fileInputRef.click();
        };
        registrationGuard(callback);
      },
      icon: DocumentArrowUpIcon,
    },
    {
      name: "Create Thread",
      handler: () => {
        const callback = () => {
          createThread();
        };
        registrationGuard(callback);
      },
      icon: ChatBubbleLeftRightIcon,
    },
  ]);
  const actionableCreationOptions = computed(() => {
    if (threadable.value) {
      return creationOptions.value;
    } else {
      return creationOptions.value.filter(
        ({ name }) => name !== "Create Thread",
      );
    }
  });

  const threadable = computed(() =>
    _.includes(["channel"], mainContent.value?.contentType),
  );

  function createThread() {
    if (!activeDraft.value) {
      weThreadFormStore.patchDrafts({
        parentTopicId: mainContent.value.content.id,
        name: "",
        isPrivate: false,
        members: [],
      });
    }
    rightEdgeLevelTwoComponent.value = markRaw(ChannelMessagesContainer);
    rightEdgeLevelTwoProps.value = {
      context: {
        location: "levelTwo",
        scenario: "createThread",
      },
    };
    levelTwoMainContent.value = {
      contentType: "thread",
      content: {
        id: 1,
      },
    };
  }

  function resizeTextArea(event) {
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  }

  async function sendMessage({ context, membership }) {
    const callback = async () => {
      if (context === "channelMemberDetail" && membership) {
        wePanelStore.directMessage(membership);
      } else if (
        rightEdgeLevelTwoProps.value?.context?.scenario === "createThread"
      ) {
        console.log("create thread");
        await saveThread();
      } else {
        console.log("send message", activeTopicId.value);

        if (activeTopicId.value) {
          delete topicMessageDrafts.value[activeTopicId.value];
        }
      }
    };
    registrationGuard(callback);
  }

  async function saveThread() {
    const payload = {
      parentTopicId: activeDraft.value.parentTopicId,
      name: activeDraft.value.name,
      isPrivate: activeDraft.value.isPrivate,
      userIds: activeDraft.value.members.map(({ userId }) => userId),
    };
    if (_.trim(payload.name) === "") {
      payload.name = "New Thread";
    }
    const topicResponse = await api.post(`discussion_topic_threads`, payload);

    wePanelStore.patchChannel(topicResponse.data);
    wePanelStore.closeRightEdgeLevelTwoPanel();
  }

  function registrationGuard(callback) {
    if (signedIn.value) {
      callback();
    } else {
      secondaryPanelStore.promptToRegister();
    }
  }

  return {
    topicMessageDrafts,
    creationOptions,
    actionableCreationOptions,
    threadable,
    resizeTextArea,
    sendMessage,
    createThread,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useWeMessageInputStore, import.meta.hot),
  );
}
