<template>
  <div class="relative flex h-full w-full flex-col overflow-hidden">
    <button
      v-if="!expandedView && isDesktop"
      type="button"
      class="absolute top-2 right-6 rounded-full p-2 flex items-center justify-center text-gray-900 shadow-sm hover:text-gray-700"
      @click="cancel"
    >
      <XMarkIcon class="size-6" aria-hidden="true" />
    </button>
    <UnsavedChangesBanner
      :changes="overviewUnsavedChanges"
      @reset="remount"
      @save="update"
    />
    <div
      class="mx-auto flex h-full w-full max-w-7xl items-start gap-x-8"
      :class="[expandedView ? 'px-8 pt-10' : 'p-4']"
    >
      <main class="flex-1 h-full overflow-y-auto">
        <!-- Main area -->
        <form>
          <div class="space-y-12">
            <div class="border-b border-gray-900/10 pb-12">
              <h2 class="text-base/7 font-semibold text-gray-900">
                Hub Profile
              </h2>
              <p class="mt-1 text-sm/6 text-gray-600">
                Customize how your hub appears in invite links, Hub Discovery,
                and Announcement Topic messages.
              </p>

              <div
                class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8"
                :class="[expandedView ? 'grid-cols-6' : '']"
              >
                <div class="" :class="[expandedView ? 'col-span-4' : '']">
                  <label
                    for="name"
                    class="block text-sm/6 font-medium text-gray-900"
                    >Name</label
                  >
                  <div class="mt-2">
                    <input
                      id="name"
                      v-model="name"
                      v-focus
                      type="text"
                      name="name"
                      class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                      :class="[expandedView ? 'text-sm/6' : '']"
                      placeholder="Hub name"
                    />
                  </div>
                </div>

                <div
                  v-if="false && !activeOption.matchContent?.dataField"
                  class="col-span-full"
                >
                  <label
                    for="photo"
                    class="block text-sm/6 font-medium text-gray-900"
                    >Icon</label
                  >
                  <p class="mt-2 text-sm/6 text-gray-600">
                    We recommend an image of at least 512x512.
                  </p>
                  <div class="mt-2 flex items-center gap-x-3">
                    <UserCircleIcon
                      class="size-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <button
                      type="button"
                      class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Change
                    </button>
                  </div>
                </div>

                <fieldset v-if="expandedView" class="col-span-full">
                  <legend class="block text-sm/6 font-semibold text-gray-900">
                    Banner Color
                  </legend>
                  <RadioGroup
                    v-model="selectedBrandColor"
                    class="mt-2 px-2 flex items-center flex-wrap gap-3"
                    :class="[expandedView ? '' : '']"
                  >
                    <RadioGroupOption
                      v-for="option in brandColorOptions"
                      :key="option.name"
                      v-slot="{ active, checked }"
                      as="template"
                      :value="option"
                      :aria-label="option.name"
                    >
                      <div
                        :class="[
                          option.color,
                          active && checked ? 'ring ring-offset-1' : '',
                          !active && checked ? 'ring-2' : '',
                          'relative flex cursor-pointer items-center justify-center -m-0.5 p-0.5 rounded-lg ring-current focus:outline-none',
                        ]"
                      >
                        <span
                          aria-hidden="true"
                          class="h-12 w-16 rounded-lg border border-black/10 bg-current"
                        />
                      </div>
                    </RadioGroupOption>
                  </RadioGroup>
                </fieldset>

                <div class="col-span-full">
                  <label
                    for="description"
                    class="block text-sm/6 font-medium text-gray-900"
                    >Description</label
                  >
                  <p class="mt-2 text-sm/6 text-gray-600">
                    What is your hub all about? Why should people join?
                  </p>
                  <div class="mt-2">
                    <textarea
                      id="description"
                      v-model="description"
                      name="description"
                      rows="3"
                      class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                      :class="[expandedView ? 'text-sm/6' : '']"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-6 flex items-center justify-end gap-x-6" />
        </form>
      </main>

      <aside
        class="sticky top-8 w-96 shrink-0"
        :class="[expandedView ? 'block' : 'hidden']"
      >
        <!-- Profile header -->
        <DiscussionHubOverviewCard :hub="simulatedHub" />
      </aside>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { UserCircleIcon } from "@heroicons/vue/24/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useWeHubSettingsStore } from "@/stores/weHubSettings";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { RadioGroup, RadioGroupOption } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { computed, nextTick, onMounted } from "vue";
import _ from "lodash";
import DiscussionHubOverviewCard from "@/components/we/channels-format/DiscussionHubOverviewCard.vue";
import UnsavedChangesBanner from "@/components/we/settings-views/UnsavedChangesBanner.vue";

const wePanelStore = useWePanelStore();
const { expandedView, activeOption } = storeToRefs(wePanelStore);
const weHubSettingsStore = useWeHubSettingsStore();
const {
  overviewUnsavedChanges,
  name,
  description,
  brandColorOptions,
  selectedBrandColor,
} = storeToRefs(weHubSettingsStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const simulatedHub = computed(() => {
  return {
    brandColor: selectedBrandColor.value.name,
    name: name.value,
    description: description.value,
    membersCount: activeOption.value.matchContent.membersCount,
    onlineMembersCount: activeOption.value.matchContent.onlineMembersCount,
    createdAt: activeOption.value.matchContent.createdAt,
  };
});

onMounted(() => {
  remount();
});

async function remount() {
  if (activeOption.value.name) {
    weHubSettingsStore.resetOverview();

    await nextTick();

    name.value = activeOption.value.name;
    description.value = activeOption.value.matchContent.description;
    selectedBrandColor.value = _.find(brandColorOptions.value, {
      name: activeOption.value.matchContent.brandColor,
    });
  }
}

async function update() {
  weHubSettingsStore.updateHubOverview();
}

function cancel() {
  wePanelStore.closeBottomEdgeLevelTwoPanel();
}
</script>
