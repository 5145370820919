<template>
  <TransitionRoot
    v-if="!expandedView"
    as="div"
    class="absolute inset-0 flex z-[130]"
    :show="!!rightEdgeLevelThreeComponent"
  >
    <TransitionChild
      as="template"
      enter="transition ease-in-out duration-300 transform"
      enter-from="translate-x-full"
      enter-to="translate-x-0"
      leave="transition ease-in-out duration-300 transform"
      leave-from="translate-x-0"
      leave-to="translate-x-full"
    >
      <div
        class="relative w-full flex flex-col rounded-tl-2xl bg-white"
        @touchstart="handleRightEdgeTouchStart"
        @touchmove="handleRightEdgeTouchMove"
        @touchend="handleRightEdgeTouchEnd"
      >
        <UnsavedChangesBanner
          v-if="!expandedView"
          :changes="roleUnsavedChanges"
          @reset="remount"
          @save="update"
        />
        <div class="px-4 pt-4 flex items-center justify-between">
          <button type="button" @click="close">
            <ChevronLeftIcon class="size-7 text-gray-700 hover:text-gray-900" />
          </button>
          <div v-if="editableRole?.name" class="text-lg font-semibold text-gray-900">{{ editableRole?.name }}</div>
          <div v-if="editableRole?.name" class="w-2" />
        </div>
        <!-- Sidebar component -->
        <component
          :is="rightEdgeLevelThreeComponent"
          v-bind="rightEdgeLevelThreeProps"
        />
      </div>
    </TransitionChild>
  </TransitionRoot>
</template>

<script setup>
import UnsavedChangesBanner from "@/components/we/settings-views/UnsavedChangesBanner.vue";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import { useWePanelStore } from "@/stores/wePanel";
import { useWeHubSettingsStore } from "@/stores/weHubSettings";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import _ from "lodash";
import { ChevronLeftIcon } from "@heroicons/vue/24/outline";

const wePanelStore = useWePanelStore();
const { expandedView, rightEdgeLevelThreeComponent, rightEdgeLevelThreeProps } =
  storeToRefs(wePanelStore);
const weHubSettingsStore = useWeHubSettingsStore();
const {
  selectedTabLayeredComponents,
  editableRole,
  roleUnsavedChanges,
  unsavedChangesNavigationGuard,
} = storeToRefs(weHubSettingsStore);

// Right edge panel touch event handling
let touchStartX = ref(0);
let touchEndX = ref(0);
const touchThreshold = ref(50); // Adjust as needed for sensitivity

function handleRightEdgeTouchStart(event) {
  touchStartX.value = event.touches[0].clientX;
}

function handleRightEdgeTouchMove(event) {
  touchEndX.value = event.touches[0].clientX;
}

function handleRightEdgeTouchEnd() {
  if (touchStartX.value - touchEndX.value < -touchThreshold.value) {
    // Swipe from left to right detected
    close();
  }
  // Reset values
  touchStartX.value = 0;
  touchEndX.value = 0;
}

function close() {
  if (roleUnsavedChanges.value) {
    unsavedChangesNavigationGuard.value = true;
  } else if (selectedTabLayeredComponents.value.length > 0) {
    weHubSettingsStore.backLayeredComponent();

    const raw = _.last(selectedTabLayeredComponents.value);

    rightEdgeLevelThreeComponent.value = raw;
  } else {
    wePanelStore.closeRightEdgeLevelThreePanel();
  }
}

async function remount() {
  weHubSettingsStore.resetRoles();
}

async function update() {
  weHubSettingsStore.updateRoles();
}
</script>
