<template>
  <div class="w-full relative" @click.right.exact.prevent="clickType = 'right'">
    <VDropdown
      :placement="clickType === 'right' ? 'bottom' : 'left'"
      :triggers="[]"
      :shown="!!clickType"
      @hide="clickType = null"
    >
      <button
        class="w-full relative p-2 rounded-lg hover:bg-gray-200 flex items-center justify-between gap-2"
        :class="[isDesktop ? '' : 'bg-gray-200']"
        @click.left.exact.prevent="clickType = 'left'"
      >
        <div class="flex items-center gap-2">
          <div
            v-if="membership.linkedContactDataField"
            class="absolute inset-0"
          />
          <DataField
            v-if="membership.linkedContactDataField"
            :data-field="membership.linkedContactDataField"
            :no-dropdown="true"
            primary-text-path="fieldContent.name"
            text-classes="text-sm font-semibold"
            text-styles=""
          />
          <template v-else>
            <AvatarPhoto
              :person="membership"
              shape-size="h-8 w-8"
              text-size="md"
            />
            <h2 class="text-sm text-gray-700 font-medium">
              {{ membership.name }}
            </h2>
          </template>
          <div
            v-if="wePanelStore.propertyAppearancesFor(membership).length > 0"
            v-tooltip="'Viewing Properties'"
            class="flex items-center flex-wrap gap-1"
          >
            <BuildingOffice2Icon class="size-4 text-indigo-500" />
            <DataField
              v-for="dataField in wePanelStore.propertyAppearancesFor(
                membership,
              )"
              :key="dataField.localId"
              :data-field="dataField"
              :no-dropdown="true"
              text-classes="text-xs font-medium"
              text-styles=""
            />
          </div>
        </div>
        <button v-if="removable" v-tooltip="'Remove member'" class="z-10" @click.stop="emit('remove')">
          <XCircleIcon class="size-5 text-gray-700" />
        </button>
      </button>
      <template #popper>
        <ChannelMemberDetail
          v-if="clickType === 'left'"
          :membership="membership"
          @blur="clickType = null"
          @message-sent="clickType = null"
        />
        <div
          v-else-if="clickType === 'right'"
          class="p-2"
          @blur="clickType = null"
        >
          Right...
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script setup>
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { BuildingOffice2Icon } from "@heroicons/vue/20/solid";
import { XCircleIcon } from "@heroicons/vue/24/solid";
import { ref } from "vue";
import { storeToRefs } from "pinia";
import ChannelMemberDetail from "@/components/we/channels-format/ChannelMemberDetail.vue";

defineProps(["membership", "removable"]);
const emit = defineEmits(["remove"]);

const wePanelStore = useWePanelStore();
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const clickType = ref(null);
</script>
