<template>
  <NetworkHeader class="" />
  <FriendRequestContainer v-if="networkTab === 'Pending'" class="flex-grow" />
  <FriendListContainer v-else class="flex-grow" />
</template>

<script setup>
import NetworkHeader from "@/components/we/main-content/NetworkHeader.vue";
import FriendListContainer from "@/components/we/main-content/FriendListContainer.vue";
import FriendRequestContainer from "@/components/we/main-content/FriendRequestContainer.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

const wePanelStore = useWePanelStore();
const { networkTab } = storeToRefs(wePanelStore);

onMounted(() => {
  wePanelStore.fetchNetworkings();
});
</script>
