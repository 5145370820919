<template>
  <div class="relative flex flex-col">
    <button
      v-if="!expandedView"
      type="button"
      class="absolute top-2 right-2 rounded-full p-2 flex items-center justify-center text-gray-900 shadow-sm hover:text-gray-700"
      @click="cancel"
    >
      <XMarkIcon class="size-6" aria-hidden="true" />
    </button>
    <div class="p-2">
      <h2 class="text-base/6 font-semibold text-gray-900">
        Server Invite Link Settings
      </h2>
      <div class="flex items-center gap-x-1">
        <TopicTypeIcon
          :topic="channel"
          lock-size="size-3"
          icon-size="size-5"
        />
        <h3 class="text-xs text-gray-700">
          {{ channel.name }}
        </h3>
      </div>
    </div>
    <fieldset class="px-2 pt-2 py-4 flex-grow space-y-4 overflow-visible">
      <Listbox v-model="maxAge" as="div">
        <ListboxLabel class="block text-sm/6 font-medium text-gray-900"
          >Expire After</ListboxLabel
        >
        <div class="relative mt-2">
          <ListboxButton
            class="grid w-full cursor-default grid-cols-1 rounded-md bg-white py-1.5 pl-3 pr-2 text-left text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
          >
            <span class="col-start-1 row-start-1 truncate pr-6">{{
              maxAge.label
            }}</span>
            <ChevronUpDownIcon
              class="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4"
              aria-hidden="true"
            />
          </ListboxButton>

          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
            >
              <ListboxOption
                v-for="ageOption in ageOptions"
                :key="ageOption.label"
                v-slot="{ active, selected }"
                as="template"
                :value="ageOption"
              >
                <li
                  :class="[
                    active
                      ? 'bg-indigo-600 text-white outline-none'
                      : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                  ]"
                >
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]"
                    >{{ ageOption.label }}</span
                  >

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon class="size-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>

      <Listbox v-model="maxUses" as="div">
        <ListboxLabel class="block text-sm/6 font-medium text-gray-900"
          >Max Number of Uses</ListboxLabel
        >
        <div class="relative mt-2">
          <ListboxButton
            class="grid w-full cursor-default grid-cols-1 rounded-md bg-white py-1.5 pl-3 pr-2 text-left text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
          >
            <span class="col-start-1 row-start-1 truncate pr-6">{{
              maxUses.label
            }}</span>
            <ChevronUpDownIcon
              class="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4"
              aria-hidden="true"
            />
          </ListboxButton>

          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
            >
              <ListboxOption
                v-for="useOption in useOptions"
                v-slot="{ active, selected }"
                :key="useOption.label"
                as="template"
                :value="useOption"
              >
                <li
                  :class="[
                    active
                      ? 'bg-indigo-600 text-white outline-none'
                      : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                  ]"
                >
                  <span
                    :class="[
                      selected ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]"
                    >{{ useOption.label }}</span
                  >

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 right-0 flex items-center pr-4',
                    ]"
                  >
                    <CheckIcon class="size-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </fieldset>
    <div class="px-2 py-4 border-t border-gray-400 flex items-center gap-x-2">
      <button
        type="button"
        class="w-1/2 rounded bg-white p-2 text-xs font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        @click="cancel"
      >
        Cancel
      </button>
      <button
        type="button"
        class="w-1/2 rounded bg-indigo-600 p-2 text-xs font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        @click="generateInviteLink"
      >
        Generate a New Link
      </button>
    </div>
  </div>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/16/solid";
import { CheckIcon } from "@heroicons/vue/20/solid";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import TopicTypeIcon from "@/components/we/channels-format/TopicTypeIcon.vue";
import AddDiscussionTopicInvitees from "@/components/we/channels-format/AddDiscussionTopicInvitees.vue";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const props = defineProps(["channel"]);
const emit = defineEmits(["primary-component"]);

const wePanelStore = useWePanelStore();
const { expandedView } = storeToRefs(wePanelStore);

const thirtyMinutesSeconds = 60 * 30;
const oneHourSeconds = 60 * 60;
const sixHoursSeconds = 60 * 60 * 6;
const twelveHoursSeconds = 60 * 60 * 12;
const oneDaySeconds = 60 * 60 * 24;
const sevenDaysSeconds = 60 * 60 * 24 * 7;
const ageOptions = [
  { label: "30 Minutes", value: thirtyMinutesSeconds },
  { label: "1 Hour", value: oneHourSeconds },
  { label: "6 Hours", value: sixHoursSeconds },
  { label: "12 Hours", value: twelveHoursSeconds },
  { label: "1 Day", value: oneDaySeconds },
  { label: "7 Days", value: sevenDaysSeconds },
  { label: "Never", value: 0 },
];
const maxAge = ref({ label: "7 Days", value: sevenDaysSeconds });
const useOptions = [
  { label: "No limit", value: 0 },
  { label: "1 use", value: 1 },
  { label: "5 uses", value: 5 },
  { label: "10 uses", value: 10 },
  { label: "25 uses", value: 25 },
  { label: "50 uses", value: 50 },
  { label: "100 uses", value: 100 },
];
const maxUses = ref({ label: "No limit", value: 0 });

async function generateInviteLink() {
  await wePanelStore.createTopicInvitation({
    topicId: props.channel.id,
    maxAge: maxAge.value.value,
    maxUses: maxUses.value.value,
  });

  cancel();
}

function cancel() {
  if (expandedView.value) {
    emit("primary-component");
  } else {
    wePanelStore.openSecondaryContentPanel({
      component: AddDiscussionTopicInvitees,
      props: {
        channel: props.channel,
      },
    });
  }
}
</script>
