<template>
  <VDropdown
    class="flex items-center"
    :placement="placement || 'auto'"
    @show="emit('open')"
    @hide="emit('closed')"
  >
    <slot name="button"></slot>
    <template #popper>
      <KeepAlive>
        <component
          :is="alternateComponent"
          v-bind="{ channel }"
          v-if="alternateComponent"
          @primary-component="viewPrimary"
        />
        <AddDiscussionTopicInvitees
          v-else
          :channel="channel"
          @alternate-component="viewAlternate"
          @primary-component="viewPrimary"
        />
      </KeepAlive>
    </template>
  </VDropdown>
</template>

<script setup>
import AddDiscussionTopicInvitees from "@/components/we/channels-format/AddDiscussionTopicInvitees.vue";
import { markRaw, ref } from "vue";

defineProps(["channel", "placement"]);
const emit = defineEmits(["open", "closed"]);

const alternateComponent = ref(null);

function viewPrimary() {
  alternateComponent.value = null;
}
function viewAlternate(component) {
  alternateComponent.value = markRaw(component);
}
</script>
