<template>
  <div
    v-if="presence"
    class="absolute"
    :class="[
      linked || linkRequestPending ? '-left-1.5 -top-1.5' : '-left-1 -top-1',
    ]"
  >
    <ShieldCheckIcon
      v-if="linked"
      v-tooltip="`${_.capitalize(presence)} - Linked to a Tower Hunt contact`"
      class="size-4 bg-white rounded-full"
      :class="[
        presence === 'here' ? 'text-green-500' : '',
        presence === 'away' ? 'text-orange-400' : '',
        presence === 'offline' ? 'text-gray-600' : '',
      ]"
    />
    <ShieldExclamationIcon
      v-else-if="linkRequestPending"
      v-tooltip="
        `${_.capitalize(presence)} - Contact link request under review`
      "
      class="size-4 bg-white rounded-full"
      :class="[
        presence === 'here' ? 'text-green-500' : '',
        presence === 'away' ? 'text-orange-400' : '',
        presence === 'offline' ? 'text-gray-600' : '',
      ]"
    />
    <div
      v-else
      v-tooltip="
        `${_.capitalize(presence)} - Not linked to a Tower Hunt contact`
      "
      class="size-3 rounded-full ring-2 ring-white"
      :class="[
        presence === 'here' ? 'bg-green-500' : '',
        presence === 'away' ? 'bg-orange-400' : '',
        presence === 'offline' ? 'bg-gray-600' : '',
      ]"
    />
  </div>
</template>

<script setup>
import {
  ShieldCheckIcon,
  ShieldExclamationIcon,
} from "@heroicons/vue/20/solid";
import _ from "lodash";
import { useWeChannelStore } from "@/stores/weChannel";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const props = defineProps(["person"]);

const weChannelStore = useWeChannelStore();
const { userPresence } = storeToRefs(weChannelStore);
const linked = computed(
  () => props.person?.linkedContactId || props.person?.linkedUserId,
);
const linkRequestPending = computed(() => props.person?.linkRequestPending);
const presence = computed(() => {
  if (props.person?.token) {
    return _.find(userPresence.value, { token: props.person?.token })?.presence || props.person?.presence;
  } else {
    return props.person?.presence;
  }
});
</script>
