<template>
  <header class="flex items-center justify-between gap-x-3">
    <a
      href=""
      class="flex items-center gap-x-2"
      :class="[context?.scenario === 'createThread' ? 'cursor-default' : '']"
      @click.prevent="topicDetailsMenu"
    >
      <TopicTypeIcon
        v-if="mainContent.content?.topicType"
        :topic="mainContent.content"
        lock-size="size-3"
        icon-size="size-5"
      />
      <div class="flex flex-col">
        <div class="flex items-center space-x-1">
          <div class="text-base font-semibold text-gray-900">
            {{ mainContent.content?.name }}
          </div>
          <ChevronRightIcon
            v-if="context?.scenario !== 'createThread'"
            class="size-3 text-gray-900 hover:text-gray-700"
          />
        </div>
        <div
          v-if="threadParentTopic"
          class="text-xs text-gray-500 hover:text-gray-400"
        >
          {{ threadParentTopic.name }}
        </div>
      </div>
    </a>
    <ol
      v-if="context?.scenario !== 'createThread'"
      class="flex items-center gap-x-4"
    >
      <li
        v-for="option in wePanelStore.topicOptionsFor(
          mainContent.content,
          'header',
        )"
        :key="option.name"
      >
        <a
          v-tooltip.bottom-end="option.name"
          class="flex items-center"
          href=""
          @click.prevent="option.handler"
        >
          <component
            :is="option.icon"
            class="size-5 text-gray-500 hover:text-gray-600"
          />
        </a>
      </li>
    </ol>
  </header>
</template>

<script setup>
import TopicTypeIcon from "@/components/we/channels-format/TopicTypeIcon.vue";
import CompactTopicDetailsMenu from "@/components/we/channels-format/CompactTopicDetailsMenu.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const props = defineProps(["context"]);

const wePanelStore = useWePanelStore();
const { mainContent, channels } = storeToRefs(wePanelStore);

const threadParentTopic = computed(() => {
  if (mainContent.value.contentType === "thread") {
    const parentId = mainContent.value.content.threadParentTopicId;

    return _.find(channels.value, { id: parentId });
  } else {
    return null;
  }
});

function topicDetailsMenu() {
  if (props.context?.scenario !== "createThread") {
    wePanelStore.openSecondaryContentPanel({
      component: CompactTopicDetailsMenu,
      props: {},
    });
  }
}
</script>
