<template>
  <div v-if="displayable">
    <div
      v-observe-visibility="{ callback: fetchTopLevelContent, once: true }"
      class="bg-gray-100 border-b border-gray-200"
    >
      <span class="text-gray-700 block p-2 text-sm font-bold"
        >Connect the Dots</span
      >
    </div>
    <a
      v-close-popper
      v-for="action in contextActions"
      :key="action.key"
      @click.prevent="action.clickHandler"
      href=""
      class="text-gray-700 group flex items-center p-2 text-xs hover:bg-gray-100 hover:text-gray-900"
      role="menuitem"
      tabindex="-1"
      id="menu-item-0"
      :data-test="`${_.kebabCase(action.name)}-context-button`"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="flex-shrink-0 mr-3 h-5 w-5 text-indigo-500 group-hover:text-indigo-600"
        fill="currentColor"
        viewBox="0 0 20 20"
        v-html="pathsForIcon(action.svgPaths)"
      ></svg>
      {{ action.name }}
    </a>
  </div>
</template>

<script setup>
import { ref, computed, markRaw, onMounted } from "vue";
import pathsForIcon from "@/assets/pathsForIcon";
import DropboxEmbedViewer from "@/components/DropboxEmbedViewer.vue";
import IFrameEmbedViewer from "@/components/IFrameEmbedViewer.vue";
import { useModalStore } from "@/stores/modal";
import { useUploadedFileStore } from "@/stores/uploadedFile";
import { useRoute, useRouter } from "vue-router";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useTopLevelContentFieldsStore } from "@/stores/topLevelContentFields";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useMainMapStore } from "@/stores/mainMap";
import { useUserStore } from "@/stores/user";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps([
  "dataField",
  "tasks",
  "analyze",
  "userStore",
  "layoutStore",
  "changeGroupStore",
  "unlockerStore",
]);
const userStore = props.userStore || useUserStore();
const { linked, signedIn, currentUser } = storeToRefs(userStore);
const uploadedFileStore = useUploadedFileStore();
const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);
const wePanelStore = useWePanelStore();
const { acceptedNetworkings } = storeToRefs(wePanelStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyIdParam } = storeToRefs(propertyDiagramStore);
const { temporaryMapCenter, newOriginOverride } =
  storeToRefs(useMainMapStore());
const dealStore = useDealBuilderStore();
const propertyFieldsStore = usePropertyFieldsStore();
const topLevelContentFieldsStore = useTopLevelContentFieldsStore();
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();

const displayable = ref(true);
const propertyId = ref(null);

const router = useRouter();
const route = useRoute();
const query = computed(() => route.query);

const horizontalIsWe = computed(
  () => _.get(query.value, "horizontalTab") === "We",
);

const embeddedPropertyId = computed(() => {
  if (_.get(props.dataField, "fieldContentType") === "Property") {
    return props.dataField.fieldContentId;
  } else if (_.get(props.dataField, "decoratingContentType") === "Property") {
    return props.dataField.decoratingContentId;
  } else if (_.get(props.dataField, "joiningContentType") === "Property") {
    return props.dataField.joiningContentId;
  } else {
    return null;
  }
});
const spaceUsageId = computed(() => {
  if (props.dataField.decoratingContentType === "SpaceUsage") {
    return props.dataField.decoratingContentId;
  } else {
    return null;
  }
});
const investmentId = computed(() => {
  if (props.dataField.decoratingContentType === "Investment") {
    return props.dataField.decoratingContentId;
  } else if (props.dataField.fieldContentType === "Investment") {
    return props.dataField.fieldContentId;
  } else if (props.dataField.fieldContent?.investmentId) {
    return props.dataField.fieldContent.investmentId;
  } else if (
    props.dataField.fieldContent?.originationInvestmentType === "Investment"
  ) {
    return props.dataField.fieldContent.originationInvestmentId;
  } else {
    return null;
  }
});
const joinHubAction = computed(() => {
  if (
    _.includes(
      [
        "Property",
        "Company",
        "CompanyInvolvement",
        "Contact",
        "ContactCompanyInvolvement",
      ],
      props.dataField.fieldContentType,
    ) ||
    propertyId.value
  ) {
    const member = _.get(props.dataField, "hubMember", false);
    return member
      ? null
      : {
          key: "joinHub",
          name: "Join Discussion Hub",
          svgPaths: [
            "M10 1a6 6 0 0 0-3.815 10.631C7.237 12.5 8 13.443 8 14.456v.644a.75.75 0 0 0 .572.729 6.016 6.016 0 0 0 2.856 0A.75.75 0 0 0 12 15.1v-.644c0-1.013.762-1.957 1.815-2.825A6 6 0 0 0 10 1ZM8.863 17.414a.75.75 0 0 0-.226 1.483 9.066 9.066 0 0 0 2.726 0 .75.75 0 0 0-.226-1.483 7.553 7.553 0 0 1-2.274 0Z",
          ],
          clickHandler: joinHub,
        };
  } else {
    return null;
  }
});

const viewFileAction = computed(() => {
  if (props.dataField.fieldContentType === "CrowdsourcedFile") {
    const file = _.get(props.dataField, "fieldContent", null);
    return file
      ? {
          key: "viewFile",
          name: "View file",
          svgPaths: [
            "M8 10a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z",
            "M4.5 2A1.5 1.5 0 0 0 3 3.5v13A1.5 1.5 0 0 0 4.5 18h11a1.5 1.5 0 0 0 1.5-1.5V7.621a1.5 1.5 0 0 0-.44-1.06l-4.12-4.122A1.5 1.5 0 0 0 11.378 2H4.5Zm5 5a3 3 0 1 0 1.524 5.585l1.196 1.195a.75.75 0 1 0 1.06-1.06l-1.195-1.196A3 3 0 0 0 9.5 7Z",
          ],
          clickHandler: viewEmbed,
        }
      : null;
  } else {
    return null;
  }
});
const spaceUsageAction = computed(() => {
  if (spaceUsageId.value) {
    return {
      key: "viewSpaceUsage",
      name: "View space usage details",
      svgPaths: [
        "M10.362 1.093a.75.75 0 0 0-.724 0L2.523 5.018 10 9.143l7.477-4.125-7.115-3.925ZM18 6.443l-7.25 4v8.25l6.862-3.786A.75.75 0 0 0 18 14.25V6.443ZM9.25 18.693v-8.25l-7.25-4v7.807a.75.75 0 0 0 .388.657l6.862 3.786Z",
      ],
      clickHandler: viewSpaceUsage,
    };
  } else {
    return null;
  }
});
const investmentAction = computed(() => {
  if (investmentId.value) {
    return {
      key: "viewInvestment",
      name: "View investment details",
      svgPaths: [
        "M13.2 2.24a.75.75 0 00.04 1.06l2.1 1.95H6.75a.75.75 0 000 1.5h8.59l-2.1 1.95a.75.75 0 101.02 1.1l3.5-3.25a.75.75 0 000-1.1l-3.5-3.25a.75.75 0 00-1.06.04zm-6.4 8a.75.75 0 00-1.06-.04l-3.5 3.25a.75.75 0 000 1.1l3.5 3.25a.75.75 0 101.02-1.1l-2.1-1.95h8.59a.75.75 0 000-1.5H4.66l2.1-1.95a.75.75 0 00.04-1.06z",
      ],
      clickHandler: viewInvestment,
    };
  } else {
    return null;
  }
});
const clientAction = computed(() => {
  if (
    props.dataField.fieldContentType === "CompanyInvolvement" &&
    props.dataField?.fieldContent?.huntClient?.id
  ) {
    return {
      key: "viewClientCompany",
      name: "View client company",
      svgPaths: [
        "M11 2a1 1 0 1 0-2 0v6.5a.5.5 0 0 1-1 0V3a1 1 0 1 0-2 0v5.5a.5.5 0 0 1-1 0V5a1 1 0 1 0-2 0v7a7 7 0 1 0 14 0V8a1 1 0 1 0-2 0v3.5a.5.5 0 0 1-1 0V3a1 1 0 1 0-2 0v5.5a.5.5 0 0 1-1 0V2Z",
      ],
      clickHandler: viewClientCompany,
    };
  } else {
    return null;
  }
});
const investmentGroupId = computed(() => {
  if (props.dataField.decoratingContentType === "InvestmentGroup") {
    return props.dataField.decoratingContentId;
  } else if (props.dataField.fieldContentType === "InvestmentGroup") {
    return props.dataField.fieldContentId;
  } else if (props.dataField.fieldContent?.portfolioId) {
    return props.dataField.fieldContent.portfolioId;
  } else if (
    props.dataField.fieldContent?.originationInvestmentType ===
    "InvestmentGroup"
  ) {
    return props.dataField.fieldContent.originationInvestmentId;
  } else {
    return null;
  }
});
const investmentGroupAction = computed(() => {
  if (investmentGroupId.value) {
    return {
      key: "viewInvestmentGroup",
      name: "View portfolio",
      svgPaths: [
        "M4.25 2A2.25 2.25 0 0 0 2 4.25v2.5A2.25 2.25 0 0 0 4.25 9h2.5A2.25 2.25 0 0 0 9 6.75v-2.5A2.25 2.25 0 0 0 6.75 2h-2.5Zm0 9A2.25 2.25 0 0 0 2 13.25v2.5A2.25 2.25 0 0 0 4.25 18h2.5A2.25 2.25 0 0 0 9 15.75v-2.5A2.25 2.25 0 0 0 6.75 11h-2.5Zm9-9A2.25 2.25 0 0 0 11 4.25v2.5A2.25 2.25 0 0 0 13.25 9h2.5A2.25 2.25 0 0 0 18 6.75v-2.5A2.25 2.25 0 0 0 15.75 2h-2.5Zm0 9A2.25 2.25 0 0 0 11 13.25v2.5A2.25 2.25 0 0 0 13.25 18h2.5A2.25 2.25 0 0 0 18 15.75v-2.5A2.25 2.25 0 0 0 15.75 11h-2.5Z",
      ],
      clickHandler: viewInvestmentGroup,
    };
  } else {
    return null;
  }
});
const sendContactNetworkingRequestAction = computed(() => {
  if (
    _.includes(
      ["Contact", "ContactCompanyInvolvement"],
      props.dataField.fieldContentType,
    ) &&
    signedIn.value
  ) {
    const contactId = _.get(props.dataField, "fieldContent.id");
    const contactIsLinked = _.get(props.dataField, "fieldContent.linkedUserId");
    const contactConsentsToNetworking = _.get(
      props.dataField,
      "fieldContent.linkedUserNetworkingConsent",
    );
    const networkingRequestPending = _.get(
      props.dataField,
      "fieldContent.linkRequestPending",
    );
    let thirdPartyLinkedContact = true;
    let alreadyConnected = _.find(
      acceptedNetworkings.value,
      function (networking) {
        return (
          wePanelStore.networkingCounterpart(networking)?.linkedContactId ===
          _.toNumber(contactId)
        );
      },
    );

    if (linked.value) {
      thirdPartyLinkedContact =
        currentUser.value.linkedContactId !== _.toNumber(contactId);
    }
    return contactId &&
      contactIsLinked &&
      contactConsentsToNetworking &&
      !networkingRequestPending &&
      thirdPartyLinkedContact &&
      !alreadyConnected
      ? {
          key: "sendNetworkingRequest",
          name: "Send networking request",
          svgPaths: [
            "M10 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM1.615 16.428a1.224 1.224 0 0 1-.569-1.175 6.002 6.002 0 0 1 11.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 0 1 7 18a9.953 9.953 0 0 1-5.385-1.572ZM16.25 5.75a.75.75 0 0 0-1.5 0v2h-2a.75.75 0 0 0 0 1.5h2v2a.75.75 0 0 0 1.5 0v-2h2a.75.75 0 0 0 0-1.5h-2v-2Z",
          ],
          clickHandler: sendNetworkingRequest,
        }
      : null;
  } else {
    return null;
  }
});
const contextActions = computed(() => {
  switch (props.dataField.fieldContentType) {
    case "Hunt": {
      if (props.dataField.decoratingContentType === "CompanyInvolvement") {
        return [
          {
            key: "viewCompany",
            name: "View company details",
            svgPaths: [
              "M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z",
              "M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z",
            ],
            clickHandler: viewCompany,
          },
        ];
      } else {
        return [];
      }
    }
    case "Company":
    case "CompanyInvolvement": {
      return _.compact([
        joinHubAction.value,
        clientAction.value,
        {
          key: "viewCompany",
          name: "View company details",
          svgPaths: [
            "M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z",
            "M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z",
          ],
          clickHandler: viewCompany,
        },
        investmentAction.value,
        spaceUsageAction.value,
      ]);
    }
    case "Contact":
    case "ContactCompanyInvolvement": {
      return _.compact([
        joinHubAction.value,
        sendContactNetworkingRequestAction.value,
        {
          key: "viewContact",
          name: "View contact details",
          svgPaths: [
            "M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z",
          ],
          clickHandler: viewContact,
        },
        investmentAction.value,
      ]);
    }
    default:
      if (signedIn.value && (props.tasks || props.analyze)) {
        let mapZoom = null;
        let viewPropertyDiagram = null;
        let openPortfolio = null;

        if (propertyId.value) {
          mapZoom = propertyIdParam.value
            ? null
            : {
                key: "mapZoom",
                name: "Center on map",
                svgPaths: [
                  "M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z",
                ],
                clickHandler: centerOnMap,
              };
          viewPropertyDiagram = {
            key: "viewDiagram",
            name: "View property diagram",
            svgPaths: [
              "M5.127 3.502L5.25 3.5h9.5c.041 0 .082 0 .123.002A2.251 2.251 0 0012.75 2h-5.5a2.25 2.25 0 00-2.123 1.502zM1 10.25A2.25 2.25 0 013.25 8h13.5A2.25 2.25 0 0119 10.25v5.5A2.25 2.25 0 0116.75 18H3.25A2.25 2.25 0 011 15.75v-5.5zM3.25 6.5c-.04 0-.082 0-.123.002A2.25 2.25 0 015.25 5h9.5c.98 0 1.814.627 2.123 1.502a3.819 3.819 0 00-.123-.002H3.25z",
            ],
            clickHandler: viewDiagram,
          };
        }
        if (props.analyze) {
          openPortfolio = investmentGroupAction.value;
        }
        return _.compact([
          mapZoom,
          viewPropertyDiagram,
          investmentAction.value,
          openPortfolio,
          viewFileAction.value,
        ]);
      } else if (propertyId.value) {
        return _.compact([
          joinHubAction.value,
          {
            key: "viewDiagram",
            name: "View property diagram",
            svgPaths: [
              "M5.127 3.502L5.25 3.5h9.5c.041 0 .082 0 .123.002A2.251 2.251 0 0012.75 2h-5.5a2.25 2.25 0 00-2.123 1.502zM1 10.25A2.25 2.25 0 013.25 8h13.5A2.25 2.25 0 0119 10.25v5.5A2.25 2.25 0 0116.75 18H3.25A2.25 2.25 0 011 15.75v-5.5zM3.25 6.5c-.04 0-.082 0-.123.002A2.25 2.25 0 015.25 5h9.5c.98 0 1.814.627 2.123 1.502a3.819 3.819 0 00-.123-.002H3.25z",
            ],
            clickHandler: viewDiagram,
          },
          investmentAction.value,
          viewFileAction.value,
        ]);
      } else if (props.dataField.decoratingContentType === "Investment") {
        return _.compact([investmentAction.value, viewFileAction.value]);
      } else {
        return [];
      }
  }
});

onMounted(async () => {
  if (
    _.includes(
      ["Contact", "ContactCompanyInvolvement"],
      props.dataField.fieldContentType,
    ) &&
    signedIn.value &&
    !horizontalIsWe.value
  ) {
    await wePanelStore.fetchNetworkings();
  }
});

function viewEmbed() {
  const file = _.get(props.dataField, "fieldContent", null);
  let component = null;

  switch (file.hostName) {
    case "dropbox":
      component = DropboxEmbedViewer;
      break;
    case "google":
    case "microsoft":
      component = IFrameEmbedViewer;
      break;
    case "tower_hunt":
    default:
      uploadedFileStore.viewEmbed(file);
  }

  if (component) {
    modalPayload.value = {
      size: "xl",
      theme: "dark",
      component: markRaw(component),
      props: { file, document: file },
    };
  }
}

async function viewCompany() {
  let outerField = null;

  if (props.dataField.fieldContentType === "Company") {
    outerField = props.dataField;
  } else {
    const response = await api.get(
      `company_involvement_companies/${props.dataField.fieldContentId}`,
    );
    outerField = response?.data;
  }

  if (outerField?.fieldContentType === "Company") {
    router.push({
      name: "CompanyShell",
      params: { companyId: outerField.fieldContentId },
      query: {
        ...route.query,
        propertyIds: undefined,
      },
    });
  }

  if (
    props.dataField.decoratingContentType === "CompanyInvolvement" &&
    props.dataField.fieldContent?.companyId
  ) {
    router.push({
      name: "CompanyShell",
      params: { companyId: props.dataField.fieldContent.companyId },
      query: {
        ...route.query,
        propertyIds: undefined,
      },
    });
  }
}
async function viewClientCompany() {
  if (props.dataField?.fieldContent?.huntClient?.id) {
    router.push({
      name: "CompanyShell",
      params: { companyId: props.dataField?.fieldContent?.huntClient?.id },
      query: {
        ...route.query,
        propertyIds: undefined,
      },
    });
  }
}
async function viewContact() {
  const response = await api.get(
    `contact_involvement_contacts/${props.dataField.fieldContentId}`,
  );
  const outerField = response?.data;

  if (outerField?.fieldContentType === "Contact") {
    router.push({
      name: "ContactShell",
      params: { contactId: outerField.fieldContentId },
      query: {
        ...route.query,
        propertyIds: undefined,
      },
    });
  }
}
async function joinHub() {
  let contentType = null;
  let contentId = null;
  if (
    _.includes(
      ["Property", "Company", "Contact"],
      props.dataField.fieldContentType,
    )
  ) {
    contentType = props.dataField.fieldContentType;
    contentId = props.dataField.fieldContentId;
  } else if (
    props.dataField.fieldContentType === "CompanyInvolvement" &&
    props.dataField?.fieldContent?.companyId
  ) {
    contentType = "Company";
    contentId = props.dataField?.fieldContent?.companyId;
  } else if (
    props.dataField.fieldContentType === "ContactCompanyInvolvement" &&
    props.dataField?.fieldContent?.id
  ) {
    contentType = "Contact";
    contentId = props.dataField?.fieldContent?.id;
  } else if (propertyId.value) {
    contentType = "Property";
    contentId = propertyId.value;
  }

  if (contentType && contentId) {
    wePanelStore.joinHub({
      contentType,
      contentId,
    });
  }
}
async function sendNetworkingRequest() {
  const contactId = _.get(props.dataField, "fieldContent.id");
  await userStore.contactNetworkingRequest(contactId);
}
async function fetchTopLevelContent(isVisible) {
  if (isVisible) {
    if (embeddedPropertyId.value) {
      propertyId.value = embeddedPropertyId.value;
      setDisplayable();
    } else {
      const topLevelField =
        await topLevelContentFieldsStore.fetchTopLevelContentDataField(
          props.dataField.outerDataFieldId,
          props.dataField.localId,
        );

      if (topLevelField) {
        if (topLevelField.fieldContentType === "Property") {
          propertyId.value = topLevelField.fieldContentId;
        } else if (
          topLevelField.fieldContent?.propertyId ||
          topLevelField.fieldContent?.topLevelPropertyId
        ) {
          propertyId.value =
            topLevelField.fieldContent?.propertyId ||
            topLevelField.fieldContent?.topLevelPropertyId;
        }
        setDisplayable();
      }
    }
  }
}
function setDisplayable() {
  displayable.value = contextActions.value.length > 0;
}
function viewDiagram() {
  propertyDiagramStore.navigateToDiagram(propertyId.value, true);
}
function viewInvestment() {
  api
    .get(`investment_deal_builder_assets/${investmentId.value}`)
    .then((json) => {
      const { assetDataField, propertyDataField } = json.data;

      dealStore.patchPropertyDataFields(propertyDataField);
      dealStore.upsertDealBuilder({
        assetDataField,
        timelineInvestmentId: investmentId.value,
        timeTravelDate: props.dataField?.date,
      });
    });
}
function viewSpaceUsage() {
  api.get(`space_usage_spaces/${spaceUsageId.value}`).then((json) => {
    const availabilityDataField = json.data;

    if (availabilityDataField.fieldContent?.topLevelPropertyId) {
      propertyFieldsStore.fetchPropertyDataField(
        availabilityDataField.fieldContent?.topLevelPropertyId,
      );
      spaceUsageBuilderStore.upsertSpaceUsageBuilder({
        spaceDataField: availabilityDataField.fieldContent?.space,
        timelineAvailabilityId: availabilityDataField.fieldContentId,
        timeTravelDate: availabilityDataField.fieldContent?.date,
      });
    }
  });
}
function viewInvestmentGroup() {
  dealStore.upsertDealBuilder({
    existingGroupId: investmentGroupId.value,
  });
}
function centerOnMap() {
  api
    .get(
      `crowdsourced_data_fields/Property/${propertyId.value}?field_name=ContentLocation`,
    )
    .then((json) => {
      const location = json.data.fieldContent;

      newOriginOverride.value = true;
      temporaryMapCenter.value = {
        lat: _.get(location, "lat"),
        lng: _.get(location, "lng"),
        zoom: 16.5,
      };
    });
}
</script>
