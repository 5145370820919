import TaskShell from "@/components/tasks/TaskShell.vue";
import StackOfCards from "@/components/collectible-cards/StackOfCards.vue";
import MySafezone from "@/components/crowdsourcing/safezone/MySafezone.vue";
import MyContributions from "@/components/crowdsourcing/contributions/MyContributions.vue";
import AccountSettings from "@/components/users/AccountSettings.vue";
import { markRaw } from "vue";
import _ from "lodash";

export function initialSelectedTab() {
  return _.get(tabs, "news");
}

export const tabs = {
  news: {
    name: "News",
    tabQuery: "News",
    href: "",
    svgPaths: [
      "M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z",
    ],
    component: markRaw(StackOfCards),
  },
  tasks: {
    name: "Tasks",
    tabQuery: "Tasks",
    href: "",
    svgPaths: [
      "M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z",
    ],
    component: markRaw(TaskShell),
  },
  contributions: {
    name: "Contributions",
    tabQuery: "Contributions",
    href: "",
    svgPaths: [
      "M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
    ],
    component: markRaw(MyContributions),
  },
  safezone: {
    name: "Safezone",
    tabQuery: "Safezone",
    href: "",
    svgPaths: [
      "M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z",
    ],
    component: markRaw(MySafezone),
  },
  account: {
    name: "Account Settings",
    tabQuery: "Settings",
    href: "",
    svgPaths: [
      "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z",
      "M15 12a3 3 0 11-6 0 3 3 0 016 0z",
    ],
    component: markRaw(AccountSettings),
  },
};
