<template>
  <div class="flex items-center justify-between gap-x-4">
    <span class="flex grow flex-col space-y-1">
      <div class="flex items-center space-x-2">
        <span class="text-sm/6 font-medium text-gray-900">{{ label }}</span>
      </div>
      <span class="text-sm text-gray-500">{{ description }}</span>
    </span>

    <span class="isolate inline-flex rounded-md shadow-sm">
      <button
        v-tooltip="'Deny'"
        type="button"
        class="relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset focus:z-10"
        :class="[
          internalModelValue === 'deny'
            ? 'bg-red-500 text-white ring-red-300'
            : 'bg-white text-red-500 ring-gray-300 hover:bg-gray-50',
        ]"
        @click="internalModelValue = 'deny'"
      >
        <NoSymbolIcon class="size-5" />
      </button>
      <button
        v-tooltip="'Inherit from @everyone'"
        type="button"
        class="relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset focus:z-10"
        :class="[
          internalModelValue === null
            ? 'bg-gray-500 text-white ring-gray-300'
            : 'bg-white text-gray-900 ring-gray-300 hover:bg-gray-50',
        ]"
        @click="internalModelValue = null"
      >
        <ArrowUpIcon class="size-5" />
      </button>
      <button
        v-tooltip="'Allow'"
        type="button"
        class="relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset focus:z-10"
        :class="[
          internalModelValue === 'allow'
            ? 'bg-green-500 text-white ring-green-300'
            : 'bg-white text-green-500 ring-gray-300 hover:bg-gray-50',
        ]"
        @click="internalModelValue = 'allow'"
      >
        <CheckIcon class="size-5" />
      </button>
    </span>
  </div>
</template>

<script setup>
import {
  ArrowUpIcon,
  CheckIcon,
  NoSymbolIcon,
} from "@heroicons/vue/24/outline";
import { ref, watch, watchEffect } from "vue";

const props = defineProps([
  "modelValue",
  "label",
  "description",
  "privateIcon",
]);
const emits = defineEmits(["update:modelValue"]);

const internalModelValue = ref(props.modelValue);

watchEffect(() => {
  emits("update:modelValue", internalModelValue.value);
});

watch(
  () => props.modelValue,
  (newValue) => {
    internalModelValue.value = newValue;
  },
);
</script>
