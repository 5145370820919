<template>
  <HeaderContainer :context="context">
    <ExpandedChannelHeader v-if="expandedView" :context="context" />
    <CompactChannelHeader v-else :context="context" />
  </HeaderContainer>
</template>

<script setup>
import HeaderContainer from "@/components/we/main-content/HeaderContainer.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import ExpandedChannelHeader from "@/components/we/main-content/ExpandedChannelHeader.vue";
import CompactChannelHeader from "@/components/we/main-content/CompactChannelHeader.vue";

const props = defineProps(["context"]);

const wePanelStore = useWePanelStore();
const { expandedView, mainContent } = storeToRefs(wePanelStore);
</script>
