<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-blue-600 to-sky-400 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative px-4 py-16 flex flex-col justify-center">
      <h1 class="text-center text-4xl font-bold tracking-tight">
        <span class="block text-white">Networking</span>
        <span class="block text-sky-200">Requests</span>
      </h1>
      <p class="mx-auto mt-6 max-w-lg text-center text-lg text-sky-200">
        {{ pluralize("new request", _.size(networkingRequests), true) }}
      </p>
      <div class="mx-auto mt-10 max-w-sm flex justify-center">
        <div class="space-y-4">
          <a
            href=""
            @click.prevent="viewWe"
            class="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-sky-700 shadow-sm hover:bg-sky-50 sm:px-8"
            >View Network</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import { useWeChannelStore } from "@/stores/weChannel";
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import pluralize from "pluralize";
import _ from "lodash";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
]);

const weChannelStore = useWeChannelStore();
const { weChannelDataQueue } = storeToRefs(weChannelStore);

const networkingRequests = ref(0);

watch(
  () => props.stackIndex,
  () => {
    if (networkingRequests.value === 0) fetchNetworkingRequests();
  },
);
watch(weChannelDataQueue, () => {
  longDebouncedFetch();
});

onMounted(() => {
  fetchNetworkingRequests();
});

const longDebouncedFetch = _.debounce(fetchNetworkingRequests, 5000);

async function fetchNetworkingRequests() {
  return new Promise((resolve) => {
    if (
      props.stackIndex === props.cardIndex &&
      !_.includes(props.completedCardIds, props.card.id)
    ) {
      api.get(`pending_networking_requests`).then((json) => {
        networkingRequests.value = json.data.received;
      });
    } else {
      resolve();
    }
  });
}

const router = useRouter();
const route = useRoute();

function viewWe() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "We",
    },
  });
}
</script>
