<template>
  <form class="p-4 h-full flex flex-col" @submit.prevent>
    <div class="px-1 pb-4 flex-grow overflow-y-auto">
      <h2 class="text-base/6 font-semibold text-gray-900">
        Add members<template v-if="context !== 'roleMembersList'"
          >&nbsp;or roles</template
        >
      </h2>
      <div class="flex items-center space-x-2 text-xs/5 text-gray-600">
        <TopicTypeIcon
          v-if="context === 'DiscussionTopic'"
          :topic="{
            topicType: selectedTopicType.name,
            isPrivate: true,
          }"
          lock-size="size-2.5"
          icon-size="size-4"
        />
        <TagIcon
          v-else-if="context === 'roleMembersList'"
          class="size-4 flex-none"
          :class="[weHubSettingsStore.colorFor(editableRole)]"
        />
        <span>{{ displayName }}</span>
      </div>

      <div class="mt-1 flex flex-col gap-y-4">
        <div class="">
          <SearchableInput
            v-model="searchQuery"
            :selected-items="[...actionableRoles, ...actionableMembers]"
            :chip-component="AutocompleteNameChip"
            :placeholder="
              context !== 'roleMembersList'
                ? 'e.g., Owners, @amy'
                : 'e.g., @amy'
            "
            class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
            @remove-item="removeItem"
          />
          <p
            id="member-role-search-description"
            class="mt-2 text-xs text-gray-500"
          >
            Add individual members by starting @<template
              v-if="context !== 'roleMembersList'"
              >, or type a role name</template
            >.
          </p>
        </div>

        <div v-if="context !== 'roleMembersList'">
          <fieldset>
            <legend class="text-base font-semibold text-gray-900">Roles</legend>
            <div class="mt-3">
              <div
                v-for="(role, roleIdx) in roleOptions"
                :key="roleIdx"
                class="relative flex gap-3 py-2"
              >
                <div class="flex h-6 shrink-0 items-center">
                  <div class="group grid size-4 grid-cols-1">
                    <input
                      :id="`role-${role.id}`"
                      v-model="role.selected"
                      :name="`role-${role.id}`"
                      type="checkbox"
                      :checked="role.selected"
                      class="col-start-1 row-start-1 appearance-none rounded border border-gray-400 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                    />
                    <svg
                      class="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        class="opacity-0 group-has-[:checked]:opacity-100"
                        d="M3 8L6 11L11 3.5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        class="opacity-0 group-has-[:indeterminate]:opacity-100"
                        d="M3 7H11"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  class="min-w-0 flex-1 flex items-center space-x-2 text-sm/6"
                >
                  <TagIcon
                    class="size-6 flex-none"
                    :class="[weHubSettingsStore.colorFor(role)]"
                  />
                  <label
                    :for="`role-${role.id}`"
                    class="select-none font-medium text-gray-600"
                    >{{ role.name }}</label
                  >
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <div>
          <fieldset>
            <legend class="text-base font-semibold text-gray-900">
              Members
            </legend>
            <div class="mt-3">
              <div
                v-for="(person, personIdx) in people"
                :key="personIdx"
                class="relative flex items-center gap-3"
              >
                <div class="flex h-6 shrink-0 items-center">
                  <div class="group grid size-4 grid-cols-1">
                    <input
                      :id="`person-${person.id}`"
                      v-model="person.selected"
                      :name="`person-${person.id}`"
                      type="checkbox"
                      :checked="person.selected"
                      class="col-start-1 row-start-1 appearance-none rounded border border-gray-400 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                    />
                    <svg
                      class="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        class="opacity-0 group-has-[:checked]:opacity-100"
                        d="M3 8L6 11L11 3.5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        class="opacity-0 group-has-[:indeterminate]:opacity-100"
                        d="M3 7H11"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div class="min-w-0 flex-1 text-sm/6">
                  <CompactChannelMemberListElement
                    :membership="person"
                    context="addMembersForm"
                    @select="person.selected = !person.selected"
                  />
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>

    <div class="pt-4 flex items-center justify-end gap-x-6">
      <button
        type="button"
        class="text-sm/6 font-semibold text-gray-900"
        @click="back"
      >
        Back
      </button>
      <button
        type="button"
        :disabled="!actionableCanSave"
        class="rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        :class="[
          actionableCanSave
            ? 'bg-indigo-600 text-white hover:bg-indigo-500'
            : 'bg-indigo-400 text-gray-100 cursor-not-allowed',
        ]"
        @click="save"
      >
        {{ submitLabel }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { TagIcon } from "@heroicons/vue/24/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useWeHubSettingsStore } from "@/stores/weHubSettings";
import { useWeTopicSettingsStore } from "@/stores/weTopicSettings";
import { useModalStore } from "@/stores/modal";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import { useWeDiscussionTopicFormStore } from "@/stores/weDiscussionTopicForm";
import { useWeDiscussionCategoryFormStore } from "@/stores/weDiscussionCategoryForm";
import SearchableInput from "@/components/we/SearchableInput.vue";
import AutocompleteNameChip from "@/components/we/AutocompleteNameChip.vue";
import CompactChannelMemberListElement from "@/components/we/channels-format/CompactChannelMemberListElement.vue";
import DiscussionTopicForm from "@/components/we/channels-format/DiscussionTopicForm.vue";
import DiscussionCategoryForm from "@/components/we/channels-format/DiscussionCategoryForm.vue";
import TopicTypeIcon from "@/components/we/channels-format/TopicTypeIcon.vue";
import _ from "lodash";

const props = defineProps(["context"]);

const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);
const modalStore = useModalStore();
const wePanelStore = useWePanelStore();
const {
  expandedView,
  activeHubId,
  mainContent,
  hubMembers,
  rightEdgeLevelThreeComponent,
} = storeToRefs(wePanelStore);
const weHubSettingsStore = useWeHubSettingsStore();
const {
  editableRole,
  sortableRoles,
  selectedTabLayeredComponents: hubLayeredComponents,
  membersToAdd: hubMembersToAdd,
} = storeToRefs(weHubSettingsStore);
const weTopicSettingsStore = useWeTopicSettingsStore();
const {
  selectedTabLayeredComponents: topicLayeredComponents,
  rolesToAdd: existingTopicRoles,
  membersToAdd: existingTopicMembers,
} = storeToRefs(weTopicSettingsStore);
const weDiscussionTopicFormStore = useWeDiscussionTopicFormStore();
const {
  parentCategory,
  topicName,
  selectedTopicType,
  roles: topicRoles,
  members: topicPermissionOverwrites,
  canSave: topicCanSave,
} = storeToRefs(weDiscussionTopicFormStore);
const weDiscussionCategoryFormStore = useWeDiscussionCategoryFormStore();
const {
  categoryName,
  roles: categoryRoles,
  members: categoryMembers,
  canSave: categoryCanSave,
} = storeToRefs(weDiscussionCategoryFormStore);

const searchQuery = ref("");
const activeHubMembers = computed(() =>
  hubMembers.value.filter(
    (member) => member.hubId && member.hubId === activeHubId.value,
  ),
);

const displayName = computed(() => {
  switch (props.context) {
    case "topicPermissionOverwrites":
      return mainContent.value.content.name;
    case "roleMembersList":
      return editableRole.value.name;
    case "DiscussionTopic":
      return topicName.value;
    case "DiscussionCategory":
      return categoryName.value;
    default:
      return null;
  }
});
const actionableCanSave = computed(() => {
  switch (props.context) {
    case "topicPermissionOverwrites":
      return existingTopicMembers.value.length > 0 || existingTopicRoles.value.length > 0;
    case "roleMembersList":
      return hubMembersToAdd.value.length > 0;
    case "DiscussionTopic":
      return topicCanSave.value;
    case "DiscussionCategory":
      return categoryCanSave.value;
    default:
      return false;
  }
});
const actionableRoles = computed(() => {
  switch (props.context) {
    case "topicPermissionOverwrites":
      return existingTopicRoles.value;
    case "roleMembersList":
      return [];
    case "DiscussionTopic":
      return topicRoles.value;
    case "DiscussionCategory":
      return categoryRoles.value;
    default:
      return [];
  }
});
const actionableMembers = computed(() => {
  switch (props.context) {
    case "topicPermissionOverwrites":
      return existingTopicMembers.value;
    case "roleMembersList":
      return hubMembersToAdd.value;
    case "DiscussionTopic":
      return topicPermissionOverwrites.value;
    case "DiscussionCategory":
      return categoryMembers.value;
    default:
      return [];
  }
});
const submitLabel = computed(() => {
  const empty =
    actionableRoles.value.length === 0 && actionableMembers.value.length === 0;

  if (
    _.includes(["roleMembersList", "topicPermissionOverwrites"], props.context)
  ) {
    return "Done";
  } else if (empty) {
    return "Skip";
  } else {
    return "Create Topic";
  }
});
const actionableStore = computed(() => {
  switch (props.context) {
    case "DiscussionTopic":
      return weDiscussionTopicFormStore;
    case "DiscussionCategory":
      return weDiscussionCategoryFormStore;
    default:
      return null;
  }
});

const roleOptions = ref(
  _.cloneDeep(sortableRoles.value).map((role) => ({
    ...role,
    selected: _.includes(
      actionableRoles.value.map(({ id }) => id),
      role.id,
    ),
  })),
);
const people = ref(
  _.cloneDeep(activeHubMembers.value)
    .filter(({ userId, hubOwner }) => userId !== currentUser.value.id || !hubOwner)
    .map((member) => ({
      ...member,
      id: member.memberId,
      name: member.linkedContactDataField?.fieldContent?.name || member.name,
      selected: _.includes(
        actionableMembers.value.map(({ memberId }) => memberId),
        member.memberId,
      ),
    })),
);

watch(
  roleOptions,
  () => {
    updateRoles();
  },
  { deep: true },
);

watch(
  people,
  () => {
    updateMembers();
  },
  { deep: true },
);

onMounted(() => {
  wePanelStore.fetchHubMembers();
});

const backComponent = computed(() => {
  switch (props.context) {
    case "DiscussionTopic":
      return {
        component: DiscussionTopicForm,
        props: {
          category: parentCategory.value,
        },
      };
    case "DiscussionCategory":
      return {
        component: DiscussionCategoryForm,
        props: {},
      };
    default:
      return null;
  }
});

function updateRoles() {
  switch (props.context) {
    case "topicPermissionOverwrites":
      existingTopicRoles.value = roleOptions.value.filter(
        ({ selected }) => selected,
      );
      break;
    case "DiscussionTopic":
      topicRoles.value = roleOptions.value.filter(({ selected }) => selected);
      break;
    case "DiscussionCategory":
      categoryRoles.value = roleOptions.value.filter(
        ({ selected }) => selected,
      );
      break;
    default:
      break;
  }
}

function updateMembers() {
  switch (props.context) {
    case "topicPermissionOverwrites":
      existingTopicMembers.value = people.value.filter(
        ({ selected }) => selected,
      );
      break;
    case "roleMembersList":
      hubMembersToAdd.value = people.value.filter(({ selected }) => selected);
      break;
    case "DiscussionTopic":
      topicPermissionOverwrites.value = people.value.filter(
        ({ selected }) => selected,
      );
      break;
    case "DiscussionCategory":
      categoryMembers.value = people.value.filter(({ selected }) => selected);
      break;
    default:
      break;
  }
}

function removeItem(item) {
  if (item.memberId) {
    // membership
    const toDeselect = _.find(people.value, { id: item.memberId });

    if (toDeselect) {
      toDeselect.selected = false;
    }

    switch (props.context) {
      case "topicPermissionOverwrites":
        existingTopicMembers.value = existingTopicMembers.value.filter(
          ({ memberId }) => memberId !== item.memberId,
        );
        break;
      case "roleMembersList":
        hubMembersToAdd.value = hubMembersToAdd.value.filter(
          ({ memberId }) => memberId !== item.memberId,
        );
        break;
      case "DiscussionTopic":
        topicPermissionOverwrites.value =
          topicPermissionOverwrites.value.filter(
            ({ memberId }) => memberId !== item.memberId,
          );
        break;
      case "DiscussionCategory":
        categoryMembers.value = categoryMembers.value.filter(
          ({ memberId }) => memberId !== item.memberId,
        );
        break;
      default:
        break;
    }
  } else {
    // role
    const toDeselect = _.find(roleOptions.value, { id: item.id });

    if (toDeselect) {
      toDeselect.selected = false;
    }

    switch (props.context) {
      case "topicPermissionOverwrites":
        existingTopicRoles.value = existingTopicRoles.value.filter(
          ({ memberId }) => memberId !== item.memberId,
        );
        break;
      case "DiscussionTopic":
        topicRoles.value = topicRoles.value.filter(({ id }) => id !== item.id);
        break;
      case "DiscussionCategory":
        categoryRoles.value = categoryRoles.value.filter(
          ({ id }) => id !== item.id,
        );
        break;
      default:
        break;
    }
  }
}

async function save() {
  if (props.context === "topicPermissionOverwrites") {
    await weTopicSettingsStore.addPermissionOverwrites();
    hubRoleBack();
  } else if (props.context === "roleMembersList") {
    await weHubSettingsStore.addRoleMembers();
    hubRoleBack();
  } else {
    actionableStore.value.save();
  }
}

function back() {
  if (backComponent.value) {
    wePanelStore.openSecondaryContentPanel(backComponent.value);
  } else {
    hubRoleBack();
  }
}

function hubRoleBack() {
  if (expandedView.value) {
    existingTopicRoles.value = [];
    existingTopicMembers.value = [];
    hubMembersToAdd.value = [];
    modalStore.closeModal();
  } else if (topicLayeredComponents.value.length > 0) {
    existingTopicRoles.value = [];
    existingTopicMembers.value = [];

    weTopicSettingsStore.backLayeredComponent();

    const raw = _.last(topicLayeredComponents.value);

    rightEdgeLevelThreeComponent.value = raw;
  } else if (hubLayeredComponents.value.length > 0) {
    hubMembersToAdd.value = [];
    weHubSettingsStore.backLayeredComponent();

    const raw = _.last(hubLayeredComponents.value);

    rightEdgeLevelThreeComponent.value = raw;
  } else {
    wePanelStore.closeRightEdgeLevelThreePanel();
  }
}
</script>
