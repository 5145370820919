<template>
  <div>
    <button
      v-if="interceptField || interceptCalculation"
      @click="promptToSubscribe"
      :class="`px-1 py-0.5 rounded-md inline-flex items-center space-x-1 text-left ${borderClasses} ${
        textClasses || 'text-sm font-medium'
      } text-gray-900 bg-gray-200 underline decoration-2 decoration-dashed decoration-gray-700 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150`"
      :style="`${textStyles}`"
      type="button"
    >
      <LockClosedIcon class="h-3 w-3" />
      <span>Subscribe to view</span>
    </button>

    <VDropdown
      v-else-if="dataField || props.calculationName"
      :placement="dropdownPlacement || 'auto-start'"
      :disabled="!isDesktop || noDropdown"
    >
      <button
        :class="[
          `relative px-1 py-0.5 rounded-md inline-flex items-center space-x-1 text-left ${borderClasses} ${
            textClasses || 'text-sm font-medium'
          } ${dynamicClass} underline decoration-2 ${dynamicUnderlineClass}`,
          noDropdown
            ? ''
            : 'transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150',
        ]"
        :style="`${textStyles}`"
        :data-test="`${fieldName}-data-field`"
        type="button"
      >
        <AvatarPresenceIndicator
          v-if="_.includes(['Contact', 'ContactCompanyInvolvement'], field?.fieldContentType)"
          :person="field?.fieldContent"
        />
        <span
          v-if="field?.taskPriority"
          :class="`${taskLabelColor}`"
          :data-test="`${fieldName}-data-field-task-priority-label`"
          >{{ taskLabel }}</span
        >
        <CalculatorIcon v-if="calculationName" class="flex-shrink-0 h-4 w-4" />
        <LockClosedIcon v-else-if="unlockable" class="flex-shrink-0 h-3 w-3" />
        <HandThumbDownIcon v-else-if="rejected" class="flex-shrink-0 h-3 w-3" />
        <ScaleIcon
          v-else-if="reputable && inValidation"
          class="flex-shrink-0 h-4 w-4"
        />
        <ShieldExclamationIcon
          v-else-if="reputable && unpublished"
          class="flex-shrink-0 h-4 w-4"
        />
        <EyeSlashIcon v-else-if="safezone" class="flex-shrink-0 h-3 w-3" />
        <EyeIcon v-else-if="!safezone" class="flex-shrink-0 h-3 w-3" />
        <div
          v-if="imageableField"
          class="aspect-square shrink-0"
          :class="imageDimensions"
        >
          <HeroImage :override-data-field="imageableField" :no-add="true" />
        </div>
        <PulseLoader
          v-if="fetchingData"
          :loading="true"
          size="3px"
          :color="textColor"
        />
        <span
          v-else-if="isText"
          v-html="primaryText"
          class="whitespace-pre-line dont-break-out"
        />
        <span
          :class="textWrap ? 'whitespace-pre-line dont-break-out' : ''"
          v-else
          >{{ primaryText }}</span
        >
      </button>

      <template #popper>
        <div class="flex flex-col">
          <DataFieldActionMenu
            :data-field="field"
            :timing-fields="timingFields"
            :fetched-all-fields-data="fetchedAllFieldsData"
            :fully-licensed="fullyLicensed"
            :calculation-name="calculationName"
            :calculation-option-data="calculationOptionData"
            :bundle-field-ids="bundleFieldIds"
            :calculation-content-type="calculationContentType"
            :calculation-content-ids="calculationContentIds"
            :calculation-is-survey="calculationIsSurvey"
            :admin-view="adminView"
            :always-private="alwaysPrivate"
            :safezone-tab="safezoneTab"
            :tasks="tasks"
            :analyze="analyze"
            :sharing-stage="sharingStage"
            :citations-stage="citationsStage"
            :is-inner-field="isInnerField"
            :user-store="userStore"
            :modal-store="modalStore"
            :unlocker-store="unlockerStore"
            :tasks-store="tasksStore"
            :task-list-store="taskListStore"
            :reminder-store="reminderStore"
            :guest-profile-store="guestProfileStore"
            :property-diagram-store="propertyDiagramStore"
            :change-group-store="changeGroupStore"
            :layout-store="layoutStore"
            :documentation-store="documentationStore"
            :parent-component-save-function="parentComponentSaveFunction"
            :skip-post-editing-patch="skipPostEditingPatch"
            @unlocked="unlocked"
            @open-sourced="openSourced"
            @admin-open-sourced="adminOpenSourced"
            @completed="completed"
            @suggest-change="suggestChange"
            @dismiss="dismiss"
            @set-proof="setProof"
            class="w-72 divide-y divide-gray-200 focus:outline-none"
          />
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script setup>
import { computed, onMounted, onBeforeUnmount, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useModalStore } from "@/stores/modal";
import { useUserStore } from "@/stores/user";
import { useUnlockerStore } from "@/stores/unlocker";
import { useDataFieldLoadingStore } from "@/stores/dataFieldLoadingManagement";
import HeroImage from "@/components/property-diagram/HeroImage.vue";
import AvatarPresenceIndicator from "@/components/users/AvatarPresenceIndicator.vue";
import selfLabel from "@/components/crowdsourcing/selfLabelDataField";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import stateAbbreviationsUS from "@/assets/stateAbbreviationsUS";
import moment from "moment";
import _ from "lodash";
import "leaflet-draw/dist/leaflet.draw";
import api from "@/router/api";
import DataFieldActionMenu from "./DataFieldActionMenu.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import {
  EyeSlashIcon,
  EyeIcon,
  LockClosedIcon,
  HandThumbDownIcon,
  CalculatorIcon,
  ShieldExclamationIcon,
  ScaleIcon,
} from "@heroicons/vue/20/solid";
/* global L */

const props = defineProps([
  "dataField",
  "timingFields",
  "propertyDataField",
  "interceptCalculation",
  "fullyLicensed",
  "fetchingPropData",
  "fetchedAllFieldsData",
  "calculationName",
  "calculationValue",
  "calculationOptionData",
  "bundleFieldIds",
  "calculationContentType",
  "calculationContentIds",
  "calculationIsSurvey",
  "userStore",
  "modalStore",
  "unlockerStore",
  "tasksStore",
  "taskListStore",
  "reminderStore",
  "guestProfileStore",
  "propertyDiagramStore",
  "changeGroupStore",
  "documentationStore",
  "layoutStore",
  "standaloneFieldName",
  "dropdownRangeValue",
  "innerFieldName",
  "textClasses",
  "textStyles",
  "textWrap",
  "selfLabelOutputFormat",
  "primaryTextPropOverride",
  "primaryTextPath",
  "secondaryTextPath",
  "dropdownPlacement",
  "dismissOnSave",
  "safezoneTab",
  "sharingStage",
  "citationsStage",
  "tasks",
  "analyze",
  "alwaysPrivate",
  "adminView",
  "companyDetail",
  "contactDetail",
  "parentComponentSaveFunction",
  "skipPostEditingPatch",
  "noImage",
  "noDropdown",
]);
const { isDesktop } = storeToRefs(
  props.layoutStore || useWorkspaceLayoutStore(),
);
const { resetRequired, upgradeSuccessful } = storeToRefs(
  props.unlockerStore || useUnlockerStore(),
);
const upgradeInProgress = ref(false);
const { modalPayload } = storeToRefs(props.modalStore || useModalStore());
const userStore = props.userStore || useUserStore();
const { reputable } = storeToRefs(userStore);
const companyDetailStore = useCompanyDetailStore();
const contactDetailStore = useContactDetailStore();
const analyzePanelStore = useAnalyzePanelStore();
const {
  sizesSurveyFetched,
  investmentVolumeSurveyFetched,
  fetchingAnalyzePanelData,
} = storeToRefs(analyzePanelStore);
const interceptField = computed(
  () => _.get(field.value, "meteringStatus") === "prompt_to_subscribe",
);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { fetchingSpaceUsageBuilderData } = storeToRefs(spaceUsageBuilderStore);
const dealBuilderStore = useDealBuilderStore();
const { patchableCompanySize, allFetchedFields, fetchingDealBuilderData } =
  storeToRefs(dealBuilderStore);

const dataFieldLoadingStore = useDataFieldLoadingStore();
const innerField = ref(null);
const innerFieldLastFetchedTime = ref(null);
const imageable = computed(() => {
  if (props.dataField?.unmasked && !props.noImage) {
    const matchingDirectField = _.includes(
      ["Company", "Contact", "CompanyInvolvement", "ContactCompanyInvolvement"],
      props.dataField?.fieldContentType,
    );
    const matchingInnerField =
      innerField.value?.fieldContentType === "CompanyInvolvement";

    return matchingDirectField || matchingInnerField;
  } else {
    return false;
  }
});
const imageableField = computed(() => {
  if (imageable.value) {
    if (innerField.value?.fieldContentType === "CompanyInvolvement") {
      return innerField.value;
    } else {
      return props.dataField;
    }
  } else {
    return null;
  }
});
const imageDimensions = computed(() => {
  if (imageable.value) {
    return props.dataField.fieldContentType === "Company" ||
      innerField.value?.fieldContentType === "CompanyInvolvement"
      ? "w-[50px] h-[30px]"
      : "w-[50px] h-[50px]";
  } else {
    return "";
  }
});
const taskLabel = computed(() => {
  switch (field.value?.taskPriority) {
    case "high":
      return "!!!";
    case "medium":
      return "!!";
    case "low":
      return "!";
    default:
      return "";
  }
});
const taskLabelColor = computed(() => {
  switch (field.value?.taskPriority) {
    case "high":
      return "text-red-500";
    case "medium":
      return "text-amber-500";
    case "low":
      return "text-green-500";
    default:
      return "text-gray-300";
  }
});
const borderClasses = "border-2 border-white";
const fetchingData = computed(() => {
  if (props.calculationName) {
    return (
      fetchingAnalyzePanelData.value ||
      fetchingDealBuilderData.value ||
      fetchingSpaceUsageBuilderData.value
    );
  } else {
    return (
      dataFieldLoadingStore.isLoading(props.dataField?.localId) ||
      props.fetchingPropData
    );
  }
});
const isInnerField = computed(() => {
  return !!(props.innerFieldName && innerField.value);
});
const safezone = computed(
  () => !props.calculationName && _.get(field.value, "safezone"),
);
const field = computed(() => {
  return isInnerField.value ? innerField.value : props.dataField;
});
const fieldName = computed(() => {
  if (props.calculationName) {
    return props.calculationName;
  } else if (_.get(field.value, "fieldName")) {
    return `${field.value.decoratingContentType}-${field.value.decoratingContentId}-${field.value.fieldName}`;
  } else {
    return `${field.value.decoratingContentType}-${field.value.decoratingContentId}-${field.value.fieldContentType}-${field.value.fieldContentId}`;
  }
});
const isText = computed(() => {
  return (
    _.get(field.value, "fieldValueType") === "Text" ||
    _.get(field.value, "fieldContentType") === "ContentNote"
  );
});
const unlockable = computed(() => {
  const rawPrice = _.get(field.value, "price");
  const price = rawPrice ? _.toNumber(rawPrice) : null;

  return (
    (unstaked.value && !safezone.value) ||
    (price && price > 0) ||
    unlicensedSpecificFieldContent.value
  );
});
const unlicensedSpecificFieldContent = computed(() => {
  if (_.includes(["Investment"], field.value?.fieldContentType)) {
    return field.value?.fieldContent?.state?.meteringStatus === "unlicensed";
  } else {
    return false;
  }
});
const rejected = computed(() => _.get(field.value, "state") === "rejected");
const unstaked = computed(() => _.get(field.value, "state") === "unstaked");
const unpublished = computed(() => _.get(field.value, "state") === "staked");
const inValidation = computed(() => _.get(field.value, "state") === "proposed");
const fieldValue = computed(() => {
  return _.get(field.value, "fieldValue", null);
});
const primaryTextOverride = computed(() => {
  if (props.primaryTextPropOverride) {
    return props.primaryTextPropOverride;
  } else {
    switch (props.standaloneFieldName) {
      case "date": {
        if (fieldValue.value) {
          return moment(fieldValue.value).format("MMM D, YYYY");
        } else {
          return null;
        }
      }
      default: {
        switch (_.get(field.value, "fieldContentType")) {
          case "CompanyInvolvement": {
            if (
              _.includes(
                ["Hunt", "HuntGeographyIntent"],
                field.value.decoratingContentType,
              )
            ) {
              return null;
            } else if (
              props.companyDetail &&
              companyDetailStore.propertyNameFor(field.value)
            ) {
              return `Involvement at ${companyDetailStore.propertyNameFor(
                field.value,
              )}`;
            } else if (
              props.contactDetail &&
              contactDetailStore.propertyNameFor(field.value)
            ) {
              return `Involvement at ${contactDetailStore.propertyNameFor(
                field.value,
              )}`;
            } else {
              return null;
            }
          }
          case "ContentLocation": {
            const state = _.get(field.value, "fieldContent.state");
            const abbreviatedState = stateAbbreviationsUS[state];

            return `${_.get(field.value, "fieldContent.city")}, ${
              abbreviatedState || state
            }`;
          }
          default:
            return null;
        }
      }
    }
  }
});

const calculationOutput = computed(() => {
  if (!_.isNil(props.calculationValue)) {
    return props.calculationValue;
  } else {
    return null;
  }
});
const calculationTypeMatch = computed(() => {
  if (!_.includes(["sizes_uses", "investment_volume"], props.calculationName)) {
    return true;
  } else if (props.calculationIsSurvey && investmentVolumeSurveyFetched.value) {
    return true;
  } else if (props.calculationIsSurvey && sizesSurveyFetched.value) {
    return true;
  } else if (props.calculationContentType && props.calculationContentIds) {
    return (
      sizesSurveyFetched.value ||
      analyzePanelStore.alreadyFetched(
        "sizesUses",
        `${props.calculationContentType}_${props.calculationContentIds.join(
          "_",
        )}`,
      )
    );
  } else {
    return false;
  }
});
const gettable = computed(() => {
  if (props.calculationIsSurvey) {
    switch (props.calculationName) {
      case "investment_volume":
        return !investmentVolumeSurveyFetched.value;
      case "sizes_uses":
        return !sizesSurveyFetched.value;
      default:
        return true;
    }
  } else {
    if (props.calculationName === "sizes_uses") {
      return !analyzePanelStore.alreadyFetched(
        "sizesUses",
        `${props.calculationContentType}_${props.calculationContentIds.join(
          "_",
        )}`,
      );
    } else {
      return false;
    }
  }
});
const primaryText = computed(() => {
  const primaryContent = _.get(field.value, props.primaryTextPath);
  const secondaryContent = _.get(field.value, props.secondaryTextPath);

  if (!_.isNil(calculationOutput.value) && calculationTypeMatch.value) {
    return calculationOutput.value;
  } else if (gettable.value) {
    return "Get";
  } else if (props.calculationName) {
    return _.startCase(props.calculationName);
  } else if (primaryTextOverride.value) {
    return primaryTextOverride.value;
  } else if (
    primaryContent &&
    _.isArray(primaryContent) &&
    !_.isEmpty(primaryContent)
  ) {
    return primaryContent.join(", ");
  } else if (
    primaryContent &&
    !_.isArray(primaryContent) &&
    props.primaryTextPath === "fieldContent.name" &&
    field.value.fieldContentType === "Property" &&
    field.value.fieldContentSubType === "unused_development_rights"
  ) {
    return `${primaryContent} (Air Rights)`;
  } else if (primaryContent && !_.isArray(primaryContent)) {
    return primaryContent;
  } else if (
    secondaryContent &&
    _.isArray(secondaryContent) &&
    !_.isEmpty(secondaryContent)
  ) {
    return secondaryContent.join(", ");
  } else if (secondaryContent && !_.isArray(secondaryContent)) {
    return secondaryContent;
  } else if (
    selfLabel(field.value, props.propertyDataField, props.selfLabelOutputFormat)
  ) {
    return selfLabel(
      field.value,
      props.propertyDataField,
      props.selfLabelOutputFormat,
    );
  } else if (fieldValue.value) {
    return fieldValue.value;
  } else if (unstaked.value && !safezone.value) {
    return "Unstaked data";
  } else if (inValidation.value) {
    return "Data in validation";
  } else if (unpublished.value) {
    return "Unpublished data";
  } else if (unlockable.value) {
    return "Unlockable data";
  } else if (field.value === "await") {
    return "...";
  } else {
    return "Untitled data";
  }
});
const textColor = computed(() => {
  if (props.calculationName) {
    return "#0d9488";
  } else if (unlockable.value) {
    if (safezone.value) return "#ca8a04";
    else if (unstaked.value) return "#be185d";
    else return "#111827";
  } else if (rejected.value) {
    return "#db2777";
  } else if (safezone.value) {
    return "#ca8a04";
  } else if (unpublished.value) {
    return "#059669";
  } else {
    return "#4338ca";
  }
});
const dynamicClass = computed(() => {
  if (props.calculationName) {
    return "text-teal-600 bg-teal-100";
  } else if (unlockable.value) {
    if (safezone.value) return "text-yellow-600 bg-yellow-100";
    else if (unstaked.value) return "text-pink-700 bg-pink-100";
    else return "text-gray-900 bg-gray-200";
  } else if (rejected.value) {
    return "text-pink-600 bg-pink-100";
  } else if (safezone.value) {
    return "text-yellow-600 bg-yellow-100";
  } else if (inValidation.value) {
    return "text-orange-600 bg-orange-100";
  } else if (unpublished.value) {
    return "text-emerald-600 bg-emerald-100";
  } else {
    return "text-indigo-700 bg-indigo-100";
  }
});
const dynamicUnderlineClass = computed(() => {
  if (props.calculationName) {
    return "decoration-solid decoration-teal-500";
  } else if (unlockable.value) {
    if (safezone.value && field.value.authored)
      return "decoration-wavy decoration-yellow-500";
    else if (safezone.value && !props.adminView)
      return "decoration-wavy decoration-emerald-400";
    else if (unstaked.value) return "decoration-dashed decoration-pink-700";
    else return "decoration-dashed decoration-gray-700";
  } else if (rejected.value) {
    return field.value.authored
      ? "decoration-dotted decoration-pink-400"
      : "decoration-wavy decoration-emerald-400";
  } else if (safezone.value && field.value.authored) {
    return "decoration-wavy decoration-yellow-500";
  } else if (safezone.value && !props.adminView) {
    return "decoration-wavy decoration-emerald-400";
  } else if (inValidation.value) {
    return field.value.authored
      ? "decoration-solid decoration-orange-400"
      : "decoration-wavy decoration-emerald-400";
  } else if (unpublished.value) {
    return field.value.authored
      ? "decoration-solid decoration-emerald-400"
      : "decoration-wavy decoration-emerald-400";
  } else {
    return "decoration-solid decoration-indigo-600";
  }
});

watch(patchableCompanySize, () => {
  if (
    props.innerFieldName === "CompanyInvolvement" &&
    patchableCompanySize.value > 0
  ) {
    const matchingField = _.find(allFetchedFields.value, {
      localId: props.dataField.localId,
    });

    if (
      matchingField &&
      innerFieldLastFetchedTime.value &&
      innerFieldLastFetchedTime.value.isBefore(moment().subtract(60, "seconds"))
    ) {
      fetchInnerField({ override: "CompanyInvolvement" });
    }
  }
});

onMounted(() => {
  let modalStoreTemp = props.modalStore;
  let unlockerStoreTemp = props.unlockerStore;
  let layoutStoreTemp = props.layoutStore;
  let propertyDiagramStoreTemp = props.propertyDiagramStore;
  let changeGroupStoreTemp = props.changeGroupStore;

  checkForSubscribePrompt();
  fetchInnerField();
});

const emit = defineEmits([
  "unlocked",
  "completed",
  "open-sourced",
  "admin-open-sourced",
  "suggest-change",
  "dismiss",
  "set-proof",
]);

function setProof(fieldName) {
  emit("set-proof", fieldName);
}
function unlocked(payload) {
  console.log("datafield unlocked", payload);
  setTimeout(() => {
    fetchInnerField(payload);
    emit("unlocked", payload);
    if (!payload?.override) emit("completed", payload);
  }, 500);
}
function openSourced() {
  emit("open-sourced");
}
function adminOpenSourced(newDataField) {
  emit("admin-open-sourced", newDataField);
}
function completed(maybePayload = {}) {
  console.log("datafield completed", maybePayload);
  emit("completed", maybePayload);
}
function suggestChange() {
  emit("suggest-change", { dismissOnSave: props.dismissOnSave });
}
function dismiss() {
  emit("dismiss");
}

async function fetchInnerField(maybePayload) {
  if (props.innerFieldName && _.get(props.dataField, "fieldContent")) {
    const fetchRequestKey = `crowdsourced_data_fields_${props.dataField.fieldContentType}_${props.dataField.fieldContentId}?field_name=${props.innerFieldName}`;

    if (
      dealBuilderStore.alreadyFetched(fetchRequestKey) &&
      !maybePayload?.override
    ) {
      innerField.value = _.head(
        dealBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey),
      );
    } else if (dataFieldLoadingStore.isLoading(fetchRequestKey)) {
      // console.log("currently loading inner field");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // console.log("currently loading RETRY");
      const result = await fetchInnerField(maybePayload);

      return result;
    } else {
      dataFieldLoadingStore.patchIds([fetchRequestKey]);
      // console.log("DATAFIELD fetch inner", fetchRequestKey);
      const fieldResponse = await api.get(
        `crowdsourced_data_fields/${props.dataField.fieldContentType}/${props.dataField.fieldContentId}?field_name=${props.innerFieldName}`,
      );
      innerField.value = _.head(fieldResponse.data);
      innerFieldLastFetchedTime.value = null;
      innerFieldLastFetchedTime.value = moment();
      dataFieldLoadingStore.dropId(fetchRequestKey);
      dealBuilderStore.interceptablePatch(fieldResponse.data, fetchRequestKey);
    }

    checkForSubscribePrompt();
  }

  setUnlockableSurveyIds();
}

onBeforeUnmount(() => {
  if (upgradeInProgress.value) {
    upgradeSuccessful.value = false;
  }

  cleanUpUnlockableSurveyIds();
});

watch(resetRequired, () => {
  if (upgradeInProgress.value) {
    resetRequired.value = false;
    upgradeInProgress.value = false;
  }
});

function setUnlockableSurveyIds() {
  if (props.analyze) {
    analyzePanelStore.patchUnlockableIds(
      _.compact([props.dataField, innerField.value]),
      "standaloneFields",
    );
  }
}

function cleanUpUnlockableSurveyIds() {
  if (props.analyze) {
    analyzePanelStore.dropUnlockableIds(
      _.compact([props.dataField, innerField.value]),
    );
  }
}

function checkForSubscribePrompt() {
  if (
    (interceptField.value || props.interceptCalculation) &&
    !modalPayload.value
  ) {
    promptToSubscribe();
  }
}

function promptToSubscribe() {
  if (
    (interceptField.value || props.interceptCalculation) &&
    !modalPayload.value
  ) {
    upgradeInProgress.value = true;
    const successCallback = () => {
      if (upgradeSuccessful.value) {
        console.log("user subscribed");
        upgradeInProgress.value = false;
        upgradeSuccessful.value = false;
        unlocked();
      }
    };

    subscribeInterceptor({
      apiRequestFunc: null,
      successCallback,
      modalPayloadRef: modalPayload,
      upgradeSuccessfulRef: upgradeSuccessful,
      afterSubscribe: "successCallback",
      promptReason: "dataUsage",
      context: "licensing",
    });
  }
}
</script>
