<template>
  <div class="w-full h-full overflow-hidden flex flex-col gap-4 bg-gray-50">
    <ReminderForm v-if="addingTask" />
    <TaskSearch v-else-if="searching" />
    <TaskListForm v-else-if="addingList" />
    <TaskListSharing v-else-if="sharingList" />
    <TaskBatchPriority v-else-if="taskBatchPriority" />
    <TaskBatchTiming v-else-if="taskBatchTiming" />
    <TaskBatchMove v-else-if="taskBatchMove" />
    <TaskListDetail v-else-if="selectedTaskList" />
    <TodayTasksView v-else-if="aggregateView === 'Today'" />
    <ScheduledTasksView v-else-if="aggregateView === 'Scheduled'" />
    <NearbyTasksView v-else-if="aggregateView === 'Nearby'" />
    <AllTasksView v-else-if="aggregateView === 'All'" />
    <CompletedTasksView v-else-if="aggregateView === 'Completed'" />
    <TaskDashboard v-else />
  </div>
</template>

<script setup>
import TodayTasksView from "@/components/tasks/TodayTasksView.vue";
import ScheduledTasksView from "@/components/tasks/ScheduledTasksView.vue";
import AllTasksView from "@/components/tasks/AllTasksView.vue";
import NearbyTasksView from "@/components/tasks/NearbyTasksView.vue";
import CompletedTasksView from "@/components/tasks/CompletedTasksView.vue";
import ReminderForm from "@/components/tasks/ReminderForm.vue";
import TaskListForm from "@/components/tasks/TaskListForm.vue";
import TaskListSharing from "@/components/tasks/TaskListSharing.vue";
import TaskListDetail from "@/components/tasks/TaskListDetail.vue";
import TaskBatchTiming from "@/components/tasks/TaskBatchTiming.vue";
import TaskBatchPriority from "@/components/tasks/TaskBatchPriority.vue";
import TaskBatchMove from "@/components/tasks/TaskBatchMove.vue";
import TaskDashboard from "@/components/tasks/TaskDashboard.vue";
import TaskSearch from "@/components/tasks/TaskSearch.vue";
import { useTasksStore } from "@/stores/tasks";
import { useTaskListStore } from "@/stores/taskList";
import { useReminderStore } from "@/stores/reminder";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import _ from "lodash";

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const tasksStore = useTasksStore();
const {
  addingTask,
  addingList,
  sharingList,
  taskBatchMove,
  taskBatchTiming,
  taskBatchPriority,
  selectedTaskList,
  searching,
} = storeToRefs(tasksStore);
const reminderStore = useReminderStore();
const taskListStore = useTaskListStore();
const aggregateView = ref(null);

const route = useRoute();
const query = computed(() => route.query);

watch(isDesktop, () => {
  reset();
});

watch(query, () => {
  handleQueryView();
});

onMounted(() => handleQueryView());

onUnmounted(() => {
  reset();
});

function handleQueryView() {
  aggregateView.value = _.get(query.value, "taskView", null);
}

function reset() {
  tasksStore.reset();
  reminderStore.reset();
  taskListStore.reset();
}
</script>
