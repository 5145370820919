<template>
  <div class="relative flex h-full w-full flex-col overflow-hidden">
    <button
      v-if="!expandedView && isDesktop"
      type="button"
      class="absolute top-2 right-6 rounded-full p-2 flex items-center justify-center text-gray-900 shadow-sm hover:text-gray-700"
      @click="cancel"
    >
      <XMarkIcon class="size-6" aria-hidden="true" />
    </button>
    <UnsavedChangesBanner
      :changes="overviewUnsavedChanges"
      @reset="remount"
      @save="update"
    />
    <div
      class="mx-auto flex h-full w-full max-w-7xl items-start gap-x-8"
      :class="[expandedView ? 'px-8 pt-10' : 'p-4']"
    >
      <main class="flex-1 h-full overflow-y-auto">
        <!-- Main area -->
        <form>
          <div class="space-y-12">
            <div class="border-b border-gray-900/10 pb-12">
              <h2 class="text-base/7 font-semibold text-gray-900">Overview</h2>

              <div
                class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8"
                :class="[expandedView ? 'grid-cols-6' : '']"
              >
                <div class="" :class="[expandedView ? 'col-span-4' : '']">
                  <label
                    for="name"
                    class="block text-sm/6 font-medium text-gray-900"
                    >Name</label
                  >
                  <div class="mt-2">
                    <input
                      id="name"
                      v-model="name"
                      v-focus
                      type="text"
                      name="name"
                      class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                      :class="[expandedView ? 'text-sm/6' : '']"
                      placeholder="Hub name"
                    />
                  </div>
                </div>

                <div class="col-span-full">
                  <label
                    for="description"
                    class="block text-sm/6 font-medium text-gray-900"
                    >Description</label
                  >
                  <p class="mt-2 text-sm/6 text-gray-600">
                    Let everyone know how to use this Topic.
                  </p>
                  <div class="mt-2">
                    <textarea
                      id="description"
                      v-model="description"
                      name="description"
                      rows="3"
                      class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                      :class="[expandedView ? 'text-sm/6' : '']"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="border-b border-gray-900/10 pb-12">
              <Listbox v-model="inactivityTimeout" as="div">
                <ListboxLabel class="block text-sm/6 font-medium text-gray-900"
                  >Hide After Inactivity</ListboxLabel
                >
                <p class="mt-2 text-sm/6 text-gray-600">
                  New threads will not show in the Topic list after being
                  inactive for the specified duration.
                </p>
                <div class="relative mt-2">
                  <ListboxButton
                    class="grid w-full cursor-default grid-cols-1 rounded-md bg-white py-1.5 pl-3 pr-2 text-left text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                  >
                    <span class="col-start-1 row-start-1 truncate pr-6">{{
                      inactivityTimeout.label
                    }}</span>
                    <ChevronUpDownIcon
                      class="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                      aria-hidden="true"
                    />
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        v-for="inactivityOption in inactivityOptions"
                        :key="inactivityOption.label"
                        v-slot="{ active, selected }"
                        as="template"
                        :value="inactivityOption"
                      >
                        <li
                          :class="[
                            active
                              ? 'bg-indigo-600 text-white outline-none'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ inactivityOption.label }}</span
                          >

                          <span
                            v-if="selected"
                            :class="[
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="size-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>

          <div class="mt-6 flex items-center justify-end gap-x-6" />
        </form>
      </main>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { CheckIcon } from "@heroicons/vue/20/solid";
import { ChevronUpDownIcon } from "@heroicons/vue/16/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useWeTopicSettingsStore } from "@/stores/weTopicSettings";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { nextTick, onMounted } from "vue";
import UnsavedChangesBanner from "@/components/we/settings-views/UnsavedChangesBanner.vue";
import _ from "lodash";

const wePanelStore = useWePanelStore();
const { expandedView, mainContent } = storeToRefs(wePanelStore);
const weTopicSettingsStore = useWeTopicSettingsStore();
const { overviewUnsavedChanges, name, description, inactivityTimeout } =
  storeToRefs(weTopicSettingsStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const oneHourSeconds = 60 * 60;
const oneDaySeconds = 60 * 60 * 24;
const threeDaysSeconds = 60 * 60 * 24 * 3;
const sevenDaysSeconds = 60 * 60 * 24 * 7;
const inactivityOptions = [
  { label: "1 Hour", value: oneHourSeconds },
  { label: "24 Hours", value: oneDaySeconds },
  { label: "3 Days", value: threeDaysSeconds },
  { label: "7 Days", value: sevenDaysSeconds },
];

onMounted(() => {
  remount();
});

async function remount() {
  if (mainContent.value?.content?.topicType) {
    weTopicSettingsStore.resetOverview();

    await nextTick();

    name.value = mainContent.value.content.name;
    description.value = mainContent.value.content.description || "";
    inactivityTimeout.value = _.find(inactivityOptions, {
      value: mainContent.value.content.inactivityTimeout,
    });
  }
}

async function update() {
  weTopicSettingsStore.updateTopicOverview();
}

function cancel() {
  wePanelStore.closeBottomEdgeLevelTwoPanel();
}
</script>
