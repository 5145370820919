<template>
  <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
    <div
      class="h-32 w-full bg-gradient-to-t to-80%"
      :class="[brandColorClass]"
    />
    <div class="pb-4 px-4 lg:px-8">
      <div class="-mt-8 sm:-mt-12 sm:flex">
        <div
          class="flex bg-gray-50 rounded-lg overflow-hidden ring-4 ring-white"
        >
          <img
            class="size-12 sm:size-20"
            src="https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png"
            alt=""
          />
        </div>
      </div>
      <div class="mt-2 min-w-0 flex-1">
        <h1 class="truncate text-2xl font-bold text-gray-900">
          {{ hub.name }}
        </h1>
      </div>
      <div class="flex items-center gap-x-3">
        <div class="flex items-center gap-x-0.5 text-xs text-gray-500">
          <div class="rounded-full size-2 bg-gray-400" />
          <div>{{ hub.membersCount }} Members</div>
        </div>
        <div class="flex items-center gap-x-0.5 text-xs text-gray-500">
          <div class="rounded-full size-2 bg-green-400" />
          <div>{{ hub.onlineMembersCount }} Online</div>
        </div>
      </div>
      <div class="mt-1 text-xs text-gray-500">
        Est. {{ moment(hub.createdAt).year() }}
      </div>
      <div v-if="hub.description" class="mt-2 text-xs text-gray-500">
        {{ hub.description }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useWeHubSettingsStore } from "@/stores/weHubSettings";
import { storeToRefs } from "pinia";
import _ from "lodash";
import moment from "moment/moment";
import { computed } from "vue";

const props = defineProps(["hub"]);

const weHubSettingsStore = useWeHubSettingsStore();
const { brandColorOptions } = storeToRefs(weHubSettingsStore);

const brandColorClass = computed(() =>
  _.find(brandColorOptions.value, { name: props.hub?.brandColor })?.background,
);
</script>
