<template>
  <div class="relative flex flex-col">
    <button
      type="button"
      class="absolute top-2 right-2 rounded-full p-2 flex items-center justify-center text-gray-900 shadow-sm hover:text-gray-700"
      @click="wePanelStore.closeSecondaryContentPanel()"
    >
      <XMarkIcon class="size-6" aria-hidden="true" />
    </button>
    <div class="p-2">
      <h2 class="text-base/6 font-semibold text-gray-900">
        Invite people to {{ activeOption.name }}
      </h2>
      <div class="flex items-center gap-x-1">
        <TopicTypeIcon
          :topic="channel"
          lock-size="size-2.5"
          icon-size="size-4"
        />
        <h3 class="text-xs text-gray-700">
          {{ channel.name }}
        </h3>
      </div>
      <SearchableInput
        v-model="networkSearch"
        :selected-items="[]"
        :chip-component="AutocompleteNameChip"
        placeholder="Search your network"
        class="mt-2 block w-full rounded-md bg-white border border-gray-300"
        @remove-item="removeInvitee"
      />
    </div>
    <div
      class="p-2 flex-grow max-h-48 overflow-y-auto"
      :class="[expandedView ? 'w-96' : '']"
    >
      <legend class="sr-only">My Network Contacts</legend>
      <div class="">
        <div
          v-if="networkings.length === 0"
          class="p-8 flex flex-col items-center gap-y-4"
        >
          <UsersIcon class="size-10 text-gray-500" />
          <h3 class="text-center text-sm text-gray-600">
            People make real estate come alive.
          </h3>
        </div>
        <div
          v-for="networking in networkings"
          :key="networking.id"
          class="relative rounded-md px-2 py-1 flex items-center gap-3 hover:bg-gray-300"
        >
          <label :for="`networking-${networking.id}`" class="flex-grow">
            <NetworkingListElement :networking="networking" :injected="true" />
          </label>
          <div class="flex shrink-0 items-center">
            <div
              v-if="_.includes(invitees, networking)"
              type="button"
              class="rounded-md px-2.5 py-1.5 text-sm font-semibold text-gray-400 shadow-sm"
              @click="invitees.value.push(networking)"
            >
              Sent
            </div>
            <button
              v-else
              type="button"
              class="rounded-md px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-green-400 hover:bg-green-400"
              @click="sendInvite(networking)"
            >
              <PulseLoader
                v-if="_.includes(pendingInvitees, networking)"
                :loading="true"
                size="4px"
                color="#111827"
              />
              <template v-else>Invite</template>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="px-2 py-4 border-t border-gray-400 flex flex-col gap-y-2">
      <h2 class="text-base/6 font-semibold text-gray-900">
        Or, send a server invite link to someone
      </h2>
      <div
        v-if="mostRecentInvitation"
        :class="[
          'flex rounded-md ring-1 ring-offset-2 transition-colors duration-500',
          isCopied ? 'ring-green-500' : 'ring-gray-400',
        ]"
      >
        <div class="-mr-px grid grow grid-cols-1 focus-within:relative">
          <input
            id="invite-link"
            type="text"
            name="invite-link"
            readonly
            :value="`https://towerhunt.com/hubs/${mostRecentInvitation.token}`"
            class="cursor-text col-start-1 row-start-1 block w-full rounded-l-md bg-white px-2 text-gray-900 border-none text-xs/5 focus:outline-none focus:ring-0"
            :class="[isCopied ? 'bg-green-200' : '']"
            @click="$event.target.select()"
          />
        </div>
        <button
          type="button"
          :class="[
            'flex shrink-0 items-center gap-x-1.5 rounded-md px-4 py-2 text-sm font-semibold text-white outline outline-1 -outline-offset-1 outline-gray-300 focus:relative focus:outline focus:outline-2 focus:-outline-offset-2 transition-colors duration-500',
            isCopied
              ? 'bg-green-400 focus:outline-none'
              : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-indigo-600',
          ]"
          @click="copyToClipboard"
        >
          {{ isCopied ? "Copied" : "Copy" }}
        </button>
      </div>
      <h4 class="flex space-x-1 text-xs">
        <span class="text-gray-600">{{ mostRecentInvitationSummary }}</span>
        <a
          href=""
          class="text-indigo-600 hover:underline"
          @click.prevent="editInviteLink"
          >Edit invite link.</a
        >
      </h4>
    </div>
  </div>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref } from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import TopicTypeIcon from "@/components/we/channels-format/TopicTypeIcon.vue";
import CreateHubInvitationForm from "@/components/we/channels-format/CreateHubInvitationForm.vue";
import SearchableInput from "@/components/we/SearchableInput.vue";
import AutocompleteNameChip from "@/components/we/AutocompleteNameChip.vue";
import NetworkingListElement from "@/components/we/direct-messages/NetworkingListElement.vue";
import { XMarkIcon, UsersIcon } from "@heroicons/vue/24/outline";
import _ from "lodash";
import moment from "moment";

const props = defineProps(["channel"]);
const emit = defineEmits(["alternate-component", "primary-component"]);

const wePanelStore = useWePanelStore();
const { expandedView, networkings, hubInvitations, activeOption } =
  storeToRefs(wePanelStore);

const invitees = ref([]);
const pendingInvitees = ref([]);
const networkSearch = ref("");
const isCopied = ref(false);

const mostRecentInvitation = computed(() => {
  const matchingInvitations = hubInvitations.value.filter((invitation) => {
    return invitation.topicId === props.channel.id;
  });
  return _.maxBy(matchingInvitations, function ({ createdAt }) {
    return moment(createdAt).valueOf();
  });
});
const mostRecentInvitationSummary = computed(() => {
  if (mostRecentInvitation.value) {
    const { maxAge, maxUses } = mostRecentInvitation.value;
    let humanReadableMaxAge = moment.duration(maxAge, "seconds").humanize();

    const ageCopy = `Your invite link expires in ${humanReadableMaxAge}`;
    const usesCopy = maxUses === 0 ? "." : `, or after ${maxUses} uses.`;

    return `${ageCopy}${usesCopy}`;
  } else {
    return null;
  }
});

onMounted(async () => {
  await wePanelStore.fetchNetworkings();
  await wePanelStore.fetchInvitations();

  if (!mostRecentInvitation.value) {
    const sevenDaysSeconds = 60 * 60 * 24 * 7;

    await wePanelStore.createTopicInvitation({
      topicId: props.channel.id,
      maxAge: sevenDaysSeconds,
      maxUses: 0,
    });
  }
});

async function sendInvite(networking) {
  const inviteeUser = wePanelStore.networkingCounterpart(networking);
  await wePanelStore.sendTopicInvitation({
    invitation: mostRecentInvitation.value,
    inviteeUser, // find_by(token: invitee_user[:token])
  });
  finalizeInvitee(networking);
}

function finalizeInvitee(item) {
  invitees.value.push(item);
  pendingInvitees.value = pendingInvitees.value.filter(
    ({ id }) => id !== item.id,
  );
}

function removeInvitee(item) {
  invitees.value = invitees.value.filter(({ id }) => id !== item.id);
}

function editInviteLink() {
  if (expandedView.value) {
    emit("alternate-component", CreateHubInvitationForm);
  } else {
    wePanelStore.openSecondaryContentPanel({
      component: CreateHubInvitationForm,
      props: {
        channel: props.channel,
      },
    });
  }
}

const copyToClipboard = () => {
  const input = document.getElementById("invite-link");
  if (input) {
    // input.select();
    navigator.clipboard.writeText(input.value);

    // Visual feedback logic
    isCopied.value = true;
    setTimeout(() => {
      isCopied.value = false;
    }, 1000);
  }
};
</script>
