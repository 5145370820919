<template>
  <li
    class="p-2 rounded-md flex items-center justify-between hover:bg-gray-300"
  >
    <DataField
      v-if="networking.inviter.linkedContactDataField"
      :data-field="networking.inviter.linkedContactDataField"
      primary-text-path="fieldContent.name"
      text-classes="text-sm font-semibold"
      text-styles=""
    />
    <div v-else class="flex items-center gap-2">
      <AvatarPhoto
        :person="networking.inviter"
        shape-size="h-8 w-8"
        text-size="md"
      />
      <h2 class="text-sm text-gray-700 font-medium">
        {{ networking.inviter.name }}
      </h2>
    </div>
    <div class="flex items-center gap-2">
      <button
        type="button"
        class="rounded-full bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        @click="wePanelStore.acceptNetworkingRequest(networking.id)"
      >
        Accept
      </button>
      <button
        type="button"
        class="rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        @click="wePanelStore.rejectNetworkingRequest(networking.id)"
      >
        Ignore
      </button>
    </div>
  </li>
</template>

<script setup>
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useWePanelStore } from "@/stores/wePanel";

defineProps(["networking"]);

const wePanelStore = useWePanelStore();
</script>
