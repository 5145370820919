<template>
  <div
    v-shortkey.push="['shift']"
    class="relative h-full w-full flex flex-col"
    @dragover.prevent="dragging = true"
    @shortkey="wePanelStore.instantUploadHandler()"
  >
    <MessageFileDragDrop v-if="isDesktop && activeOption" />

    <!-- Takeover panel -->
    <WeTakeoverPanel v-if="expandedView" />

    <!-- Bottom edge panel -->
    <WeBottomEdgePanel />

    <!-- Bottom edge level two panel -->
    <WeBottomEdgeLevelTwoPanel />

    <!-- Right edge panel -->
    <WeRightEdgePanel />

    <!-- Right edge level two panel -->
    <WeRightEdgeLevelTwoPanel />

    <div class="h-full w-full flex">
      <main v-if="activeView" class="h-full w-full flex flex-row bg-gray-100">
        <nav
          aria-label="Sidebar"
          class="flex-shrink-0 flex flex-col gap-2 p-3 bg-gray-100 h-full overflow-y-auto overflow-x-hidden"
          :class="[isDesktop ? 'gap-1.5 p-2' : 'gap-2 p-3']"
        >
          <div
            v-for="({ options }, index) in primaryNavigationOptions"
            :key="options.name"
            class="relative flex flex-col items-center gap-1.5"
          >
            <a
              v-for="option in options"
              :key="option.name"
              v-tooltip.right="option.label || option.name"
              :href="option.href"
              :class="[
                option.iconClass(option),
                'flex-shrink-0 inline-flex items-center justify-center p-0.5',
                [isDesktop ? 'size-8 rounded-lg' : 'size-12 rounded-full'],
              ]"
              :data-test="`${_.kebabCase(option.name)}-nav`"
              @click.prevent="() => wePanelStore.selectActiveOption(option)"
            >
              <span class="sr-only">{{ option.name }}</span>
              <component
                :is="option.icon"
                v-bind="option.iconProps"
                :class="[
                  activeOption?.name === option.name ? '' : '',
                  isDesktop ? 'size-5' : 'size-7',
                ]"
              />
            </a>
            <div
              v-if="index !== primaryNavigationOptions.length - 1"
              class="w-full flex items-center"
              aria-hidden="true"
            >
              <div class="w-full border-t-2 border-gray-300" />
            </div>
          </div>
        </nav>

        <div class="h-full flex flex-col flex-grow overflow-hidden">
          <component :is="activeView.component" v-bind="activeView.props" />
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import MessageFileDragDrop from "@/components/we/message-input/MessageFileDragDrop.vue";
import WeTakeoverPanel from "@/components/we/settings-views/WeTakeoverPanel.vue";
import WeBottomEdgePanel from "@/components/we/settings-views/WeBottomEdgePanel.vue";
import WeBottomEdgeLevelTwoPanel from "@/components/we/settings-views/WeBottomEdgeLevelTwoPanel.vue";
import WeRightEdgePanel from "@/components/we/settings-views/WeRightEdgePanel.vue";
import WeRightEdgeLevelTwoPanel from "@/components/we/settings-views/WeRightEdgeLevelTwoPanel.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { computed, onMounted, onBeforeUnmount, watch, nextTick } from "vue";
import _ from "lodash";

const wePanelStore = useWePanelStore();
const {
  expandedView,
  dragging,
  instantUpload,
  primaryNavigationOptions,
  activeOption,
  activeView,
  nearbyMessagesTab,
} = storeToRefs(wePanelStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop, workspaceLayout } = storeToRefs(layoutStore);

const route = useRoute();
const query = computed(() => route.query);

const horizontalIsWe = computed(
  () => _.get(query.value, "horizontalTab") === "We",
);

watch(horizontalIsWe, () => {
  if (horizontalIsWe.value) wePanelStore.changeView();
});

watch(workspaceLayout, (val) => {
  switch (val) {
    case "sideBySide":
      wePanelStore.closeRightEdgePanel();
      wePanelStore.closeRightEdgeLevelTwoPanel();
      break;
    case "topAndBottom":
      wePanelStore.closeBottomEdgePanel();
      wePanelStore.closeBottomEdgeLevelTwoPanel();
      break;
    case "fullScreen":
      wePanelStore.closeRightEdgePanel();
      wePanelStore.closeRightEdgeLevelTwoPanel();
      wePanelStore.closeBottomEdgePanel();
      wePanelStore.closeBottomEdgeLevelTwoPanel();
      break;
    default:
      break;
  }
});

onMounted(async () => {
  await nextTick();
  if (!activeOption.value && nearbyMessagesTab.value) {
    wePanelStore.selectActiveOption(nearbyMessagesTab.value);
  }
});
onBeforeUnmount(() => {
  dragging.value = false;
  instantUpload.value = false;
});
</script>
