<template>
  <SwitchGroup as="div" class="flex items-center justify-between gap-x-4">
    <span class="flex grow flex-col space-y-1">
      <SwitchLabel as="div" class="flex items-center space-x-2" passive>
        <LockClosedIcon v-if="privateIcon" class="size-6 text-gray-900" />
        <span class="text-sm/6 font-medium text-gray-900">{{ label }}</span>
      </SwitchLabel>
      <SwitchDescription as="span" class="text-sm text-gray-500">{{
        description
      }}</SwitchDescription>
    </span>

    <Switch
      v-model="internalModelValue"
      :class="[
        internalModelValue ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
      ]"
    >
      <span class="sr-only">Use setting</span>
      <span
        :class="[
          internalModelValue ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]"
      >
        <span
          :class="[
            internalModelValue
              ? 'opacity-0 duration-100 ease-out'
              : 'opacity-100 duration-200 ease-in',
            'absolute inset-0 flex size-full items-center justify-center transition-opacity',
          ]"
          aria-hidden="true"
        >
          <svg class="size-3 text-gray-400" fill="none" viewBox="0 0 12 12">
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span
          :class="[
            internalModelValue
              ? 'opacity-100 duration-200 ease-in'
              : 'opacity-0 duration-100 ease-out',
            'absolute inset-0 flex size-full items-center justify-center transition-opacity',
          ]"
          aria-hidden="true"
        >
          <svg
            class="size-3 text-indigo-600"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path
              d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
            />
          </svg>
        </span>
      </span>
    </Switch>
  </SwitchGroup>
</template>

<script setup>
import { LockClosedIcon } from "@heroicons/vue/20/solid";
import {
  Switch,
  SwitchGroup,
  SwitchLabel,
  SwitchDescription,
} from "@headlessui/vue";

import { ref, watch, watchEffect } from "vue";

const props = defineProps(["modelValue", "label", "description", "privateIcon"]);
const emits = defineEmits(["update:modelValue"]);

const internalModelValue = ref(props.modelValue);

watchEffect(() => {
  emits("update:modelValue", internalModelValue.value);
});

watch(
  () => props.modelValue,
  (newValue) => {
    internalModelValue.value = newValue;
  },
);
</script>
