<template>
  <li
    class="p-2 rounded-md flex items-center justify-between hover:bg-gray-300"
  >
    <DataField
      v-if="networking.recipient.linkedContactDataField"
      :data-field="networking.recipient.linkedContactDataField"
      primary-text-path="fieldContent.name"
      text-classes="text-sm font-semibold"
      text-styles=""
    />
    <div v-else class="flex items-center gap-2">
      <AvatarPhoto
        :person="networking.recipient"
        shape-size="h-8 w-8"
        text-size="md"
      />
      <h2 class="text-sm text-gray-700 font-medium">
        {{ networking.recipient.name }}
      </h2>
    </div>
    <button
      v-tooltip="'Revoke'"
      type="button"
      class="rounded-full border border-gray-500 p-1 text-gray-500 shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
      @click="wePanelStore.revokeNetworkingRequest(networking.id)"
    >
      <XMarkIcon class="size-5" aria-hidden="true" />
    </button>
  </li>
</template>

<script setup>
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { XMarkIcon } from "@heroicons/vue/20/solid";

defineProps(["networking"]);

const wePanelStore = useWePanelStore();
</script>
