<template>
  <li
    class="w-full flex px-2"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <a
      href=""
      class="w-full px-1 py-2 rounded-md flex items-center justify-between hover:bg-gray-300 gap-x-2"
      :class="[wePanelStore.activeSelectionOptionClass(directMessage)]"
      @click.prevent="view"
    >
      <div
        class="max-w-[90%] flex-shrink-0 flex items-center space-x-2"
      >
        <span
          class="inline-block size-8 overflow-hidden rounded-full bg-gray-100"
        >
          <svg
            class="size-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        </span>
        <div class="flex flex-col">
          <div class="text-gray-700 font-medium hover:text-gray-600 truncate">{{ directMessage.name || directMessage.groupName }}</div>
          <div v-if="(directMessage.name && directMessage.memberCount >= 2) || directMessage.memberCount > 2" class="text-xs text-gray-500 truncate">{{ directMessage.memberCount }} Members</div>
        </div>
      </div>
      <button
        v-if="isHovered || wePanelStore.selectionIsActive(directMessage)"
        type="button"
        class="flex-shrink-0 inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
        @click.stop.prevent="wePanelStore.leaveDirectMessage(directMessage)"
      >
        <XMarkIcon class="size-5" />
      </button>
    </a>
  </li>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { ref } from "vue";

const props = defineProps(["directMessage"]);
const wePanelStore = useWePanelStore();

const isHovered = ref(false);

function view() {
  wePanelStore.openDirectMessage(props.directMessage);
}
</script>
