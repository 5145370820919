<template>
  <div class="flex-grow">
    <Slate
      v-if="editor"
      :editor="editor"
      :render-element="renderElement"
      :render-leaf="renderLeaf"
      :render-placeholder="renderPlaceholder"
      :decorate="decorate"
      @change="persist"
    >
      <Editable
        id="slate-editable"
        class="message-editor"
        :placeholder="placeholder"
        :class="[
          'flex-grow',
          'overflow-y-auto',
          'ml-2',
          'py-2',
          'border-none',
          'text-gray-600',
          'text-sm/6',
          'focus:ring-0',
          'outline-none',
          'min-h-[1.5rem]',
          'max-h-[10rem]',
        ]"
        @keyup="handleEnter"
        @focus="handleFocus"
        @blur="handleBlur"
      />
    </Slate>
  </div>
</template>

<script setup>
import { Slate, Editable } from "slate-vue3";
import { createEditor, Text, Transforms } from "slate-vue3/core";
import Prism from "prismjs";
import "prismjs/components/prism-markdown";
import { withDOM } from "slate-vue3/dom";
import { withHistory } from "slate-vue3/history";
import { h, ref, onMounted, nextTick, watch } from "vue";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { useWeMessageInputStore } from "@/stores/weMessageInput";

const props = defineProps({
  placeholder: {
    type: String,
    default: "Message",
  },
  initialContent: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["enter", "focus", "blur"]);

const wePanelStore = useWePanelStore();
const { activeTopicId } = storeToRefs(wePanelStore);
const weMessageInputStore = useWeMessageInputStore();
const { topicMessageDrafts } = storeToRefs(weMessageInputStore);

const renderElement = ({ attributes, children }) => {
  return h("p", attributes, children);
};
const renderPlaceholder = ({ attributes, children }) => {
  const style = {
    position: "absolute",
    top: "0.5rem",
    color: "#9ca3af",
    opacity: "100",
  };

  return h("p", { ...attributes, style }, children);
};
const decorate = ([node, path]) => {
  const ranges = [];

  if (!Text.isText(node)) {
    return ranges;
  }

  const getLength = (token) => {
    if (typeof token === "string") {
      return token.length;
    } else if (typeof token.content === "string") {
      return token.content.length;
    } else {
      return token.content.reduce((l, t) => l + getLength(t), 0);
    }
  };

  const tokens = Prism.tokenize(node.text, Prism.languages.markdown);
  let start = 0;

  for (const token of tokens) {
    const length = getLength(token);
    const end = start + length;

    if (typeof token !== "string") {
      ranges.push({
        [token.type]: true,
        anchor: { path, offset: start },
        focus: { path, offset: end },
      });
    }

    start = end;
  }

  return ranges;
};

const renderLeaf = (props) => {
  const { attributes, children, leaf } = props;
  let decoration = "";
  if (leaf.underlined) {
    decoration = "underline";
  } else if (leaf.strike) {
    decoration = "line-through";
  }

  const style = {
    fontWeight: leaf.bold ? "bold" : "",
    fontStyle: leaf.italic ? "italic" : "",
    textDecoration: decoration,
  };

  return h("span", { ...attributes, style }, children);
};

const editor = ref(null);

watch(activeTopicId, () => {
  remount();
});

onMounted(() => {
  remount();
});

async function remount() {
  editor.value = null;
  await nextTick();

  const existingChildren = activeTopicId.value
    ? topicMessageDrafts.value[activeTopicId.value]
    : null;
  const defaultChildren = [{ text: props.initialContent || "" }];

  const initialValue = [
    {
      type: "paragraph",
      children: existingChildren || defaultChildren,
    },
  ];
  editor.value = withHistory(withDOM(createEditor(initialValue)));

  await nextTick();

  internalFocus();

  setTimeout(() => {
    Transforms.move(editor.value, { distance: 1, unit: "line" });
  }, 20);
}

async function handleEnter(event) {
  if (event.key === "Enter" && !event.shiftKey) {
    event.preventDefault();
    emit("enter");
    await nextTick();
    editor.value = null;
    await nextTick();
    remount();
  }
}

const handleFocus = () => {
  emit("focus");
};

const handleBlur = () => {
  emit("blur");
};

function persist(val) {
  const isAstChange = val.operation.type !== "set_selection";

  if (isAstChange && activeTopicId.value) {
    topicMessageDrafts.value[activeTopicId.value] = editor.value.children;
  }
}

function internalFocus() {
  const el = document.getElementById("slate-editable");

  if (el) {
    el.focus();
  }
}

// Expose a method to focus the editor from the parent component
defineExpose({
  focus: () => {
    internalFocus();
  },
});
</script>

<style scoped>
.message-editor {
  word-break: break-word;
}
</style>
