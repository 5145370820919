<template>
  <div class="h-full flex flex-col divide-y divide-gray-300">
    <ol v-if="nearby" class="w-full px-2 py-1">
      <li>
        <a
          href=""
          class="px-1 py-2 rounded-md flex items-center space-x-2 text-gray-700 font-medium hover:bg-gray-300 hover:text-gray-600"
          :class="[wePanelStore.activeSelectionOptionClass('Nearby Intel')]"
          @click.prevent="view('Nearby Intel')"
        >
          <MapPinIcon class="size-5" />
          <span>Nearby Intel</span>
        </a>
      </li>
    </ol>
    <ol class="flex-grow w-full py-1 overflow-y-auto">
      <h2 class="w-full p-2 flex items-center justify-between">
        <div class="text-gray-700 font-semibold text-sm">Regions</div>
        <button
          v-if="isDesktop"
          v-tooltip.top-end="'Create Region'"
          type="button"
          class="inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          @click="createRegion"
        >
          <PlusIcon class="size-5" />
        </button>
      </h2>
      <div
        v-if="regions.length === 0"
        class="mt-8 p-8 flex flex-col items-center gap-y-4"
      >
        <div class="text-5xl text-gray-500">
          <i class="fa-solid fa-draw-polygon"></i>
        </div>
        <h3 class="text-center text-sm text-gray-600">
          Regions let you filter intel more precisely by location.
        </h3>
        <button
          type="button"
          class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          @click="createRegion"
        >
          Create Region
        </button>
      </div>
      <RegionListElement
        v-for="region in regions"
        :key="region.id"
        :region="region"
      />
      <InfiniteLoading
        v-if="regionsPagy && regionsPagy.next"
        @infinite="async ($state) => wePanelStore.loadRegions($state)"
      />
    </ol>
  </div>
</template>

<script setup>
import { MapPinIcon, PlusIcon } from "@heroicons/vue/20/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { markRaw, onMounted, watch } from "vue";
import RegionMessagesContainer from "@/components/we/main-content-views/RegionMessagesContainer.vue";
import ParcelLocationEdit from "@/components/crowdsourcing/ParcelLocationEdit.vue";
import RegionListElement from "@/components/we/nearby-format/RegionListElement.vue";

const wePanelStore = useWePanelStore();
const {
  nearby,
  activeOption,
  expandedView,
  rightEdgeComponent,
  mainContent,
  regions,
  regionsPagy,
  directMessagesTab,
} = storeToRefs(wePanelStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

watch(nearby, () => {
  if (!nearby.value && wePanelStore.selectionIsActive("Nearby Intel")) {
    if (regions.value.length > 0) {
      rightEdgeComponent.value = markRaw(RegionMessagesContainer);
      mainContent.value = regions.value[0];
    } else {
      activeOption.value = directMessagesTab.value;
    }
  }
});

onMounted(() => {
  if (expandedView.value && nearby.value) {
    view("Nearby Intel");
  }
});

function view(content) {
  rightEdgeComponent.value = markRaw(RegionMessagesContainer);
  mainContent.value = content;
}

function createRegion() {
  wePanelStore.openSecondaryContentPanel({
    component: ParcelLocationEdit,
    props: {
      context: "wePanelUserRegions",
    },
  });
}
</script>
