<template>
  <div class="px-1" :class="[context === 'channelMemberDetail' ? '' : 'mb-4']">
    <div
      class="flex items-start rounded-lg bg-white"
      :class="[
        context === 'channelMemberDetail'
          ? 'border border-gray-400 px-2 py-0.5'
          : 'px-4 py-1',
      ]"
    >
      <VDropdown
        v-if="context !== 'channelMemberDetail'"
        class="flex items-center"
        placement="top-start"
      >
        <button
          type="button"
          class="inline-flex items-center py-2 gap-x-1.5 rounded-md text-indigo-600 text-sm font-semibold shadow-sm hover:text-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PlusCircleIcon class="size-6" aria-hidden="true" />
        </button>
        <template #popper>
          <div class="p-2">
            <ol class="flex flex-col gap-y-1">
              <li
                v-for="option in actionableCreationOptions"
                :key="option.name"
              >
                <a
                  v-close-popper
                  class="flex items-center gap-x-2"
                  href=""
                  @click.prevent="option.handler(messageInputFilesInput)"
                >
                  <component
                    :is="option.icon"
                    class="size-5 text-indigo-600 hover:text-indigo-500"
                  />
                  <span
                    class="text-sm font-semibold text-indigo-600 hover:text-indigo-500"
                    >{{ option.name }}</span
                  >
                </a>
              </li>
            </ol>
          </div>
        </template>
      </VDropdown>
      <MessageEditor
        ref="messageEditor"
        :placeholder="placeholder"
        @enter="sendMessage"
        @focus="handleEditorFocus"
        @blur="handleEditorBlur"
      />
    </div>
    <input
      ref="messageInputFilesInput"
      type="file"
      :multiple="true"
      :accept="'image/*,' + all.join(',')"
      :data-direct-upload-url="fileUploadUrl"
      class="hidden"
      name="Files"
      @change="handleInputFiles"
      data-test="upload-files-input"
    />
  </div>
</template>

<script setup>
import MessageEditor from "@/components/we/message-input/MessageEditor.vue";
import Uploader from "@/components/crowdsourcing/uploader";
import { all } from "@/assets/documentFileTypes";
import { PlusCircleIcon } from "@heroicons/vue/20/solid";
import { useWeMessageInputStore } from "@/stores/weMessageInput";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { ref, computed, watch, nextTick } from "vue";
import _ from "lodash";

const props = defineProps(["context", "membership"]);
const emit = defineEmits(["message-sent"]);

const weMessageInputStore = useWeMessageInputStore();
const { actionableCreationOptions } = storeToRefs(weMessageInputStore);
const userStore = useUserStore();
const { emailUnverified } = storeToRefs(userStore);

const messageEditor = ref(null);
const placeholder = ref('Message');
const messageInputFilesInput = ref(null);
const files = ref([]);
const uploadedFiles = ref([]);
const pendingFileCount = ref(0);
const fileUploadUrl = "/rails/active_storage/direct_uploads";
const uploading = ref(false);
const uploadingProgress = ref(null);
const uploadingTotal = ref(null);

const fileNames = computed(() => uploadedFiles.value.map((f) => f.name));
const fileSizeLimit = computed(() => 100);

const filesToPersist = computed(() =>
  _.differenceBy(files.value, uploadedFiles.value, "signedId"),
);
const readyToPersist = computed(() => {
  return (
    filesToPersist.value.length > 0 &&
    pendingFileCount.value === 0 &&
    filesToPersist.value.every((file) => !file.errorMessage)
  );
});

watch(filesToPersist, async () => {
  if (readyToPersist.value) {
    console.log(
      "TODO: INLINE MESSAGING FILES READY TO PERSIST IN WEPANEL STORE. SEND TO SERVER WITH MESSAGE SUBMIT.",
    );
  }
});

async function sendMessage() {
  weMessageInputStore.sendMessage({
    context: props.context,
    membership: props.membership,
  });
  emit("message-sent");
}

async function handleEditorFocus() {}

async function handleEditorBlur() {}

function handleInputFiles() {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else {
    const toLoad = Array.from(messageInputFilesInput.value.files);

    pendingFileCount.value = toLoad.length;
    toLoad.forEach(loadFiles);
    messageInputFilesInput.value.value = null;
  }
}
async function loadFiles(file) {
  if (_.includes(fileNames, file.name)) return;

  await processFile(file);
}
async function processFile(file) {
  const fileSize = (file.size / 1024 / 1024).toFixed(4); // MB

  if (fileSize > fileSizeLimit.value) {
    files.value.push(
      Object.assign(
        {},
        {
          name: file.name,
          size: file.size,
          signedId: null,
          errorMessage: `is > ${fileSizeLimit.value}MB.`,
        },
      ),
    );
    pendingFileCount.value--;
    setTimeout(() => {
      remove(file);
    }, 3500);
  } else {
    await uploadFile(file);
  }
}
async function uploadFile(file) {
  const uploader = new Uploader(
    file,
    fileUploadUrl,
    files,
    pendingFileCount,
    uploading,
    uploadingProgress,
    uploadingTotal,
  );
  const reader = new FileReader();

  reader.addEventListener("load", () => {
    // console.log("loaded");
  });

  reader.readAsDataURL(file);

  await uploader.directUpload();
}
function remove(file) {
  const newFiles = files.value.filter((f) => f.name !== file.name);

  files.value = newFiles;

  attemptDirectRemove(file);
}
let channelMessageFiles = null;
function attemptDirectRemove(file) {
  // TODO: Store should maintain message files state on a per-channel basis.
  if (channelMessageFiles) {
    const newFiles = channelMessageFiles.filter((f) => f.name !== file.name);

    channelMessageFiles = newFiles;
  }
}
</script>
