<template>
  <div class="relative flex h-full w-full flex-col overflow-hidden">
    <button
      v-if="!expandedView && isDesktop"
      type="button"
      class="absolute top-2 right-6 rounded-full p-2 flex items-center justify-center text-gray-900 shadow-sm hover:text-gray-700"
      @click="cancel"
    >
      <XMarkIcon class="size-6" aria-hidden="true" />
    </button>
    <UnsavedChangesBanner
      v-if="!expandedView"
      :changes="permissionUnsavedChanges"
      @reset="remount"
      @save="update"
    />
    <div
      class="mx-auto flex h-full w-full max-w-7xl items-start gap-x-8"
      :class="[expandedView ? 'px-8 pt-10' : 'p-4']"
    >
      <main class="flex-1 h-full overflow-y-auto">
        <!-- Main area -->
        <form @submit.prevent>
          <div class="space-y-12">
            <div class="border-b border-gray-900/10 pb-12">
              <h2 class="text-base/7 font-semibold text-gray-900">
                Topic Permissions
              </h2>
              <p class="mt-1 text-sm/6 text-gray-600">
                Use permissions to customize who can do what in this channel.
              </p>

              <div class="mt-6 mx-2 flex flex-col">
                <SettingsToggleWithDescription
                  v-model="isPrivate"
                  :private-icon="true"
                  label="Private Topic"
                  description="By making a Topic private, only select members and roles will be able to view this Topic."
                />

                <div
                  v-if="privateTopic && !editableEveryonePlaceholder"
                  class="mt-6 flex flex-col rounded-lg bg-gray-50"
                >
                  <div class="flex p-3 items-center justify-between space-x-3">
                    <span class="font-medium text-gray-700"
                      >Who can access this Topic?</span
                    >
                    <button
                      type="button"
                      class="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      @click="weTopicSettingsStore.addPermissionMembers()"
                    >
                      {{
                        expandedView ? "Add members or roles" : "Add members"
                      }}
                    </button>
                  </div>
                  <ul
                    v-for="(
                      groupPermissions, targetType
                    ) in displayablePermissions"
                    :key="targetType"
                    class="p-3 flex flex-col gap-y-2"
                  >
                    <div>
                      {{
                        targetType === "DiscussionRole" ? "Roles" : "Members"
                      }}
                    </div>
                    <li
                      v-for="permission in groupPermissions"
                      :key="permission.id"
                    >
                      <div
                        v-if="targetType === 'DiscussionRole'"
                        class="p-2 flex items-center justify-between gap-x-2"
                      >
                        <div class="flex items-center gap-x-2">
                          <TagIcon
                            class="size-6 flex-none"
                            :class="[
                              weHubSettingsStore.colorFor(
                                weTopicSettingsStore.roleFor(permission),
                              ),
                            ]"
                          />
                          <div class="text-sm font-medium text-gray-500">
                            <template
                              v-if="permission.targetName === 'everyone'"
                              >@</template
                            >{{ permission.targetName }}
                          </div>
                        </div>
                        <button
                          v-tooltip="'Remove member'"
                          class="z-10"
                          type="button"
                          @click.stop="confirmRemove(permission)"
                        >
                          <XCircleIcon class="size-5 text-gray-700" />
                        </button>
                      </div>
                      <CompactChannelMemberListElement
                        v-else
                        :membership="weTopicSettingsStore.memberFor(permission)"
                        :removable="!permission.targetIsHubOwner"
                        :hub-owner="permission.targetIsHubOwner"
                        context="addMembersForm"
                        @remove="confirmRemove(permission)"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="border-b border-gray-900/10 pb-12">
              <h2 class="text-base/7 font-semibold text-gray-900">
                Advanced Permissions
              </h2>
              <TwoColumnPermissionDetails v-if="expandedView" />
              <CompactPermissionsList v-else />
            </div>
          </div>
        </form>
      </main>
    </div>
  </div>
</template>

<script setup>
import { TagIcon, XCircleIcon } from "@heroicons/vue/24/solid";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { useWePanelStore } from "@/stores/wePanel";
import { useModalStore } from "@/stores/modal";
import { useWeHubSettingsStore } from "@/stores/weHubSettings";
import { useWeTopicSettingsStore } from "@/stores/weTopicSettings";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import UnsavedChangesBanner from "@/components/we/settings-views/UnsavedChangesBanner.vue";
import CompactChannelMemberListElement from "@/components/we/channels-format/CompactChannelMemberListElement.vue";
import TwoColumnPermissionDetails from "@/components/we/settings-views/discussion-topic/TwoColumnPermissionDetails.vue";
import SettingsToggleWithDescription from "@/components/we/settings-views/SettingsToggleWithDescription.vue";
import CompactPermissionsList from "@/components/we/settings-views/discussion-topic/CompactPermissionsList.vue";
import _ from "lodash";

const wePanelStore = useWePanelStore();
const { expandedView, topicPermissionOverwrites } = storeToRefs(wePanelStore);
const weTopicSettingsStore = useWeTopicSettingsStore();
const {
  permissionUnsavedChanges,
  privateTopic,
  everyonePermission,
  editableEveryonePlaceholder,
  editablePermissionOverwrites,
  displayablePermissions,
} = storeToRefs(weTopicSettingsStore);
const weHubSettingsStore = useWeHubSettingsStore();
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const modalStore = useModalStore();
const { confirmationPayload } = storeToRefs(modalStore);

const isPrivate = ref(false);
const watchVModel = ref(true);

const placeholderViewChannels = computed(
  () => editableEveryonePlaceholder.value?.viewChannels,
);

watch(isPrivate, () => {
  if (watchVModel.value) {
    updatePrivateTopic();
  }
});

watch(placeholderViewChannels, () => {
  if (editableEveryonePlaceholder.value) {
    watchVModel.value = false;
    isPrivate.value = placeholderViewChannels.value === "deny" ? true : false;
    watchVModel.value = true;
  }
});

watch(editableEveryonePlaceholder, (val, oldVal) => {
  if (oldVal && !val) {
    console.log("placeholder watcher", editableEveryonePlaceholder.value);
    remount();
  }
});

onMounted(() => {
  remount();
});

onBeforeUnmount(() => {
  editablePermissionOverwrites.value = [];
});

async function remount() {
  weTopicSettingsStore.resetPermissions();
  isPrivate.value = privateTopic.value;
}

async function update() {
  weTopicSettingsStore.updatePermissions();
}

function cancel() {
  wePanelStore.closeBottomEdgeLevelTwoPanel();
}

function updatePrivateTopic() {
  const translated = isPrivate.value ? "deny" : "allow";

  if (everyonePermission.value) {
    const index = _.findIndex(editablePermissionOverwrites.value, {
      id: everyonePermission.value.id,
    });
    if (index !== -1) {
      const existingPermission = editablePermissionOverwrites.value[index];
      if (existingPermission) {
        const existingVal =
          editablePermissionOverwrites.value[index].viewChannels;
        if (existingVal !== translated) {
          editablePermissionOverwrites.value[index].viewChannels = translated;
        }
      }
    }
  }
}

function confirmRemove(permission) {
  if (weTopicSettingsStore.memberFor(permission)?.hubOwner === true) return;
  if (
    permission.targetType === "DiscussionRole" &&
    permission.targetName === "everyone"
  )
    return;

  const callback = async () => {
    await weTopicSettingsStore.deletePermissionOverwrite(permission);

    topicPermissionOverwrites.value = topicPermissionOverwrites.value.filter(
      ({ id }) => id !== permission.id,
    );
  };

  confirmationPayload.value = {
    title: `Delete Permission Settings`,
    message: `Are you sure you want to delete ${permission.targetName} permissions? This action cannot be undone.`,
    affirmText: "Delete",
    affirmCallback: callback,
  };
}
</script>
