<template>
  <h2 class="w-full p-2 flex items-center justify-between">
    <div class="flex items-center gap-0.5">
      <button v-if="!isDesktop" @click="collapsed = !collapsed">
        <component :is="showHideIcon" class="size-5 text-gray-700" />
      </button>
      <button
        class="text-gray-700 font-semibold text-sm"
        @click="collapsed = !collapsed"
      >
        {{ category.name }}
      </button>
      <button v-if="isDesktop" @click="collapsed = !collapsed">
        <component :is="showHideIcon" class="size-4 text-gray-700" />
      </button>
    </div>
    <button
      v-if="isDesktop && canManage"
      v-tooltip.top-end="'Create Topic'"
      type="button"
      class="inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
      @click="createTopic"
    >
      <PlusIcon class="size-5" />
    </button>
  </h2>
</template>

<script setup>
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
} from "@heroicons/vue/20/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { computed, markRaw } from "vue";
import DiscussionTopicForm from "@/components/we/channels-format/DiscussionTopicForm.vue";
import _ from "lodash";

const props = defineProps(["category"]);

const wePanelStore = useWePanelStore();
const { categories } = storeToRefs(wePanelStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const collapsed = computed({
  get() {
    return props.category.categoryCollapsed;
  },
  set(bool) {
    const storeCategory = _.find(categories.value, { id: props.category.id });

    if (storeCategory) {
      const newStatus = bool ? "collapsed" : "expanded";
      storeCategory.categoryCollapsed = bool;
      wePanelStore.patchTopicDisplayStatus(props.category.id, newStatus);
    }
  },
});
const showHideIcon = computed(() => {
  if (collapsed.value) {
    return markRaw(ChevronRightIcon);
  } else {
    return markRaw(ChevronDownIcon);
  }
});
const canManage = computed(() => true);

function createTopic() {
  wePanelStore.openSecondaryContentPanel({
    component: DiscussionTopicForm,
    props: {
      category: props.category,
    },
  });
}
</script>
