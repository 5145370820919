<template>
  <button
    type="button"
    class="p-1.5 rounded-md flex items-center justify-between gap-x-2 text-sm bg-gray-300 hover:bg-gray-200 text-gray-500 hover:text-gray-600"
    @click="emit('remove')"
  >
    <span>{{ name }}</span>
    <span class="sr-only">Remove</span>
    <XMarkIcon class="size-4" />
  </button>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/20/solid";

defineProps(["name"]);
const emit = defineEmits(["remove"]);
</script>
