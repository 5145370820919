import { ref } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { createChannel } from "@/channels/channel";
import { useUserStore } from "@/stores/user";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import _ from "lodash";

export const useWeChannelStore = defineStore("weChannel", () => {
  const userStore = useUserStore();
  const { currentUser, presence } = storeToRefs(userStore);
  const propertyFieldsStore = usePropertyFieldsStore();
  const WeChannel = ref(null);
  const weChannelDataQueue = ref([]);
  const userPresence = ref([]);
  const userAppearances = ref([]);

  function patchUserPresence(users) {
    userPresence.value = _.unionBy(users, userPresence.value, "token");
  }

  function pushAndTrim(data) {
    weChannelDataQueue.value.push(data);
    weChannelDataQueue.value = _.takeRight(weChannelDataQueue.value, 3);
  }

  function subscribe() {
    if (!WeChannel.value) {
      WeChannel.value = createChannel(
        {
          channel: "WeChannel",
          userId: currentUser.value.id,
        },
        {
          initialized() {
            this.update = this.update.bind(this);
          },
          connected() {
            this.install();
            this.update();
            presence.value = "here";
          },
          received(data) {
            if (data?.users) {
              patchUserPresence(data.users);
            }
            if (data?.propertyAppearances) {
              const userIds = _.uniq(data.users.map(({ id }) => id));
              userAppearances.value = userAppearances.value.filter(
                ({ userId }) => !_.includes(userIds, userId),
              );
              const propertyIds = _.uniq(
                data.propertyAppearances.map(({ hostId }) => hostId),
              );
              userAppearances.value.push(...data.propertyAppearances);

              for (const propertyId of propertyIds) {
                propertyFieldsStore.fetchPropertyDataField(propertyId);
              }
            }
            pushAndTrim(data);
          },
          update() {
            const active =
              document.visibilityState === "visible" && document.hasFocus();
            active ? this.appear() : this.away();
          },
          appear() {
            // Calls `appear(data)` on the server.
            this.perform("appear");
            presence.value = "here";
          },
          away() {
            // Calls `away` on the server.
            this.perform("away");
            presence.value = "away";
          },
          install() {
            window.addEventListener("focus", this.update);
            window.addEventListener("blur", this.update);
            document.addEventListener("visibilitychange", this.update);
          },

          uninstall() {
            window.removeEventListener("focus", this.update);
            window.removeEventListener("blur", this.update);
            document.removeEventListener("visibilitychange", this.update);
          },
        },
      );
    }
  }

  return {
    WeChannel,
    weChannelDataQueue,
    userPresence,
    userAppearances,
    subscribe,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWeChannelStore, import.meta.hot));
}
