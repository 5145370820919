<template>
  <div>
    <h3 class="font-medium leading-6 text-gray-900">Company Memberships</h3>
    <div class="mt-1 max-w-xl text-sm text-gray-500">
      <p>Companies you are linked to.</p>
    </div>
    <ul role="list" class="divide-y divide-gray-100">
      <li
        v-for="membership in companyMemberships"
        :key="membership.memberId"
        class="flex items-center justify-between gap-x-6 py-5"
      >
        <div class="min-w-0">
          <div class="flex items-start gap-x-3">
            <p class="text-sm/6 font-semibold text-gray-900">
              {{ membership.companyName }}
            </p>
            <p
              :class="[
                statuses[membership.status],
                'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
              ]"
            >
              {{ membership.status }}
            </p>
          </div>
          <div class="mt-1 flex items-center gap-x-2 text-xs/5 text-gray-500">
            <p class="whitespace-nowrap">
              From
              <time :datetime="membership.acceptedAt">{{
                formatDate(membership.acceptedAt)
              }}</time>
            </p>
            <template v-if="membership.endedAt">
              <svg viewBox="0 0 2 2" class="size-0.5 fill-current">
                <circle cx="1" cy="1" r="1" />
              </svg>
              <p class="truncate">
                Ended
                <time :datetime="membership.endedAt">{{
                  formatDate(membership.endedAt)
                }}</time>
              </p>
            </template>
          </div>
        </div>
        <div class="flex flex-none items-center gap-x-4">
          <a
            href=""
            :class="[
              'rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
              workspaceLayoutIsTopAndBottom ? 'block' : 'hidden',
            ]"
            @click.prevent="viewCompany(membership.companyId)"
            >View company<span class="sr-only"
              >, {{ membership.companyName }}</span
            ></a
          >
          <Menu
            v-if="membership.status !== 'Ended'"
            as="div"
            class="relative flex-none"
          >
            <MenuButton
              class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900"
            >
              <span class="sr-only">Open options</span>
              <EllipsisVerticalIcon class="size-5" aria-hidden="true" />
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
              >
                <MenuItem v-slot="{ active }">
                  <a
                    href=""
                    :class="[
                      active ? 'bg-gray-50 outline-none' : '',
                      'block px-3 py-1 text-sm/6 text-gray-900',
                    ]"
                    @click.prevent="endMembership(membership.memberId)"
                    >I'm no longer here<span class="sr-only"
                      >, at {{ membership.companyName }}</span
                    ></a
                  >
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { EllipsisVerticalIcon } from "@heroicons/vue/20/solid";
import { useUserStore } from "@/stores/user";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useRouter, useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";
import moment from "moment";
import { computed, onMounted } from "vue";

const userStore = useUserStore();
const { companyMemberships } = storeToRefs(userStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const workspaceLayoutIsTopAndBottom = computed(() => {
  return workspaceLayout.value === "topAndBottom";
});

const statuses = {
  Active: "text-green-700 bg-green-50 ring-green-600/20",
  Pending: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Ended: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
};

onMounted(() => {
  userStore.fetchCompanyMemberships();
});

const router = useRouter();
const route = useRoute();

function viewCompany(companyId) {
  router.push({
    name: "CompanyShell",
    params: { companyId },
    query: {
      ...route.query,
      propertyIds: undefined,
    },
  });
}

async function endMembership(membershipId) {
  const response = await api.delete(`company_memberships/${membershipId}`);

  companyMemberships.value = _.unionBy(
    [response.data],
    companyMemberships.value,
    "memberId",
  );
}

function formatDate(dateString) {
  return moment(dateString).format("MMMM DD, YYYY");
}
</script>
