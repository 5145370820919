<template>
  <div class="relative flex h-full w-full pt-10 overflow-hidden">
    <div
      class="w-[200px] flex-shrink-0 px-4 border-r border-gray-300 h-full flex flex-col space-y-2 overflow-y-auto"
    >
      <div class="w-full flex items-center justify-between">
        <a
          href=""
          class="flex items-center space-x-2 text-gray-500"
          @click.prevent="weHubSettingsStore.backLayeredComponent()"
        >
          <ArrowLeftIcon class="size-5" />
          <span class="uppercase">Back</span>
        </a>
        <a
          href=""
          class="flex items-center text-gray-500"
          @click.prevent="weHubSettingsStore.createRole()"
        >
          <PlusIcon class="size-5" />
        </a>
      </div>
      <ol class="flex-grow w-full flex flex-col gap-y-2 overflow-y-auto">
        <li v-for="role in roles" :key="role.id">
          <button
            class="w-full rounded-lg p-2 flex items-center space-x-2"
            :class="[selectedRoleId === role.id ? 'bg-gray-300' : '']"
            @click="selectedRoleId = role.id"
          >
            <div
              class="size-3 rounded-full bg-current"
              :class="[weHubSettingsStore.colorFor(role)]"
            />
            <div class="text-sm font-medium text-gray-500">
              <template v-if="role.name === 'everyone'">@</template
              >{{ role.name }}
            </div>
          </button>
        </li>
      </ol>
    </div>
    <div class="flex-grow flex flex-col px-4">
      <div class="flex items-center justify-between gap-x-4">
        <span class="text-gray-700 font-semibold uppercase"
          >Edit Role &mdash; {{ editableRole.name }}</span
        >
        <DiscussionRoleOptionsDropdown :role="editableRole" />
      </div>
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex justify-between space-x-8" aria-label="Tabs">
          <a
            v-for="tab in roleTabs"
            :key="tab.name"
            href=""
            :class="[
              selectedTab?.name === tab.name
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
              tab.name !== 'Permissions' && editableRole?.name === 'everyone'
                ? 'cursor-not-allowed'
                : '',
              'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-semibold',
            ]"
            :aria-current="selectedTab?.name === tab.name ? 'page' : undefined"
            @click.prevent="select(tab)"
            >{{ tab.name }}</a
          >
        </nav>
      </div>
      <div class="flex-grow overflow-y-auto">
        <component :is="selectedTab.component" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ArrowLeftIcon, PlusIcon } from "@heroicons/vue/24/outline";
import { useWeHubSettingsStore } from "@/stores/weHubSettings";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import DiscussionRoleOptionsDropdown from "@/components/we/settings-views/discussion-hub/discussion-role/DiscussionRoleOptionsDropdown.vue";


const weHubSettingsStore = useWeHubSettingsStore();
const { roles, selectedRoleId, editableRole, roleTabs } = storeToRefs(weHubSettingsStore);

const selectedTab = ref(weHubSettingsStore.defaultRoleTab());

watch(selectedRoleId, (id) => {
  if (!id) return;
  if (editableRole.value?.name !== "everyone") return;

  selectedTab.value = weHubSettingsStore.defaultRoleTab();
});

function select(tab) {
  if (tab.name !== "Permissions" && editableRole.value?.name === "everyone") return;

  selectedTab.value = tab;
}
</script>
