<template>
  <div class="flex items-center p-2 border-b border-gray-400 gap-2">
    <button v-if="!expandedView" type="button" @click="close">
      <ChevronLeftIcon class="h-5 w-5 text-gray-700" aria-hidden="true" />
    </button>
    <div class="flex-grow">
      <slot />
    </div>
    <button
      v-if="context?.location === 'levelTwo' && expandedView"
      type="button"
      @click="close"
    >
      <XMarkIcon class="h-5 w-5 text-gray-700" aria-hidden="true" />
    </button>
  </div>
</template>

<script setup>
import { ChevronLeftIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";

const props = defineProps(["context"]);

const wePanelStore = useWePanelStore();
const { expandedView } = storeToRefs(wePanelStore);

function close() {
  if (props.context?.location === "levelTwo") {
    wePanelStore.closeRightEdgeLevelTwoPanel();
  } else {
    wePanelStore.closeRightEdgePanel();
  }
}
</script>
