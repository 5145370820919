<template>
  <HeaderContainer>
    <header class="flex items-center justify-between gap-x-3">
      <div class="flex items-center gap-x-2">
        <span
          class="inline-block size-7 overflow-hidden rounded-full bg-gray-100"
        >
          <svg
            class="size-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
        </span>
        <span class="text-base font-semibold text-gray-900">
          {{ directMessage?.name || directMessage?.groupName }}
        </span>
      </div>
    </header>
  </HeaderContainer>
</template>

<script setup>
import HeaderContainer from "@/components/we/main-content/HeaderContainer.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const wePanelStore = useWePanelStore();
const { mainContent } = storeToRefs(wePanelStore);

const directMessage = computed(() => mainContent.value?.content);
</script>
