import { ref, computed, markRaw, watch, nextTick } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { useWePanelStore } from "@/stores/wePanel";
import { useModalStore } from "@/stores/modal";
import ComingSoon from "@/components/ComingSoon.vue";
import api from "@/router/api";
import _ from "lodash";
import {
  Bars4Icon,
  IdentificationIcon,
  KeyIcon,
  LinkIcon,
  NoSymbolIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/vue/20/solid";
import HubSettingsOverviewForm from "@/components/we/settings-views/discussion-hub/HubSettingsOverviewForm.vue";
import HubRolesList from "@/components/we/settings-views/discussion-hub/HubRolesList.vue";
import TwoColumnRoleDetails from "@/components/we/settings-views/discussion-hub/discussion-role/TwoColumnRoleDetails.vue";
import DiscussionRoleDisplaySettings from "@/components/we/settings-views/discussion-hub/discussion-role/DiscussionRoleDisplaySettings.vue";
import DiscussionRolePermissions from "@/components/we/settings-views/discussion-hub/discussion-role/DiscussionRolePermissions.vue";
import DiscussionRoleMembersList from "@/components/we/settings-views/discussion-hub/discussion-role/DiscussionRoleMembersList.vue";

export const useWeHubSettingsStore = defineStore("weHubSettings", () => {
  const modalStore = useModalStore();
  const { confirmationPayload } = storeToRefs(modalStore);
  const wePanelStore = useWePanelStore();
  const {
    activeHubId,
    activeOption,
    expandedView,
    activeHubRoles,
    rightEdgeLevelThreeComponent,
    rightEdgeLevelThreeProps,
    levelThreeMainContent,
    nearbyMessagesTab,
  } = storeToRefs(wePanelStore);
  const unsavedChangesNavigationGuard = ref(false);
  const overviewUnsavedChanges = computed(() => {
    const matchingTab = expandedView.value
      ? selectedTab.value?.name === "Overview"
      : true;
    const nameChange =
      _.trim(name.value) !== "" &&
      _.trim(name.value) !== activeOption.value.name;
    const descriptionChange =
      _.trim(description.value) !== "" &&
      _.trim(description.value) !==
        (activeOption.value?.matchContent?.description || "");
    const brandColorChange =
      brandColor.value !== activeOption.value?.matchContent?.brandColor;

    return matchingTab && (nameChange || descriptionChange || brandColorChange);
  });
  const unsavedChanges = computed(
    () => overviewUnsavedChanges.value || roleUnsavedChanges.value,
  );
  const name = ref("");
  const description = ref("");
  const brandColor = computed(() => selectedBrandColor.value.name);
  const brandColorOptions = ref([
    {
      name: "Steel",
      color: "text-gray-600",
      background: "from-gray-400 to-gray-600",
    },
    {
      name: "Pink",
      color: "text-pink-500",
      background: "from-pink-300 to-pink-500",
    },
    {
      name: "Purple",
      color: "text-purple-500",
      background: "from-purple-300 to-purple-500",
    },
    {
      name: "Blue",
      color: "text-blue-500",
      background: "from-blue-300 to-blue-500",
    },
    {
      name: "Green",
      color: "text-green-500",
      background: "from-green-300 to-green-500",
    },
    {
      name: "Yellow",
      color: "text-yellow-500",
      background: "from-yellow-300 to-yellow-500",
    },
  ]);

  const selectedBrandColor = ref(brandColorOptions.value[0]);

  const editableRoles = ref(_.cloneDeep(activeHubRoles.value));
  const membersToAdd = ref([]);
  const roles = computed(() => {
    return _.orderBy(editableRoles.value, ["order"], ["desc"]);
  });
  const everyoneRole = computed(() =>
    _.find(roles.value, { name: "everyone" }),
  );
  const sortableRoles = computed(() =>
    roles.value.filter(({ name }) => name !== "everyone"),
  );
  const roleUnsavedChanges = computed(() => {
    const matchingTab = expandedView.value
      ? selectedTab.value?.name === "Roles"
      : true;
    return matchingTab && !_.isEqual(editableRoles.value, activeHubRoles.value);
  });
  const selectedRoleId = ref(null);
  const editableRole = computed({
    get() {
      return _.find(editableRoles.value, { id: selectedRoleId.value }) || {};
    },
    set(newRole) {
      const index = _.findIndex(editableRoles.value, {
        id: selectedRoleId.value,
      });
      if (index !== -1) {
        editableRoles.value[index] = {
          ...editableRoles.value[index],
          ...newRole,
        };
      }
    },
  });

  function colorFor(role) {
    return (
      _.find(roleColorOptions.value, { name: role.color })?.color ||
      "text-gray-400"
    );
  }

  const roleColorOptions = ref([
    {
      name: "Default",
      color: "text-gray-400",
    },
    {
      name: "Teal",
      color: "text-teal-500",
    },
    {
      name: "Green",
      color: "text-green-500",
    },
    {
      name: "Blue",
      color: "text-blue-500",
    },
    {
      name: "Purple",
      color: "text-purple-500",
    },
    {
      name: "Pink",
      color: "text-pink-500",
    },
    {
      name: "Yellow",
      color: "text-yellow-500",
    },
    {
      name: "Bronze",
      color: "text-orange-400",
    },
    {
      name: "Orange",
      color: "text-orange-600",
    },
    {
      name: "Dry Stone",
      color: "text-slate-400",
    },
    {
      name: "Cool Stone",
      color: "text-cyan-800",
    },
  ]);

  const rolePermissionGroupings = ref([
    {
      name: "General Server Permissions",
      permissions: [
        "viewChannels",
        "manageChannels",
        "manageRoles",
        "manageHub",
      ],
    },
    {
      name: "Membership Permissions",
      permissions: [
        "createInvite",
        "kickApproveRejectMembers",
        "banMembers",
        "timeoutMembers",
      ],
    },
    {
      name: "Text Topic Permissions",
      permissions: [
        "sendMessages",
        "sendThreadMessages",
        "createPublicThreads",
        "createPrivateThreads",
        "embedLinks",
        "attachFiles",
        "mentionRoles",
        "manageMessages",
        "manageThreads",
        "readMessageHistory",
      ],
    },
    {
      name: "Apps Permissions",
      permissions: ["useCommands"],
    },
    {
      name: "Advanced Permissions",
      permissions: ["administrator"],
    },
  ]);

  const permissionCopy = ref({
    viewChannels: {
      label: "View Topics",
      description:
        "Allows members to view Topics by default (excluding private Topics).",
    },
    manageChannels: {
      label: "Manage Topics",
      description: "Allows members to create, edit, or delete Topics.",
    },
    manageRoles: {
      label: "Manage Hub Roles",
      description:
        "Allows members to create new roles and edit or delete roles lower than their highest role. Also allows members to change permissions of individual Topics that they have access to.",
    },

    manageHub: {
      label: "Manage Hub",
      description:
        "Allows members to change this Hub's name and related settings, as well as view all invites.",
    },

    createInvite: {
      label: "Create Invite",
      description: "Allows members to invite new people to this Hub.",
    },
    kickApproveRejectMembers: {
      label: "Kick, Approve, and Reject Members",
      description:
        "Kick will remove other members from this Hub. Kicked members will be able to rejoin if they have another invite. If the Hub enables Member Requirements, this permission enables the ability to approve or reject members who request to join.",
    },

    banMembers: {
      label: "Ban Members",
      description:
        "Allows members to permanently ban and delete the message history of other members from this Hub.",
    },

    timeoutMembers: {
      label: "Timeout Members",
      description:
        "When you put a user in timeout they will not be able to send messages in chat, reply within threads, or react to messages.",
    },

    sendMessages: {
      label: "Send Messages",
      description: "Allows members to send messages.",
    },
    sendThreadMessages: {
      label: "Send Messages in Threads",
      description: "Allows members to send messages in threads.",
    },
    createPublicThreads: {
      label: "Create Public Threads",
      description:
        "Allows members to create threads that everyone in a Topic can view.",
    },

    createPrivateThreads: {
      label: "Create Private Threads",
      description: "Allows members to create invite-only threads.",
    },
    embedLinks: {
      label: "Embed Links",
      description: "Allows links that members share to show embedded content.",
    },
    attachFiles: {
      label: "Attach Files",
      description: "Allows members to upload files.",
    },
    mentionRoles: {
      label: "Mention @everyone, @here, and All Roles",
      description:
        "Allows members to use @everyone (everyone in the Hub) or @here (only online members in that Topic). They can also @mention all roles, even if the role's 'Allow anyone to mention this role' permission is disabled.",
    },

    manageMessages: {
      label: "Manage Messages",
      description:
        "Allows members to delete messages by other members or star any message.",
    },

    manageThreads: {
      label: "Manage Threads",
      description:
        "Allows members to rename, delete, and close threads. They can also view private threads.",
    },

    readMessageHistory: {
      label: "Read Message History",
      description:
        "Allows members to read previous messages sent in Topics. If this permission is disabled, members only see messages sent when they are online. This does not fully apply to threads and Forum posts.",
    },

    useCommands: {
      label: "Use Commands",
      description:
        "Allows members to use commands from applications, including slash commands and context menu commands.",
    },

    administrator: {
      label: "Administrator",
      description:
        "Members with this permission will have every permission and will also bypass all Topic-specific permissions or restrictions (for example, these members would get access to all private Topics). This is a dangerous permission to grant.",
    },
  });

  const permissionTemplates = ref([
    {
      name: "Cosmetic",
      color: "indigo",
      primaryColorShade: 600,
      description:
        "Just for looks. Great for giving labels or color to members.",
      bullets: ["Shows up on their profile", "Colors their name"],
      permissions: {
        viewChannels: false,
        manageChannels: false,
        manageRoles: false,
        manageHub: false,
        createInvite: false,
        kickApproveRejectMembers: false,
        banMembers: false,
        timeoutMembers: false,
        sendMessages: false,
        sendThreadMessages: false,
        createPublicThreads: false,
        createPrivateThreads: false,
        embedLinks: false,
        attachFiles: false,
        mentionRoles: false,
        manageMessages: false,
        manageThreads: false,
        readMessageHistory: false,
        useCommands: false,
        administrator: false,
      },
    },
    {
      name: "Member",
      color: "green",
      primaryColorShade: 500,
      description: "Basic permissions for a regular member to talk.",
      bullets: ["Talk in Topics", "Invite friends"],
      permissions: {
        viewChannels: true,
        manageChannels: false,
        manageRoles: false,
        manageHub: false,
        createInvite: true,
        kickApproveRejectMembers: false,
        banMembers: false,
        timeoutMembers: false,
        sendMessages: true,
        sendThreadMessages: true,
        createPublicThreads: true,
        createPrivateThreads: true,
        embedLinks: true,
        attachFiles: true,
        mentionRoles: true,
        manageMessages: false,
        manageThreads: false,
        readMessageHistory: true,
        useCommands: true,
        administrator: false,
      },
    },
    {
      name: "Moderator",
      color: "yellow",
      primaryColorShade: 500,
      description:
        "People who can help you manage other members in this server.\n\nEverything members can do, and:",
      bullets: ["Delete any messages", "Kick/ban members", "Timeout members"],
      permissions: {
        viewChannels: true,
        manageChannels: false,
        manageRoles: false,
        manageHub: false,
        createInvite: true,
        kickApproveRejectMembers: true,
        banMembers: true,
        timeoutMembers: true,
        sendMessages: true,
        sendThreadMessages: true,
        createPublicThreads: true,
        createPrivateThreads: true,
        embedLinks: true,
        attachFiles: true,
        mentionRoles: true,
        manageMessages: true,
        manageThreads: false,
        readMessageHistory: true,
        useCommands: true,
        administrator: false,
      },
    },
    {
      name: "Managers",
      color: "red",
      primaryColorShade: 600,
      description:
        "Trusted leaders who can help you build the Hub.\n\nEverything moderators can do, and:",
      bullets: [
        "Create and delete Topics",
        "Create and delete roles",
        "Pretty much anything",
      ],
      permissions: {
        viewChannels: true,
        manageChannels: true,
        manageRoles: true,
        manageHub: true,
        createInvite: true,
        kickApproveRejectMembers: true,
        banMembers: true,
        timeoutMembers: true,
        sendMessages: true,
        sendThreadMessages: true,
        createPublicThreads: true,
        createPrivateThreads: true,
        embedLinks: true,
        attachFiles: true,
        mentionRoles: true,
        manageMessages: true,
        manageThreads: true,
        readMessageHistory: true,
        useCommands: true,
        administrator: false,
      },
    },
  ]);

  watch(
    activeHubRoles,
    () => {
      resetRoles();
    },
    { deep: true },
  );

  const notificationTypes = ref([
    {
      name: "Only Mentions",
      selected: true,
    },
    {
      name: "All Messages",
      selected: false,
    },
  ]);
  const selectedNotificationType = ref(notificationTypes.value[0]);

  const overviewTab = {
    name: "Overview",
    order: 0,
    grouping: "Settings",
    handler: () => {},
    component: markRaw(HubSettingsOverviewForm),
    icon: IdentificationIcon,
  };
  const channelsTab = {
    name: "Channels",
    order: 1,
    grouping: "Settings",
    handler: () => {},
    component: markRaw(ComingSoon),
    icon: Bars4Icon,
  };
  const membersTab = {
    name: "Members",
    order: 0,
    grouping: "User Management",
    handler: () => {},
    component: markRaw(ComingSoon),
    icon: UsersIcon,
  };
  const rolesTab = {
    name: "Roles",
    order: 1,
    grouping: "User Management",
    handler: () => {},
    component: markRaw(HubRolesList),
    icon: KeyIcon,
  };
  const invitesTab = {
    name: "Invites",
    order: 2,
    grouping: "User Management",
    handler: () => {},
    component: markRaw(ComingSoon),
    icon: LinkIcon,
  };
  const bansTab = {
    name: "Bans",
    order: 3,
    grouping: "User Management",
    handler: () => {},
    component: markRaw(ComingSoon),
    icon: NoSymbolIcon,
  };
  const deleteTab = {
    name: "Delete Hub",
    order: 0,
    grouping: "Confirmatory",
    handler: () => {
      const callback = () => {
        deleteHub();
      };
      confirmationPayload.value = {
        title: "Delete Hub",
        message:
          "Are you sure you want to delete this Hub? This will also delete any associated team. This action cannot be undone.",
        affirmText: "Delete",
        affirmCallback: callback,
      };
    },
    component: markRaw(ComingSoon),
    icon: TrashIcon,
  };

  const tabs = [
    overviewTab,
    channelsTab,
    membersTab,
    rolesTab,
    invitesTab,
    bansTab,
    deleteTab,
  ];
  const selectedTab = ref(overviewTab);
  const selectedTabLayeredComponents = ref([]);
  function addLayeredComponent(component) {
    selectedTabLayeredComponents.value.push(markRaw(component));
  }
  function removeLayeredComponent(component) {
    const index = selectedTabLayeredComponents.value.indexOf(
      markRaw(component),
    );
    if (index > -1) {
      selectedTabLayeredComponents.value.splice(index, 1);
    }
  }
  function backLayeredComponent() {
    selectedTabLayeredComponents.value.pop();

    if (selectedTabLayeredComponents.value.length === 0) {
      selectedRoleId.value = null;
    }
  }
  function resetLayeredComponents() {
    if (!expandedView.value) {
      wePanelStore.closeRightEdgeLevelThreePanel();
    }
    selectedTabLayeredComponents.value = [];
    selectedRoleId.value = null;
  }

  const primaryNavigationOptions = computed(() => {
    const groupings = [
      {
        name: "Settings",
        label: activeOption.value.name,
        order: 0,
      },
      {
        name: "User Management",
        label: "User Management",
        order: 1,
      },
      {
        name: "Confirmatory",
        label: "Confirmatory",
        order: 2,
      },
    ];
    const withTabs = groupings.map(({ name, label, order }) => {
      return {
        name,
        label,
        order,
        options: _.orderBy(
          tabs.filter((tab) => tab.grouping === name),
          ["order"],
          ["asc"],
        ),
      };
    });

    return _.orderBy(withTabs, ["order"], ["asc"]);
  });

  async function updateHubOverview() {
    if (activeHubId.value && overviewUnsavedChanges.value) {
      const payload = {
        name: _.trim(name.value),
        description: _.trim(description.value),
        brandColor: selectedBrandColor.value.name,
      };

      const hubResponse = await api.patch(
        `discussion_hubs/${activeHubId.value}`,
        payload,
      );

      wePanelStore.patchHub(hubResponse.data);
      activeOption.value.name = name.value;
      activeOption.value.matchContent = hubResponse.data;

      if (expandedView.value) {
        wePanelStore.closeSecondaryContentPanel();
      } else {
        wePanelStore.closeBottomEdgeLevelTwoPanel();
      }
      resetOverview();
    }
  }

  async function deleteHub() {
    if (activeHubId.value) {
      await api.delete(`discussion_hubs/${activeHubId.value}`);

      if (expandedView.value) {
        wePanelStore.closeSecondaryContentPanel();
      } else {
        wePanelStore.closeBottomEdgeLevelTwoPanel();
      }

      await nextTick();

      wePanelStore.dropHub(activeOption.value.matchContent);
      wePanelStore.selectActiveOption(nearbyMessagesTab.value);
      resetOverview();
    }
  }

  function resetOverview() {
    name.value = "";
    description.value = "";
    selectedBrandColor.value = brandColorOptions.value[0];
  }

  async function createRole() {
    const response = await api.post(
      `discussion_hubs/${activeHubId.value}/discussion_roles`,
    );

    if (response?.data) {
      wePanelStore.patchHubRoles([response.data]);
      viewRoleDetails(response.data);
    }
  }

  async function addRoleMembers() {
    const payload = {
      userIds: membersToAdd.value.map(({ userId }) => userId),
    };

    const response = await api.post(
      `discussion_hubs/${activeHubId.value}/discussion_roles/${editableRole.value.id}/role_members`,
      payload,
    );

    membersToAdd.value = [];
    return response?.data;
  }

  async function deleteRoleMember(membership) {
    const response = await api.delete(
      `discussion_hubs/${activeHubId.value}/discussion_roles/${editableRole.value.id}/role_members/${membership.memberId}`,
    );

    return response?.data;
  }

  async function deleteRole(role) {
    const callback = async () => {
      const response = await api.delete(
        `discussion_hubs/${activeHubId.value}/discussion_roles/${role.id}`,
      );

      if (response?.data) {
        resetLayeredComponents();
        wePanelStore.dropHubRole(role);
      }
    };

    confirmationPayload.value = {
      title: `Delete Role: ${role.name}`,
      message:
        "Are you sure you want to delete this role? This action cannot be undone.",
      affirmText: "Delete",
      affirmCallback: callback,
    };
  }

  async function updateRoles() {
    for (const role of editableRoles.value) {
      const payload = {
        name: role.name,
        color: role.color,
        order: role.order,
        hoist: role.hoist,
        mentionable: role.mentionable,
        viewChannels: role.viewChannels,
        manageChannels: role.manageChannels,
        manageRoles: role.manageRoles,
        manageHub: role.manageHub,
        createInvite: role.createInvite,
        kickApproveRejectMembers: role.kickApproveRejectMembers,
        banMembers: role.banMembers,
        timeoutMembers: role.timeoutMembers,
        sendMessages: role.sendMessages,
        sendThreadMessages: role.sendThreadMessages,
        createPublicThreads: role.createPublicThreads,
        createPrivateThreads: role.createPrivateThreads,
        embedLinks: role.embedLinks,
        attachFiles: role.attachFiles,
        mentionRoles: role.mentionRoles,
        manageMessages: role.manageMessages,
        manageThreads: role.manageThreads,
        readMessageHistory: role.readMessageHistory,
        useCommands: role.useCommands,
        administrator: role.administrator,
      };
      const response = await api.patch(
        `discussion_hubs/${activeHubId.value}/discussion_roles/${role.id}`,
        payload,
      );

      if (response?.data) {
        wePanelStore.patchHubRoles([response.data]);

        if (!expandedView.value) {
          wePanelStore.closeRightEdgeLevelThreePanel();
          resetLayeredComponents();
        }
      }
    }

    resetRoles();
  }

  const roleTabs = ref([
    {
      name: "Display",
      component: markRaw(DiscussionRoleDisplaySettings),
    },
    {
      name: "Permissions",
      component: markRaw(DiscussionRolePermissions),
    },
    {
      name: "Links",
      component: markRaw(ComingSoon),
    },
    {
      name: "Manage Members",
      component: markRaw(DiscussionRoleMembersList),
    },
  ]);

  function defaultRoleTab() {
    return _.find(roleTabs.value, {
      name: editableRole.value?.name === "everyone" ? "Permissions" : "Display",
    });
  }

  function viewRoleDetails(role = everyoneRole.value) {
    if (expandedView.value) {
      if (!selectedRoleId.value) {
        addLayeredComponent(TwoColumnRoleDetails);
      }

      selectedRoleId.value = role.id;
    } else {
      selectedRoleId.value = role.id;
      const actionableTab = defaultRoleTab()?.component;
      if (actionableTab) {
        addLayeredComponent(actionableTab);

        rightEdgeLevelThreeComponent.value = _.last(
          selectedTabLayeredComponents.value,
        );
        rightEdgeLevelThreeProps.value = {
          context: {
            location: "levelThree",
            scenario: "discussionHubRoleDetails",
          },
        };
        levelThreeMainContent.value = null;
      }
    }
  }

  function resetRoles() {
    editableRoles.value = _.cloneDeep(activeHubRoles.value);
  }

  function reset() {
    resetOverview();
    resetRoles();
    resetLayeredComponents();
    selectedRoleId.value = null;
  }

  return {
    unsavedChangesNavigationGuard,
    overviewUnsavedChanges,
    unsavedChanges,
    name,
    description,
    brandColorOptions,
    selectedBrandColor,
    roles,
    editableRoles,
    editableRole,
    membersToAdd,
    sortableRoles,
    selectedRoleId,
    roleColorOptions,
    colorFor,
    roleUnsavedChanges,
    rolePermissionGroupings,
    permissionCopy,
    permissionTemplates,
    notificationTypes,
    selectedTab,
    primaryNavigationOptions,
    updateHubOverview,
    resetOverview,
    createRole,
    addRoleMembers,
    deleteRoleMember,
    updateRoles,
    deleteRole,
    resetRoles,
    viewRoleDetails,
    defaultRoleTab,
    roleTabs,
    selectedTabLayeredComponents,
    addLayeredComponent,
    removeLayeredComponent,
    backLayeredComponent,
    resetLayeredComponents,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useWeHubSettingsStore, import.meta.hot),
  );
}
