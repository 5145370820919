<template>
  <div
    v-if="availableCollectibleCardCount > 0 && displayCardContainer"
    :class="
      context === 'panel'
        ? 'items-center mx-auto'
        : 'items-end'
    "
    class="h-full flex"
  >
    <CardContainer
      :context="context"
      :class="context === 'panel' ? 'py-3' : 'pt-5 pb-10'"
    />
  </div>

  <main
    v-else
    v-observe-visibility="{
      callback: _.debounce(fetchAvailableCollectibleCardCount, 1000),
    }"
    class="h-full relative flex flex-1 bg-cover bg-top sm:bg-top"
    style="
      background-image: url(&quot;https://assets.towerhunt.com/site/katie-moum-5FHv5nS7yGg-unsplash.jpg&quot;);
    "
  >
    <a
      href="https://unsplash.com/@katiemoum"
      target="_blank"
      class="absolute px-2 py-1 bottom-0 left-0 flex items-center space-x-1 text-[9px] text-gray-500 underline"
      >Source: Katie Moum via Unsplash</a
    >
    <div class="mx-auto max-w-7xl px-4 py-16 text-center">
      <h1 class="mt-4 text-4xl font-bold tracking-tight text-gray-500">
        You're all caught up for now.
      </h1>
      <p class="mt-4 text-lg font-medium text-black text-opacity-50">
        Keep exploring to discover new intel.
      </p>
      <div class="mt-6 flex flex-col items-center gap-4">
        <button
          @click="goToWe"
          type="button"
          class="w-36 inline-flex items-center justify-center rounded-md border border-transparent bg-gray-800 bg-opacity-75 px-4 py-2 text-sm font-medium text-white text-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
        >
          Go to We
        </button>
        <button
          @click="goToTasks"
          type="button"
          class="w-36 inline-flex items-center justify-center rounded-md border border-transparent bg-white bg-opacity-75 px-4 py-2 text-sm font-medium text-black text-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
        >
          Go to Tasks
        </button>
      </div>
    </div>
  </main>
</template>

<script setup>
import CardContainer from "@/components/collectible-cards/CardContainer.vue";
import { useUserStore } from "@/stores/user";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useMobileNavigationStore } from "@/stores/mobileNavigation";
import { useCollectibleCardsChannelStore } from "@/stores/collectibleCardsChannel";
import { ref, watch, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";

defineProps(["context"]);

const userStore = useUserStore();
const { availableCollectibleCardCount } = storeToRefs(userStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const mobileNavigationStore = useMobileNavigationStore();
const { selectedMobileTab } = storeToRefs(mobileNavigationStore);
const collectibleCardsChannelStore = useCollectibleCardsChannelStore();
const { collectibleCardsChannelDataQueue } = storeToRefs(
  collectibleCardsChannelStore,
);

const displayCardContainer = ref(true);

async function fetchAvailableCollectibleCardCount(isVisible) {
  if (isVisible) {
    userStore.fetchAvailableCollectibleCardCount();
  }
}

watch(collectibleCardsChannelDataQueue, async () => {
  const data = _.last(collectibleCardsChannelDataQueue.value);
  const variant = data?.variant;

  if (variant === "jobLocked") {
    displayCardContainer.value = false;

    await nextTick();

    displayCardContainer.value = true;
  }
});

const router = useRouter();
const route = useRoute();

function goToWe() {
  if (isDesktop.value) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "We",
        verticalTab: "Nearby",
      },
    });
  } else {
    selectedMobileTab.value = "We";
  }
}
function goToTasks() {
  if (isDesktop.value) {
    router.push({
      name: route.name,
      query: {
        ...route.query,
        horizontalTab: "Me",
        verticalTab: "Tasks",
      },
    });
  } else {
    selectedMobileTab.value = "Tasks";
  }
}
</script>
