<template>
  <div class="h-full flex flex-col px-2">
    <h2
      class="w-full p-2 flex items-center justify-between border-b border-gray-400"
    >
      <div class="text-gray-700 font-semibold text-sm">
        {{ networkTab }} &mdash; {{ focalNetworkings.length }}
      </div>
    </h2>
    <div
      v-if="focalNetworkings.length === 0"
      class="mt-8 p-8 flex flex-col items-center gap-y-4"
    >
      <UsersIcon class="size-10 text-gray-500" />
      <h3 class="text-center text-sm text-gray-600">
        People make real estate come alive.
      </h3>
    </div>
    <ol class="flex-grow overflow-y-auto">
      <NetworkingListElement
        v-for="networking in sortedNetworkings"
        :key="networking"
        :networking="networking"
      />
      <InfiniteLoading
        v-if="networkingsPagy && networkingsPagy.next"
        @infinite="async ($state) => wePanelStore.loadNetworkings($state)"
      />
    </ol>
  </div>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { UsersIcon } from "@heroicons/vue/24/outline";
import NetworkingListElement from "@/components/we/direct-messages/NetworkingListElement.vue";
import { computed } from "vue";
import _ from "lodash";

const wePanelStore = useWePanelStore();
const { networkTab, focalNetworkings, networkingsPagy } =
  storeToRefs(wePanelStore);

const sortedNetworkings = computed(() =>
  _.sortBy(focalNetworkings.value, [
    function (n) {
      const contact = wePanelStore.networkingCounterpart(n);
      return contact.linkedContactDataField?.fieldContent?.name || contact.name;
    },
  ]),
);
</script>
