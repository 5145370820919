<template>
  <form
    class="p-4 h-full flex flex-col"
    @submit.prevent="weDiscussionTopicFormStore.save()"
  >
    <div
      class="px-1 pb-4 flex-grow overflow-y-auto border-b border-gray-900/10"
    >
      <h2 class="text-base/6 font-semibold text-gray-900">
        Create Discussion Topic
      </h2>
      <p v-if="category" class="text-xs/5 text-gray-600">
        in {{ category.name }}
      </p>

      <div class="mt-4 flex flex-col gap-y-4">
        <div class="">
          <label
            for="topic-type"
            class="block text-sm/6 font-medium text-gray-900"
            >Topic Type</label
          >
          <fieldset
            aria-label="Topic Type"
            class="mt-2 -space-y-px rounded-md bg-white"
          >
            <label
              v-for="topic in topicTypes"
              :key="topic.name"
              :value="topic.name"
              :aria-label="topic.name"
              :aria-description="topic.description"
              class="group flex space-x-2 cursor-pointer border border-gray-200 p-4 first:rounded-tl-md first:rounded-tr-md last:rounded-bl-md last:rounded-br-md focus:outline-none has-[:checked]:relative has-[:checked]:border-indigo-200 has-[:checked]:bg-indigo-50"
            >
              <input
                name="topic-type"
                :value="topic"
                type="radio"
                :checked="topic.selected"
                class="relative mt-0.5 size-4 shrink-0 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                @change="weDiscussionTopicFormStore.setSelectedType(topic)"
              />
              <component :is="topic.icon" class="size-5" />
              <span class="flex flex-col">
                <span
                  class="block text-sm font-medium text-gray-900 group-has-[:checked]:text-indigo-900"
                  >{{ topic.name }}</span
                >
                <span
                  class="block text-xs text-gray-500 group-has-[:checked]:text-indigo-700"
                  >{{ topic.description }}</span
                >
              </span>
            </label>
          </fieldset>
        </div>

        <div class="">
          <label for="name" class="block text-sm/6 font-medium text-gray-900"
            >Topic Name</label
          >
          <div class="mt-2 grid grid-cols-1">
            <input
              id="name"
              v-model="topicName"
              v-focus
              type="text"
              name="name"
              class="col-start-1 row-start-1 block w-full rounded-md bg-white py-1.5 pl-10 pr-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:pl-9 sm:text-sm/6"
              placeholder="new-topic"
            />
            <component
              :is="selectedTopicType.icon"
              class="pointer-events-none col-start-1 row-start-1 ml-3 size-5 self-center text-gray-500 sm:size-4"
              aria-hidden="true"
            />
          </div>
        </div>

        <div>
          <SwitchGroup as="div" class="flex items-center justify-between">
            <span class="flex grow flex-col">
              <SwitchLabel
                as="div"
                class="flex items-center space-x-1 text-sm/6 font-medium text-gray-900"
                passive
              >
                <LockClosedIcon class="size-5" />
                <span>Private Topic</span>
              </SwitchLabel>
              <SwitchDescription as="span" class="text-sm text-gray-500"
                >Only selected members and roles will be able to view this
                topic.</SwitchDescription
              >
            </span>
            <Switch
              v-model="privateTopic"
              :class="[
                privateTopic ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
              ]"
            >
              <span class="sr-only">Private topic</span>
              <span
                :class="[
                  privateTopic ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none relative inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                ]"
              >
                <span
                  :class="[
                    privateTopic
                      ? 'opacity-0 duration-100 ease-out'
                      : 'opacity-100 duration-200 ease-in',
                    'absolute inset-0 flex size-full items-center justify-center transition-opacity',
                  ]"
                  aria-hidden="true"
                >
                  <svg
                    class="size-3 text-gray-400"
                    fill="none"
                    viewBox="0 0 12 12"
                  >
                    <path
                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span
                  :class="[
                    privateTopic
                      ? 'opacity-100 duration-200 ease-in'
                      : 'opacity-0 duration-100 ease-out',
                    'absolute inset-0 flex size-full items-center justify-center transition-opacity',
                  ]"
                  aria-hidden="true"
                >
                  <svg
                    class="size-3 text-indigo-600"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path
                      d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                    />
                  </svg>
                </span>
              </span>
            </Switch>
          </SwitchGroup>
        </div>
      </div>
    </div>

    <div class="pt-4 flex items-center justify-end gap-x-6">
      <button
        type="button"
        class="text-sm/6 font-semibold text-gray-900"
        @click="cancel"
      >
        Cancel
      </button>
      <button
        v-if="privateTopic"
        type="button"
        :disabled="!canSave"
        class="rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        :class="[
          canSave
            ? 'bg-indigo-600 text-white hover:bg-indigo-500'
            : 'bg-indigo-400 text-gray-100 cursor-not-allowed',
        ]"
        @click="addMembers"
      >
        Next
      </button>
      <button
        v-else
        type="submit"
        :disabled="!canSave"
        class="rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        :class="[
          canSave
            ? 'bg-indigo-600 text-white hover:bg-indigo-500'
            : 'bg-indigo-400 text-gray-100 cursor-not-allowed',
        ]"
      >
        Create Topic
      </button>
    </div>
  </form>
</template>

<script setup>
import {
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import { LockClosedIcon } from "@heroicons/vue/24/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { useWeDiscussionTopicFormStore } from "@/stores/weDiscussionTopicForm";
import DiscussionAddMembersForm from "./DiscussionAddMembersForm.vue";

const props = defineProps(["category"]);

const wePanelStore = useWePanelStore();
const weDiscussionTopicFormStore = useWeDiscussionTopicFormStore();
const {
  parentCategory,
  topicName,
  privateTopic,
  topicTypes,
  selectedTopicType,
  canSave,
} = storeToRefs(weDiscussionTopicFormStore);

onMounted(() => {
  if (!parentCategory.value) parentCategory.value = props.category;
});

function cancel() {
  wePanelStore.closeSecondaryContentPanel();
  weDiscussionTopicFormStore.reset();
}

function addMembers() {
  if (canSave.value) {
    wePanelStore.openSecondaryContentPanel({
      component: DiscussionAddMembersForm,
      props: {
        context: "DiscussionTopic",
      },
    });
  }
}
</script>
