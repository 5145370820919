<template>
  <ol class="overflow-y-auto space-y-4">
    <WeUserMessage
      v-for="message in messages"
      :key="message"
      :message="message"
    />
  </ol>
</template>

<script setup>
import { computed } from "vue";
import _ from "lodash";
import WeUserMessage from "@/components/we/message-output/WeUserMessage.vue";

const props = defineProps(["context"]);

const messages = computed(() => _.times(50));
</script>
