// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log("Vite ⚡️ Rails");

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log(
//   "Visit the guide for more information: ",
//   "https://vite-ruby.netlify.app/guide/rails"
// );

import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "@/entrypoints/App.vue";
import router from "@/router";
import FloatingVue from "floating-vue";
import KeyboardShortcuts from "vue-three-shortkey";
import VueObserveVisibilityPlugin from "vue-observe-visibility";
import CodeDiff from "v-code-diff";
import InfiniteLoading from "v3-infinite-loading";
import PrimeVue from 'primevue/config';
import "v3-infinite-loading/lib/style.css";
import { filters } from "@/assets/vueFilters";
import mask from "@/components/maps/fog/mask";
import _ from "lodash";

import "@/assets/base.css";
import "floating-vue/dist/style.css";

const app = createApp(App);
const pinia = createPinia();

mask();
app.use(pinia);
app.use(router);
app.use(FloatingVue, {
  themes: {
    instant: {
      $extend: "menu",
      delay: {
        show: 0,
        hide: 0,
      },
    },
    "quick-menu": {
      $extend: "menu",
      delay: {
        show: 300,
        hide: 100,
      },
    },
    "stick-menu": {
      $extend: "menu",
      autoHide: false,
      delay: {
        show: 300,
      },
    },
    "diagram-popup": {
      $extend: "menu",
      autoHide: false,
      delay: {
        show: 1000,
      },
    },
  },
});
app.use(PrimeVue, { unstyled: true });
app.use(KeyboardShortcuts, { prevent: ["input", "textarea", ".message-editor"] });
app.use(VueObserveVisibilityPlugin);
app.use(CodeDiff);
app.component("InfiniteLoading", InfiniteLoading);
app.directive("focus", {
  mounted: (el) => el.focus(),
});

app.config.globalProperties.$filters = _.merge({}, filters);

document.addEventListener("DOMContentLoaded", () => {
  const el = document.body.appendChild(document.createElement("div"));

  app.mount(el);
});

export default app;
