<template>
  <fieldset aria-label="Discussion Hub Type Selection">
    <legend class="text-sm/6 font-semibold text-gray-900">
      Choose a Team Type
    </legend>
    <RadioGroup v-model="internalModelValue" class="space-y-4">
      <RadioGroupOption
        v-for="option in membershipOptions"
        :key="option.value"
        v-slot="{ active, checked }"
        as="template"
        :value="option"
        :aria-label="option.label"
      >
        <div
          :class="[
            active
              ? 'border-indigo-600 ring-2 ring-indigo-600'
              : 'border-gray-300',
            'relative cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none',
            workspaceLayoutIsTopAndBottom ? 'flex justify-between' : 'block',
          ]"
        >
          <span class="flex items-center">
            <span class="pointer-events-none flex flex-col text-sm">
              <DataField
                v-if="option.dataField"
                :data-field="option.dataField"
                text-classes="text-sm font-medium max-w-[325px]"
                :text-wrap="true"
                dropdown-placement="left-start"
                text-styles=""
              />
              <span v-else class="font-medium text-gray-900">{{
                option.label
              }}</span>
              <span v-if="option.description" class="text-gray-500">
                <span class="block whitespace-nowrap">{{
                  option.description
                }}</span>
              </span>
              <span v-else class="text-gray-500"> Join an Existing Team </span>
            </span>
          </span>
          <span
            :class="[
              active ? 'border' : 'border-2',
              checked ? 'border-indigo-600' : 'border-transparent',
              'pointer-events-none absolute -inset-px rounded-lg',
            ]"
            aria-hidden="true"
          />
        </div>
      </RadioGroupOption>
    </RadioGroup>
  </fieldset>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useUserStore } from "@/stores/user";
import { ref, computed, onMounted, watch, watchEffect } from "vue";
import { RadioGroup, RadioGroupOption } from "@headlessui/vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps({
  modelValue: {
    type: Object,
    default: null,
  },
});
const emits = defineEmits(["update:modelValue"]);
const userStore = useUserStore();
const { companyMemberships } = storeToRefs(userStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

const internalModelValue = ref(props.modelValue);
const workspaceLayoutIsTopAndBottom = computed(() => {
  return workspaceLayout.value === "topAndBottom";
});

const createOwnOption = {
  value: "create-own",
  label: "Create My Own",
  description: "Start a new team",
};

const companyData = ref({});

const membershipOptions = computed(() => {
  return [
    ...companyMemberships.value
      .filter((membership) => !membership.linkedDiscussionHub)
      .map((membership) => {
        const dataField = companyData.value[membership.companyId] || {};

        return {
          value: membership.memberId,
          label: membership.companyName,
          description: "Linked company team",
          dataField,
        };
      }),
    createOwnOption,
  ];
});

async function fetchCompanyDataField(id) {
  if (id) {
    try {
      const response = await api.get(`companies/${_.toNumber(id)}`);
      companyData.value[id] = response.data;
    } catch (error) {
      console.error(`Error fetching company data for id ${id}:`, error);
    }
  }
}

watchEffect(() => {
  emits("update:modelValue", internalModelValue.value);
});

watch(
  () => props.modelValue,
  (newValue) => {
    internalModelValue.value = newValue;
  },
);

onMounted(async () => {
  await userStore.fetchCompanyMemberships();
  const promises = companyMemberships.value.map((membership) =>
    fetchCompanyDataField(membership.companyId),
  );
  await Promise.all(promises);

  internalModelValue.value = membershipOptions.value[0];
});
</script>
