<template>
  <VDropdown class="z-10">
    <button
      type="button"
      class="rounded-full bg-gray-200 p-2 text-gray-700 shadow-sm hover:bg-gray-300 hover:text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
    >
      <EllipsisHorizontalIcon class="size-5" aria-hidden="true" />
    </button>
    <template #popper>
      <ol class="flex flex-col text-sm font-semibold">
        <li>
          <button
            class="p-2 flex items-center justify-between gap-2 text-gray-700 hover:text-gray-900 hover:bg-gray-100"
          >
            <div>View Hub as Role</div>
            <ArrowRightIcon class="size-5" aria-hidden="true" />
          </button>
        </li>
        <li>
          <button
            class="w-full p-2 flex items-center justify-between gap-2 text-red-600 hover:text-red-700 hover:bg-gray-100"
            @click.stop="weHubSettingsStore.deleteRole(role)"
          >
            <div>Delete</div>
            <TrashIcon class="size-5" aria-hidden="true" />
          </button>
        </li>
      </ol>
    </template>
  </VDropdown>
</template>

<script setup>
import { TrashIcon } from "@heroicons/vue/20/solid";
import {
  ArrowRightIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/vue/24/outline";
import { useWeHubSettingsStore } from "@/stores/weHubSettings";

defineProps(["role"]);

const weHubSettingsStore = useWeHubSettingsStore();
</script>
