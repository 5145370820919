<template>
  <div class="h-full flex flex-col divide-y divide-gray-300">
    <ol class="w-full px-2 py-1">
      <li>
        <a
          href=""
          class="px-1 py-2 rounded-md flex items-center space-x-2 text-gray-700 font-medium hover:bg-gray-300 hover:text-gray-600"
          :class="[wePanelStore.activeSelectionOptionClass('Network')]"
          @click.prevent="viewNetwork"
        >
          <UserGroupIcon class="size-5" />
          <span>Network</span>
        </a>
      </li>
    </ol>
    <ol class="relative flex-grow w-full py-1 overflow-y-auto">
      <h2 class="w-full p-2 flex items-center justify-between">
        <div class="text-gray-700 font-semibold text-sm">Direct Messages</div>
        <PopulateDirectMessageDropdown v-if="expandedView">
          <template #button>
            <button
              v-tooltip.top-end="'Create DM'"
              type="button"
              class="inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
              <PlusIcon class="size-5" />
            </button>
          </template>
        </PopulateDirectMessageDropdown>
        <button
          v-else-if="isDesktop"
          v-tooltip.top-end="'Create DM'"
          type="button"
          class="inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          @click="wePanelStore.populateGroupDirectMessage()"
        >
          <PlusIcon class="size-5" />
        </button>
      </h2>
      <div
        v-if="directMessages.length === 0"
        class="mt-8 p-8 flex flex-col items-center gap-y-4"
      >
        <ChatBubbleOvalLeftIcon class="size-10 text-gray-500" />
        <h3 class="text-center text-sm text-gray-600">
          DMs are invite-only discussions with named users.
        </h3>
        <button
          type="button"
          class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          @click="wePanelStore.populateGroupDirectMessage()"
        >
          Create DM
        </button>
      </div>
      <DirectMessageListElement
        v-for="dm in directMessages"
        :key="dm"
        :direct-message="dm"
      />
      <div v-if="!isDesktop" class="absolute bottom-6 right-6">
        <button
          type="button"
          class="rounded-full bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="wePanelStore.populateGroupDirectMessage()"
        >
          <SolidChatBubbleIcon class="size-8" aria-hidden="true" />
        </button>
      </div>
    </ol>
  </div>
</template>

<script setup>
import { UserGroupIcon, PlusIcon } from "@heroicons/vue/20/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { markRaw, onActivated, onMounted } from "vue";
import DirectMessageListElement from "@/components/we/direct-messages/DirectMessageListElement.vue";
import PopulateDirectMessageDropdown from "@/components/we/direct-messages/PopulateDirectMessageDropdown.vue";
import NetworkContainer from "@/components/we/main-content-views/NetworkContainer.vue";
import { ChatBubbleOvalLeftIcon } from "@heroicons/vue/24/outline";
import { ChatBubbleOvalLeftIcon as SolidChatBubbleIcon } from "@heroicons/vue/20/solid";

const wePanelStore = useWePanelStore();
const { expandedView, directMessages, rightEdgeComponent, mainContent } =
  storeToRefs(wePanelStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

onMounted(() => {
  if (expandedView.value) {
    viewNetwork();
  }
});

onActivated(() => {
  wePanelStore.fetchDirectMessages();
});

function viewNetwork() {
  rightEdgeComponent.value = markRaw(NetworkContainer);
  mainContent.value = "Network";
}
</script>
