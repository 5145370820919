<template>
  <main class="relative h-full mx-auto w-3/4 px-12">
    <UnsavedChangesBanner
      v-if="tabUnsavedChanges"
      :changes="tabUnsavedChanges"
      @reset="remount"
      @save="update"
    />
    <div class="absolute top-2 right-0 flex flex-col items-center">
      <button
        v-shortkey="['esc']"
        type="button"
        class="rounded-full p-2 flex items-center justify-center border border-gray-900 text-gray-900 shadow-sm hover:text-gray-700"
        @shortkey="cancel"
        @click="cancel"
      >
        <XMarkIcon class="size-6" aria-hidden="true" />
      </button>
      <h2 class="uppercase font-semibold text-gray-900">ESC</h2>
    </div>
    <div class="h-full grid gap-x-5" style="grid-template-columns: 1fr 4fr">
      <aside class="h-full w-full overflow-y-auto space-y-4">
        <section
          v-for="(tab, index) in primaryNavigationOptions"
          :key="tab.name"
          :class="[index === 0 ? 'pt-10' : '']"
        >
          <h2 v-if="tab.label !== 'Confirmatory'" class="text-sm uppercase font-semibold text-gray-500">
            {{ tab.label }}
          </h2>
          <a
            v-for="option in tab.options"
            :key="option.name"
            href=""
            :class="[
              selectedTab.name === option.name
                ? 'bg-gray-50 text-indigo-600 hover:bg-white'
                : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
              'group flex items-center rounded-md px-3 py-2 text-sm font-medium',
            ]"
            :aria-current="
              selectedTab.name === option.name ? 'page' : undefined
            "
            @click.prevent="attemptTabChange(option)"
          >
            <component
              :is="option.icon"
              :class="[
                selectedTab.name === option.name
                  ? 'text-indigo-500'
                  : 'text-gray-400 group-hover:text-gray-500',
                '-ml-1 mr-3 size-6 shrink-0',
              ]"
              aria-hidden="true"
            />
            <span class="truncate">{{ option.name }}</span>
          </a>
        </section>
      </aside>

      <!-- component -->
      <component :is="effectiveComponent" class="h-full w-full" />
    </div>
  </main>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { storeToRefs } from "pinia";
import { useWeHubSettingsStore } from "@/stores/weHubSettings";
import { useWePanelStore } from "@/stores/wePanel";
import UnsavedChangesBanner from "@/components/we/settings-views/UnsavedChangesBanner.vue";
import _ from "lodash";
import { computed, onMounted } from "vue";

const wePanelStore = useWePanelStore();
const weHubSettingsStore = useWeHubSettingsStore();
const {
  unsavedChangesNavigationGuard,
  unsavedChanges,
  roleUnsavedChanges,
  primaryNavigationOptions,
  selectedTab,
  selectedTabLayeredComponents,
} = storeToRefs(weHubSettingsStore);

const effectiveComponent = computed(() => {
  if (selectedTabLayeredComponents.value.length > 0) {
    const raw = _.last(selectedTabLayeredComponents.value);

    return raw;
  } else {
    return selectedTab.value?.component;
  }
});

const tabUnsavedChanges = computed(() => {
  switch (selectedTab.value?.name) {
    case "Roles":
      return roleUnsavedChanges.value;
    default:
      return false;
  }
});

onMounted(() => {
  remount();
});

function cancel() {
  if (unsavedChanges.value) {
    unsavedChangesNavigationGuard.value = true;
  } else {
    wePanelStore.closeSecondaryContentPanel();
    weHubSettingsStore.reset();
  }
}

function attemptTabChange(newTab) {
  if (unsavedChanges.value) {
    unsavedChangesNavigationGuard.value = true;
  } else if (newTab.grouping === "Confirmatory") {
    newTab.handler();
  } else {
    selectedTab.value = newTab;
    weHubSettingsStore.resetLayeredComponents();
  }
}

async function remount() {
  switch (selectedTab.value?.name) {
    case "Roles":
      weHubSettingsStore.resetRoles();
      break;
    default:
      break;
  }
}

async function update() {
  switch (selectedTab.value?.name) {
    case "Roles":
      weHubSettingsStore.updateRoles();
      break;
    default:
      break;
  }
}
</script>
