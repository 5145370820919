<template>
  <ol class="h-full overflow-y-auto space-y-3">
    <li v-if="topLevelChannels.length > 0" class="pt-2 space-y-1">
      <ChannelListElement
        v-for="channel in topLevelChannels"
        :key="channel"
        :channel="channel"
      />
    </li>
    <li v-for="category in activeCategories" :key="category.id">
      <ChannelCategory :category="category" />
      <ChannelListElement
        v-for="channel in channelsFor(category)"
        :key="channel"
        :channel="channel"
      />
    </li>
  </ol>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import ChannelListElement from "@/components/we/channels-format/ChannelListElement.vue";
import ChannelMessagesContainer from "@/components/we/main-content-views/ChannelMessagesContainer.vue";
import ChannelCategory from "./ChannelCategory.vue";
import { computed, markRaw, nextTick, onMounted, watch } from "vue";

const wePanelStore = useWePanelStore();
const {
  expandedView,
  activeHubId,
  activeCategories,
  channels,
  directHubChannels,
  rightEdgeComponent,
  rightEdgeLevelTwoComponent,
  mainContent,
} = storeToRefs(wePanelStore);


const topLevelChannels = computed(() =>
  directHubChannels.value.filter(
    (channel) => channel.hubId === activeHubId.value,
  ),
);
const hubChannels = computed(() =>
  channels.value.filter(
    (channel) =>
      channel.hubId === activeHubId.value && channel.contentType !== "category",
  ),
);

watch(activeHubId, (val, oldVal) => {
  if (val && oldVal && val !== oldVal) {
    rehydrate();
  }
});

onMounted(() => {
  rehydrate();
});

async function rehydrate() {
  await wePanelStore.fetchTopics();
  await nextTick();
  focusFirstChannel();
  wePanelStore.fetchHubMembers();
  wePanelStore.fetchHubRoles();
}

function channelsFor(category) {
  return channels.value.filter((channel) => channel.categoryId === category.id);
}

function focusFirstChannel() {
  if (!expandedView.value) return;
  if (hubChannels.value.length > 0) {
    rightEdgeComponent.value = markRaw(ChannelMessagesContainer);
    mainContent.value = {
      contentType: "channel",
      content: hubChannels.value[0],
    };
    if (!rightEdgeLevelTwoComponent.value) {
      wePanelStore.toggleChannelMembersList();
    }
  }
}
</script>
