<template>
  <div class="p-0.5 flex flex-wrap items-center gap-1 w-full">
    <div v-for="(item, index) in selectedItems" :key="index" class="shrink-0">
      <component
        :is="chipComponent"
        :name="item.name"
        @remove="removeItem(item)"
      />
    </div>
    <input
      ref="searchInput"
      v-model="searchQuery"
      type="text"
      class="flex-grow p-1 text-base text-gray-900 placeholder:text-gray-400 border-none focus:ring-0 sm:text-sm/6"
      :placeholder="placeholder"
      @keydown="handleKeyDown"
    />
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";

const props = defineProps({
  selectedItems: {
    type: Array,
    default: () => [],
  },
  chipComponent: {
    type: Object,
    required: true,
  },
  placeholder: {
    type: String,
    default: "Search...",
  },
});

const emit = defineEmits(["update:modelValue", "remove-item"]);

const searchInput = ref(null);
const searchQuery = ref("");

watchEffect(() => {
  emit("update:modelValue", searchQuery.value);
});

const removeItem = (item) => {
  emit("remove-item", item);
};

const handleKeyDown = (event) => {
  if (event.key === 'Backspace' && searchQuery.value === '') {
    if (props.selectedItems.length > 0) {
      removeItem(props.selectedItems.slice(-1)[0]); //Remove last item
    }
  }
};
</script>
