<template>
  <div
    :id="`parcel-map-${mapId}`"
    v-observe-visibility="{ callback: mountMap }"
  />
</template>

<script setup>
/* global L */
import mapStyles from "@/components/maps/mapStyles";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from "vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { useTasksStore } from "@/stores/tasks";
import { useTaskListStore } from "@/stores/taskList";
import { useReminderStore } from "@/stores/reminder";
import { useUserStore } from "@/stores/user";
import { useGuestProfileStore } from "@/stores/guestProfile";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useMainMapStore } from "@/stores/mainMap";
import { useDocumentationStore } from "@/stores/documentation";
import { useAvailableValidationsChannelStore } from "@/stores/availableValidationsChannel";
import { fetchNearbyValidationParcels } from "@/components/maps/fetchBoundingBoxProperties";
import _ from "lodash";

const props = defineProps([
  "mapId",
  "polygon",
  "polygons",
  "nearby",
  "searchResult",
]);
const refreshing = ref(null);
const map = ref(null);
const featureGroup = ref(L.featureGroup());
const parcelFeatureGroup = ref(L.featureGroup());
const baseLayers = ref({
  Base: L.gridLayer.googleMutant({
    type: "roadmap",
    styles: mapStyles.styles,
  }),
  Satellite: L.gridLayer.googleMutant({
    type: "satellite",
    styles: [],
  }),
  Hybrid: L.gridLayer.googleMutant({
    type: "hybrid",
    styles: [],
  }),
  Transit: L.gridLayer.googleMutant({
    type: "roadmap",
    styles: [],
  }),
});
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop, workspaceLayout, workspaceResized } =
  storeToRefs(layoutStore);
const timeTravelStore = useTimeTravelStore();
const { asOfMilliseconds } = storeToRefs(timeTravelStore);
const analyzePanelStore = useAnalyzePanelStore();
const secondaryPanelStore = useSecondaryPanelStore();
const modalStore = useModalStore();
const unlockerStore = useUnlockerStore();
const userStore = useUserStore();
const documentationStore = useDocumentationStore();
const tasksStore = useTasksStore();
const taskListStore = useTaskListStore();
const reminderStore = useReminderStore();
const guestProfileStore = useGuestProfileStore();
const propertyDiagramStore = usePropertyDiagramStore();
const propertyFieldsStore = usePropertyFieldsStore();
const mapStore = useMainMapStore();
const crowdsourcedChangeGroupStore = useCrowdsourcedChangeGroupStore();
const availableValidationsChannelStore = useAvailableValidationsChannelStore();
const { activeValidationChangeGroupId } = storeToRefs(
  availableValidationsChannelStore,
);
const zoom = 16.5;

watch(workspaceLayout, () => {
  if (map.value) {
    setTimeout(function () {
      map.value.invalidateSize();
    }, 100);
  }
});

watch(workspaceResized, () => {
  if (map.value && workspaceResized.value) {
    setTimeout(function () {
      map.value.invalidateSize();
    }, 100);
  }
});

onMounted(() => {
  mountMap();
});

function mountMap(isVisible) {
  const el = document.getElementById(`parcel-map-${props.mapId}`);
  if (!isVisible) return;
  if (!el) return;
  if (map.value) return;

  map.value = L.map(`parcel-map-${props.mapId}`, {
    fullscreenControl: !props.searchResult,
    scrollWheelZoom: false,
    zoomAnimation: false,
    fadeAnimation: true,
    attributionControl: !props.searchResult,
    zoomControl: !props.searchResult,
    zoomSnap: 0.25,
    zoomDelta: 0.25,
    keyboardPanDelta: 40,
    markerZoomAnimation: true,
  }).setView([37.0902, -95.7129], zoom);

  baseLayers.value["Transit"].addGoogleLayer("TransitLayer");

  if (props.polygons || props.searchResult) {
    baseLayers.value["Base"].addTo(map.value);
  } else {
    baseLayers.value["Hybrid"].addTo(map.value);
  }

  map.value.on("fullscreenchange", function () {
    if (map.value.isFullscreen()) {
      map.value.scrollWheelZoom.enable();
    } else {
      map.value.scrollWheelZoom.disable();
      map.value.fitBounds(featureGroup.value.getBounds(), { maxZoom: zoom });
    }
  });

  map.value.on("moveend", function () {
    if (!refreshing.value) {
      debouncedRefreshAllLayers();
    }
  });

  parcelFeatureGroup.value.addTo(map.value);

  if (!props.searchResult) {
    L.control
      .layers(
        baseLayers.value,
        {},
        {
          collapsed: true,
        },
      )
      .addTo(map.value);
  }

  refreshMap();

  if (!refreshing.value) {
    debouncedRefreshAllLayers();
  }
}

const debouncedRefreshAllLayers = _.debounce(function () {
  refreshAllLayers();
}, 2000);

async function refreshAllLayers() {
  if (props.nearby) {
    refreshing.value = true;
    clearMapLayers("nearby");
    await fetchNearby();
    refreshing.value = false;
  }
}

async function fetchNearby() {
  return new Promise((resolve) => {
    if (isDesktop.value && props.nearby) {
      fetchNearbyValidationParcels({
        map: map.value,
        mapStore,
        propertyFieldsStore,
        modalStore,
        unlockerStore,
        userStore,
        tasksStore,
        taskListStore,
        reminderStore,
        guestProfileStore,
        propertyDiagramStore,
        changeGroupStore: crowdsourcedChangeGroupStore,
        secondaryPanelStore,
        documentationStore,
        analyzePanelStore,
        layoutStore,
        parcelFeatureGroup: parcelFeatureGroup.value,
        asOfMilliseconds: asOfMilliseconds.value,
        activeChangeGroupId: activeValidationChangeGroupId.value,
      }).then(() => {
        resolve();
      });
    } else {
      resolve();
    }
  });
}

function refreshMap() {
  clearMapLayers("focal");
  if (props.polygon) {
    displayParcel();
  } else if (props.polygons) {
    displayParcels();
  }
}
function clearMapLayers(scope) {
  let group = null;

  switch (scope) {
    case "nearby":
      group = parcelFeatureGroup.value;
      break;
    case "focal":
      group = featureGroup.value;
      break;
    default:
      break;
  }
  group.eachLayer((layer) => {
    map.value.removeLayer(layer);
    group.removeLayer(layer);
  });
}
function displayParcel() {
  const mapPolygon = L.polygon(props.polygon.coordinates, {
    interactive: false,
    color: "#FDBA74", // orange-300
    stroke: true,
    fill: true,
  });

  mapPolygon.addTo(map.value);
  featureGroup.value.addLayer(mapPolygon);
  map.value.fitBounds(featureGroup.value.getBounds(), { maxZoom: zoom });
}

function displayParcels() {
  for (const polygonDataField of props.polygons) {
    const polygon = polygonDataField.fieldContent;
    const mapPolygon = L.polygon(polygon.coordinates, {
      interactive: false,
      color: polygonDataField.color,
      stroke: true,
      fill: true,
    });

    mapPolygon.addTo(map.value);
    featureGroup.value.addLayer(mapPolygon);
  }

  map.value.fitBounds(featureGroup.value.getBounds(), {
    maxZoom: zoom,
    padding: [25, 25],
  });
}
</script>
