import { ref, computed, markRaw, watch } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { useWePanelStore } from "@/stores/wePanel";
import { useModalStore } from "@/stores/modal";
import ComingSoon from "@/components/ComingSoon.vue";
import api from "@/router/api";
import _ from "lodash";
import {
  IdentificationIcon,
  KeyIcon,
  LinkIcon,
  TrashIcon,
} from "@heroicons/vue/20/solid";
import DiscussionAddMembersForm from "@/components/we/channels-format/DiscussionAddMembersForm.vue";
import TopicSettingsOverviewForm from "@/components/we/settings-views/discussion-topic/TopicSettingsOverviewForm.vue";
import TopicPermissionsSettings from "@/components/we/settings-views/discussion-topic/TopicPermissionsSettings.vue";
import PermissionOverwriteEditor from "@/components/we/settings-views/discussion-topic/PermissionOverwriteEditor.vue";

export const useWeTopicSettingsStore = defineStore("weTopicSettings", () => {
  const modalStore = useModalStore();
  const { modalPayload, confirmationPayload } = storeToRefs(modalStore);
  const wePanelStore = useWePanelStore();
  const {
    expandedView,
    activeHubId,
    activeTopicId,
    mainContent,
    activeHubRoles,
    hubMembers,
    topicPermissionOverwrites,
    rightEdgeLevelThreeComponent,
    rightEdgeLevelThreeProps,
    levelThreeMainContent,
  } = storeToRefs(wePanelStore);
  const unsavedChangesNavigationGuard = ref(false);
  const overviewUnsavedChanges = computed(() => {
    const matchingTab = expandedView.value
      ? selectedTab.value?.name === "Overview"
      : true;
    const nameChange =
      _.trim(name.value) !== "" &&
      _.trim(name.value) !== mainContent.value.content.name;
    const descriptionChange =
      _.trim(description.value) !== "" &&
      _.trim(description.value) !==
        (mainContent.value.content?.description || "");
    const inactivityTimeoutChange =
      inactivityTimeout.value.value !==
      mainContent.value.content.inactivityTimeout;

    return (
      matchingTab &&
      (nameChange || descriptionChange || inactivityTimeoutChange)
    );
  });
  const unsavedChanges = computed(
    () => overviewUnsavedChanges.value || permissionUnsavedChanges.value,
  );
  const name = ref("");
  const description = ref("");
  const threeDaysSeconds = 60 * 60 * 24 * 3;
  const inactivityTimeout = ref({ label: "3 Days", value: threeDaysSeconds });

  const activeTopicPermissionOverwrites = computed(() =>
    topicPermissionOverwrites.value.filter(
      ({ topicId }) => topicId === activeTopicId.value,
    ),
  );
  const everyoneRole = computed(() =>
    _.find(activeHubRoles.value, { name: "everyone" }),
  );
  function seedPermissions() {
    if (activeTopicPermissionOverwrites.value.length === 0) {
      return [
        {
          id: "@everyonePlaceholder",
          topicId: activeTopicId.value,
          targetType: "DiscussionRole",
          targetId: everyoneRole.value?.id,
          target: everyoneRole.value,
          targetName: "everyone",
          viewChannels: "allow",
          manageChannels: null,
          managePermissions: null,
          createInvite: null,
          sendMessages: null,
          sendThreadMessages: null,
          createPublicThreads: null,
          createPrivateThreads: null,
          embedLinks: null,
          attachFiles: null,
          mentionRoles: null,
          manageMessages: null,
          manageThreads: null,
          readMessageHistory: null,
          useCommands: null,
        },
      ];
    } else {
      return _.cloneDeep(activeTopicPermissionOverwrites.value);
    }
  }
  const editablePermissionOverwrites = ref(seedPermissions());
  const rolesToAdd = ref([]);
  const membersToAdd = ref([]);
  const permissions = computed(() => {
    return _.orderBy(editablePermissionOverwrites.value, ["order"], ["desc"]);
  });
  const displayablePermissions = computed(() => {
    const withoutEveryone = permissions.value.filter(({ id }) => {
      return id !== everyonePermission.value.id;
    });
    return _.groupBy(withoutEveryone, "targetType");
  });
  const everyonePermission = computed(() =>
    _.find(permissions.value, {
      targetType: "DiscussionRole",
      targetName: "everyone",
    }),
  );
  const privateTopic = computed(() => {
    const viewChannels = everyonePermission.value?.viewChannels;
    return viewChannels === "deny";
  });

  const placeholderEveryoneTemplate = computed(
    () => _.find(permissionTemplates.value, { name: "Viewer" })?.permissions,
  );
  const editableEveryonePlaceholder = computed(() => {
    if (activeTopicPermissionOverwrites.value.length === 0) {
      const permissionsOnly = editablePermissionOverwrites.value.map(
        (permission) => {
          return {
            viewChannels: permission.viewChannels,
            manageChannels: permission.manageChannels,
            managePermissions: permission.managePermissions,
            createInvite: permission.createInvite,
            sendMessages: permission.sendMessages,
            sendThreadMessages: permission.sendThreadMessages,
            createPublicThreads: permission.createPublicThreads,
            createPrivateThreads: permission.createPrivateThreads,
            embedLinks: permission.embedLinks,
            attachFiles: permission.attachFiles,
            mentionRoles: permission.mentionRoles,
            manageMessages: permission.manageMessages,
            manageThreads: permission.manageThreads,
            readMessageHistory: permission.readMessageHistory,
            useCommands: permission.useCommands,
          };
        },
      );

      return _.head(permissionsOnly);
    } else {
      return null;
    }
  });

  const permissionUnsavedChanges = computed(() => {
    const matchingTab = expandedView.value
      ? selectedTab.value?.name === "Permissions"
      : true;
    const comparisonToExisting = !_.isEqual(
      editablePermissionOverwrites.value,
      activeTopicPermissionOverwrites.value,
    );
    const placeholderComparison = !_.isEqual(
      editableEveryonePlaceholder.value,
      placeholderEveryoneTemplate.value,
    );
    const effectiveComparison =
      activeTopicPermissionOverwrites.value.length === 0
        ? placeholderComparison
        : comparisonToExisting;
    return matchingTab && effectiveComparison;
  });
  const selectedPermissionId = ref(null);
  const editablePermission = computed({
    get() {
      return (
        _.find(editablePermissionOverwrites.value, {
          id: selectedPermissionId.value,
        }) || {}
      );
    },
    set(newPermission) {
      const index = _.findIndex(editablePermissionOverwrites.value, {
        id: selectedPermissionId.value,
      });
      if (index !== -1) {
        editablePermissionOverwrites.value[index] = {
          ...editablePermissionOverwrites.value[index],
          ...newPermission,
        };
      }
    },
  });
  function memberFor(permission) {
    if (permission.targetType === "User") {
      return _.find(hubMembers.value, { userId: permission.targetId });
    } else {
      return null;
    }
  }
  function roleFor(permission) {
    if (permission.targetType === "DiscussionRole") {
      return _.find(activeHubRoles.value, { id: permission.targetId });
    } else {
      return null;
    }
  }

  const permissionGroupings = ref([
    {
      name: "General Server Permissions",
      permissions: ["viewChannels", "manageChannels", "managePermissions"],
    },
    {
      name: "Membership Permissions",
      permissions: ["createInvite"],
    },
    {
      name: "Text Topic Permissions",
      permissions: [
        "sendMessages",
        "sendThreadMessages",
        "createPublicThreads",
        "createPrivateThreads",
        "embedLinks",
        "attachFiles",
        "mentionRoles",
        "manageMessages",
        "manageThreads",
        "readMessageHistory",
      ],
    },
    {
      name: "Apps Permissions",
      permissions: ["useCommands"],
    },
  ]);

  const permissionCopy = ref({
    viewChannels: {
      label: "View Topic",
      description:
        "Allows members to view this Topic by default. Disabling this for @everyone will make this Topic private.",
    },
    manageChannels: {
      label: "Manage Topic",
      description:
        "Allows members to change this Topic's name, description, and settings. They can also delete the Topic.",
    },
    managePermissions: {
      label: "Manage Permission",
      description: "Allows members to change this Topic's permissions.",
    },

    createInvite: {
      label: "Create Invite",
      description:
        "Allows members to invite new people to this Hub via a direct invite link to this Topic.",
    },

    sendMessages: {
      label: "Send Messages",
      description: "Allows members to send messages in this Topic.",
    },
    sendThreadMessages: {
      label: "Send Messages in Threads",
      description:
        "Allows members to send messages in threads under this Topic.",
    },
    createPublicThreads: {
      label: "Create Public Threads",
      description:
        "Allows members to create threads that everyone in this Topic can view.",
    },

    createPrivateThreads: {
      label: "Create Private Threads",
      description:
        "Allows members to create invite-only threads in this Topic.",
    },
    embedLinks: {
      label: "Embed Links",
      description: "Allows links that members share to show embedded content.",
    },
    attachFiles: {
      label: "Attach Files",
      description: "Allows members to upload files.",
    },
    mentionRoles: {
      label: "Mention @everyone, @here, and All Roles",
      description:
        "Allows members to use @everyone (everyone in the Hub) or @here (only online members) in this Topic. They can also @mention all roles in this Topic, even if the role's 'Allow anyone to mention this role' permission is disabled.",
    },

    manageMessages: {
      label: "Manage Messages",
      description:
        "Allows members to delete messages by other members or star any message in this Topic.",
    },

    manageThreads: {
      label: "Manage Threads",
      description:
        "Allows members to rename, delete, and close threads in this Topic. They can also view private threads.",
    },

    readMessageHistory: {
      label: "Read Message History",
      description:
        "Allows members to read previous messages sent in this Topic. If this permission is disabled, members only see messages sent when they are online.",
    },

    useCommands: {
      label: "Use Commands",
      description:
        "Allows members to use commands from applications, including slash commands and context menu commands in this Topic.",
    },
  });

  const permissionTemplates = ref([
    {
      name: "Viewer",
      color: "indigo",
      primaryColorShade: 600,
      description: "Allows the member to view the Topic.",
      bullets: ["View the Topic"],
      permissions: {
        viewChannels: "allow",
        manageChannels: null,
        managePermissions: null,
        createInvite: null,
        sendMessages: null,
        sendThreadMessages: null,
        createPublicThreads: null,
        createPrivateThreads: null,
        embedLinks: null,
        attachFiles: null,
        mentionRoles: null,
        manageMessages: null,
        manageThreads: null,
        readMessageHistory: null,
        useCommands: null,
      },
    },
    {
      name: "Member",
      color: "green",
      primaryColorShade: 500,
      description: "Basic permissions for a regular member to talk.",
      bullets: ["Talk", "Invite friends"],
      permissions: {
        viewChannels: "allow",
        manageChannels: "deny",
        managePermissions: "deny",
        createInvite: "allow",
        sendMessages: "allow",
        sendThreadMessages: "allow",
        createPublicThreads: "allow",
        createPrivateThreads: "allow",
        embedLinks: "allow",
        attachFiles: "allow",
        mentionRoles: "allow",
        manageMessages: "deny",
        manageThreads: "deny",
        readMessageHistory: "allow",
        useCommands: "allow",
      },
    },
    {
      name: "Moderator",
      color: "yellow",
      primaryColorShade: 500,
      description:
        "People who can help you manage other members in this server.\n\nEverything members can do, and:",
      bullets: ["Delete any messages"],
      permissions: {
        viewChannels: "allow",
        manageChannels: "deny",
        managePermissions: "deny",
        createInvite: "allow",
        sendMessages: "allow",
        sendThreadMessages: "allow",
        createPublicThreads: "allow",
        createPrivateThreads: "allow",
        embedLinks: "allow",
        attachFiles: "allow",
        mentionRoles: "allow",
        manageMessages: "allow",
        manageThreads: "deny",
        readMessageHistory: "allow",
        useCommands: "allow",
      },
    },
    {
      name: "Managers",
      color: "red",
      primaryColorShade: 600,
      description:
        "Trusted leaders who can help you build the Hub.\n\nEverything moderators can do, and:",
      bullets: ["Edit this Topic", "Edit permissions", "Pretty much anything"],
      permissions: {
        viewChannels: "allow",
        manageChannels: "allow",
        managePermissions: "allow",
        createInvite: "allow",
        sendMessages: "allow",
        sendThreadMessages: "allow",
        createPublicThreads: "allow",
        createPrivateThreads: "allow",
        embedLinks: "allow",
        attachFiles: "allow",
        mentionRoles: "allow",
        manageMessages: "allow",
        manageThreads: "allow",
        readMessageHistory: "allow",
        useCommands: "allow",
      },
    },
  ]);

  watch(
    activeTopicPermissionOverwrites,
    () => {
      resetPermissions();
    },
    { deep: true },
  );

  const overviewTab = {
    name: "Overview",
    order: 0,
    grouping: "Settings",
    handler: () => {},
    component: markRaw(TopicSettingsOverviewForm),
    icon: IdentificationIcon,
  };
  const permissionsTab = {
    name: "Permissions",
    order: 1,
    grouping: "Settings",
    handler: () => {},
    component: markRaw(TopicPermissionsSettings),
    icon: KeyIcon,
  };
  const invitesTab = {
    name: "Invites",
    order: 2,
    grouping: "Settings",
    handler: () => {},
    component: markRaw(ComingSoon),
    icon: LinkIcon,
  };
  const deleteTab = {
    name: "Delete Topic",
    order: 0,
    grouping: "Confirmatory",
    handler: () => {
      const callback = () => {
        deleteTopic();
      };
      confirmationPayload.value = {
        title: "Delete Topic",
        message:
          "Are you sure you want to delete this Topic? This action cannot be undone.",
        affirmText: "Delete",
        affirmCallback: callback,
      };
    },
    component: markRaw(ComingSoon),
    icon: TrashIcon,
  };

  const tabs = [overviewTab, permissionsTab, invitesTab, deleteTab];
  const selectedTab = ref(overviewTab);
  const selectedTabLayeredComponents = ref([]);
  function addLayeredComponent(component) {
    selectedTabLayeredComponents.value.push(markRaw(component));
  }
  function removeLayeredComponent(component) {
    const index = selectedTabLayeredComponents.value.indexOf(
      markRaw(component),
    );
    if (index > -1) {
      selectedTabLayeredComponents.value.splice(index, 1);
    }
  }
  function backLayeredComponent() {
    selectedTabLayeredComponents.value.pop();

    if (selectedTabLayeredComponents.value.length === 0) {
      selectedPermissionId.value = everyonePermission.value?.id;
    }
  }
  function resetLayeredComponents() {
    if (!expandedView.value) {
      wePanelStore.closeRightEdgeLevelThreePanel();
    }
    selectedTabLayeredComponents.value = [];
    selectedPermissionId.value = everyonePermission.value?.id;
  }

  const primaryNavigationOptions = computed(() => {
    const groupings = [
      {
        name: "Settings",
        label: mainContent.value.content.name,
        order: 0,
      },
      {
        name: "Confirmatory",
        label: "Confirmatory",
        order: 1,
      },
    ];
    const withTabs = groupings.map(({ name, label, order }) => {
      return {
        name,
        label,
        order,
        options: _.orderBy(
          tabs.filter((tab) => tab.grouping === name),
          ["order"],
          ["asc"],
        ),
      };
    });

    return _.orderBy(withTabs, ["order"], ["asc"]);
  });

  async function updateTopicOverview() {
    if (
      activeHubId.value &&
      activeTopicId.value &&
      overviewUnsavedChanges.value
    ) {
      const payload = {
        name: _.trim(name.value),
        description: _.trim(description.value),
        inactivityTimeout: inactivityTimeout.value.value,
      };

      const hubResponse = await api.patch(
        `discussion_topics/${activeTopicId.value}`,
        payload,
      );

      wePanelStore.patchChannel(hubResponse.data);
      mainContent.value.content.name = name.value;
      mainContent.value.content.description = description.value;
      mainContent.value.content.inactivityTimeout =
        inactivityTimeout.value.value;

      if (expandedView.value) {
        wePanelStore.closeSecondaryContentPanel();
      } else {
        wePanelStore.closeBottomEdgeLevelTwoPanel();
      }
      resetOverview();
    }
  }

  async function deleteTopic() {
    if (activeHubId.value && activeTopicId.value) {
      await api.delete(`discussion_topics/${activeTopicId.value}`);

      wePanelStore.dropChannel(mainContent.value.content);

      if (expandedView.value) {
        wePanelStore.closeSecondaryContentPanel();
      } else {
        wePanelStore.closeBottomEdgePanel();
        wePanelStore.closeBottomEdgeLevelTwoPanel();
      }

      resetOverview();
      wePanelStore.closeRightEdgePanel();
    }
  }

  async function addPermissionOverwrites() {
    const payload = {
      roleIds: rolesToAdd.value.map(({ id }) => id),
      userIds: membersToAdd.value.map(({ userId }) => userId),
    };

    const response = await api.post(
      `discussion_topics/${activeTopicId.value}/discussion_permission_overwrites`,
      payload,
    );

    rolesToAdd.value = [];
    membersToAdd.value = [];

    if (!expandedView.value) {
      wePanelStore.patchTopicPermissionOverwrites(response.data);
      resetPermissions();
    }

    return response?.data;
  }

  function resetOverview() {
    name.value = "";
    description.value = "";
    inactivityTimeout.value = { label: "3 Days", value: threeDaysSeconds };
  }

  function addPermissionMembers() {
    if (expandedView.value) {
      modalPayload.value = {
        size: "base",
        theme: "light",
        component: markRaw(DiscussionAddMembersForm),
        props: {
          context: "topicPermissionOverwrites",
        },
        afterClose: async () => {
          await wePanelStore.fetchTopicPermissionOverwrites();
          resetPermissions();
        },
        afterCloseDestination: null,
      };
    } else {
      addLayeredComponent(DiscussionAddMembersForm);

      rightEdgeLevelThreeComponent.value = _.last(
        selectedTabLayeredComponents.value,
      );
      rightEdgeLevelThreeProps.value = {
        context: "topicPermissionOverwrites",
      };
    }
  }

  async function updatePermissions() {
    const postUpdateId = editableEveryonePlaceholder.value
      ? null
      : editablePermission.value.id;
    for (const permission of editablePermissionOverwrites.value) {
      const payload = {
        viewChannels: permission.viewChannels,
        manageChannels: permission.manageChannels,
        managePermissions: permission.managePermissions,
        createInvite: permission.createInvite,
        sendMessages: permission.sendMessages,
        sendThreadMessages: permission.sendThreadMessages,
        createPublicThreads: permission.createPublicThreads,
        createPrivateThreads: permission.createPrivateThreads,
        embedLinks: permission.embedLinks,
        attachFiles: permission.attachFiles,
        mentionRoles: permission.mentionRoles,
        manageMessages: permission.manageMessages,
        manageThreads: permission.manageThreads,
        readMessageHistory: permission.readMessageHistory,
        useCommands: permission.useCommands,
      };
      const response = await api.patch(
        `discussion_topics/${activeTopicId.value}/discussion_permission_overwrites/${permission.id}`,
        payload,
      );

      if (response?.data) {
        if (editableEveryonePlaceholder.value) {
          await wePanelStore.fetchTopicPermissionOverwrites();
        } else {
          await wePanelStore.patchTopicPermissionOverwrites([response.data]);
        }

        if (!expandedView.value) {
          wePanelStore.closeRightEdgeLevelThreePanel();
          resetLayeredComponents();
        }
      }
    }

    resetPermissions(postUpdateId);
  }

  async function deletePermissionOverwrite(permission) {
    const response = await api.delete(
      `discussion_topics/${activeTopicId.value}/discussion_permission_overwrites/${permission.id}`,
    );

    return response?.data;
  }

  const permissionTabs = ref([
    {
      name: "Permissions",
      component: markRaw(PermissionOverwriteEditor),
    },
  ]);

  function defaultPermissionTab() {
    return permissionTabs.value[0];
  }

  function viewPermissionDetails(permission = everyonePermission.value) {
    selectedPermissionId.value = permission.id;
    if (!expandedView.value) {
      const actionableTab = defaultPermissionTab()?.component;
      if (actionableTab) {
        addLayeredComponent(actionableTab);

        rightEdgeLevelThreeComponent.value = _.last(
          selectedTabLayeredComponents.value,
        );
        rightEdgeLevelThreeProps.value = {
          context: {
            location: "levelThree",
            scenario: "discussionTopicPermissionDetails",
          },
        };
        levelThreeMainContent.value = null;
      }
    }
  }

  function resetPermissions(selectedId = null) {
    editablePermissionOverwrites.value = seedPermissions();
    selectedPermissionId.value = selectedId || everyonePermission.value?.id;
  }

  function reset() {
    resetOverview();
    resetPermissions();
    resetLayeredComponents();
    selectedPermissionId.value = null;
  }

  return {
    unsavedChangesNavigationGuard,
    overviewUnsavedChanges,
    unsavedChanges,
    name,
    description,
    inactivityTimeout,
    permissions,
    displayablePermissions,
    everyonePermission,
    privateTopic,
    editablePermissionOverwrites,
    editablePermission,
    editableEveryonePlaceholder,
    selectedPermissionId,
    permissionUnsavedChanges,
    permissionGroupings,
    permissionCopy,
    permissionTemplates,
    selectedTab,
    primaryNavigationOptions,
    updateTopicOverview,
    resetOverview,
    resetPermissions,
    memberFor,
    roleFor,
    addPermissionMembers,
    updatePermissions,
    deletePermissionOverwrite,
    addPermissionOverwrites,
    rolesToAdd,
    membersToAdd,
    viewPermissionDetails,
    defaultPermissionTab,
    permissionTabs,
    selectedTabLayeredComponents,
    addLayeredComponent,
    removeLayeredComponent,
    backLayeredComponent,
    resetLayeredComponents,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useWeTopicSettingsStore, import.meta.hot),
  );
}
