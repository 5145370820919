import { useUserStore } from "@/stores/user";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useCompanyDetailStore } from "@/stores/companyDetail";
import { useContactDetailStore } from "@/stores/contactDetail";
import { useModalStore } from "@/stores/modal";
import { useWeChannelStore } from "@/stores/weChannel";
import { useNotificationsStore } from "@/stores/notifications";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useWeThreadFormStore } from "@/stores/weThreadForm";
import { defineStore, storeToRefs, acceptHMRUpdate } from "pinia";
import { computed, markRaw, nextTick, ref, watch } from "vue";
import api from "@/router/api";
import {
  ArrowsPointingInIcon,
  ChatBubbleOvalLeftIcon,
  MapPinIcon,
  PlusCircleIcon,
  SparklesIcon,
} from "@heroicons/vue/24/outline";
import {
  UsersIcon,
  UserPlusIcon,
  ClockIcon,
  StarIcon,
  BellIcon,
  ChatBubbleLeftRightIcon,
  ChatBubbleOvalLeftIcon as SolidChatBubbleOvalLeftIcon,
  EllipsisHorizontalIcon,
  Cog8ToothIcon,
  ListBulletIcon,
  CheckIcon,
  XMarkIcon,
  FolderPlusIcon,
  PlusCircleIcon as SolidPlusCircleIcon,
} from "@heroicons/vue/20/solid";
import _ from "lodash";
import ComingSoon from "@/components/ComingSoon.vue";
import TeamForm from "@/components/users/teams/TeamForm.vue";
import WeTeamIcon from "@/components/we/WeTeamIcon.vue";
import WeHeroImageIcon from "@/components/we/WeHeroImageIcon.vue";
import WeShell from "@/components/we/WeShell.vue";
import NearbyMessagesSelector from "@/components/we/selector-views/NearbyMessagesSelector.vue";
import TeamMessagesSelector from "@/components/we/selector-views/TeamMessagesSelector.vue";
import DatafieldMessagesSelector from "@/components/we/selector-views/DatafieldMessagesSelector.vue";
import DirectMessagesSelector from "@/components/we/selector-views/DirectMessagesSelector.vue";
import DirectMessagesContainer from "@/components/we/main-content-views/DirectMessagesContainer.vue";
import TeamDetails from "@/components/users/teams/TeamDetails.vue";
import TwoColumnHubSettingsForm from "@/components/we/channels-format/TwoColumnHubSettingsForm.vue";
import BottomEdgeHubSettingsForm from "@/components/we/channels-format/BottomEdgeHubSettingsForm.vue";
import TwoColumnTopicSettingsForm from "@/components/we/channels-format/TwoColumnTopicSettingsForm.vue";
import BottomEdgeTopicSettingsForm from "@/components/we/channels-format/BottomEdgeTopicSettingsForm.vue";
import DiscussionCategoryForm from "@/components/we/channels-format/DiscussionCategoryForm.vue";
import DiscussionTopicForm from "@/components/we/channels-format/DiscussionTopicForm.vue";
import AddDirectMessageMembers from "@/components/we/direct-messages/AddDirectMessageMembers.vue";
import AddDiscussionTopicInvitees from "@/components/we/channels-format/AddDiscussionTopicInvitees.vue";
import ChannelMembersList from "@/components/we/channels-format/ChannelMembersList.vue";
import ThreadsList from "@/components/we/channels-format/ThreadsList.vue";

export const useWePanelStore = defineStore("we", () => {
  const weThreadFormStore = useWeThreadFormStore();
  const secondaryPanelStore = useSecondaryPanelStore();
  const notificationsStore = useNotificationsStore();
  const propertyFieldsStore = usePropertyFieldsStore();
  const companyDetailStore = useCompanyDetailStore();
  const { companyIdParam, companyDataField } = storeToRefs(companyDetailStore);
  const contactDetailStore = useContactDetailStore();
  const { contactIdParam, contactDataField } = storeToRefs(contactDetailStore);
  const propertyDiagramStore = usePropertyDiagramStore();
  const {
    propertyIdParam,
    propertyDiagramPropertyDataFields,
    propertyDiagramPropertyIds,
  } = storeToRefs(propertyDiagramStore);
  const userStore = useUserStore();
  const { currentUser, signedIn } = storeToRefs(userStore);
  const layoutStore = useWorkspaceLayoutStore();
  const { isDesktop, workspaceLayout } = storeToRefs(layoutStore);
  const modalStore = useModalStore();
  const { confirmationPayload } = storeToRefs(modalStore);
  const weChannelStore = useWeChannelStore();
  const { userPresence, userAppearances } = storeToRefs(weChannelStore);

  const nearby = ref(true);
  const regionPagyContainer = ref({
    data: [],
    pagy: null,
  });
  const regions = computed(() => regionPagyContainer.value.data);
  const regionsPagy = computed(() => regionPagyContainer.value.pagy);
  function patchRegion(region) {
    regionPagyContainer.value.data = _.unionBy([region], regions.value, "id");
  }

  const networkingPagyContainer = ref({
    data: [],
    pagy: null,
  });
  const networkings = computed(() => networkingPagyContainer.value.data);
  const networkingsPagy = computed(() => networkingPagyContainer.value.pagy);
  function networkingCounterpart(networking) {
    if (networking.inviter.token === currentUser.value.token) {
      return networking.recipient;
    } else {
      return networking.inviter;
    }
  }
  const acceptedNetworkings = computed(() => {
    return networkings.value.filter((networking) => networking.acceptedAt);
  });
  const focalNetworkings = computed(() => {
    if (networkTab.value === "Online") {
      return acceptedNetworkings.value.filter(
        (networking) =>
          presenceFor(networkingCounterpart(networking)) === "here",
      );
    } else {
      return acceptedNetworkings.value;
    }
  });

  function presenceFor(user) {
    return user.token
      ? _.find(userPresence.value, { token: user.token })?.presence ||
          user.presence
      : user.presence;
  }
  function propertyAppearancesFor(member) {
    const raw = userAppearances.value.filter(
      ({ userId }) => member.userId === userId,
    );
    const uniq = _.uniqBy(raw, "hostId");
    return uniq.map(({ hostId }) =>
      propertyFieldsStore.getPropertyField(hostId),
    );
  }

  const networkingRequests = ref({
    sent: [],
    received: [],
  });

  const hubInvitations = ref([]);
  function patchInvitation(invitation) {
    hubInvitations.value = _.unionBy([invitation], hubInvitations.value, "id");
  }

  const channels = ref([]);
  function patchChannel(topic) {
    channels.value = _.unionBy([topic], channels.value, "id");
  }
  function dropChannel(topic) {
    channels.value = channels.value.filter((t) => t.id !== topic.id);
  }

  const categories = computed(() => {
    return channels.value.filter(
      (channel) => channel.contentType === "category",
    );
  });

  const activeHubId = computed(() => {
    if (activeOption.value?.matchType === "DiscussionHub") {
      return activeOption.value?.matchId;
    } else {
      return activeOption.value?.matchContent?.hubId;
    }
  });

  const activeTopicId = computed(() => {
    if (mainContent.value?.content?.topicType) {
      return mainContent.value.content.id;
    } else {
      return null;
    }
  });

  const activeCategories = computed(() => {
    if (activeHubId.value) {
      return categories.value.filter(
        (category) => category.hubId === activeHubId.value,
      );
    } else {
      return [];
    }
  });

  const directHubChannels = computed(() => {
    return channels.value.filter(
      (channel) =>
        channel.contentType !== "category" &&
        !channel.categoryId &&
        !channel.threadParentTopicId,
    );
  });
  const threads = computed(() => {
    return channels.value.filter((channel) => !!channel.threadParentTopicId);
  });
  const directMessages = computed(() => {
    return channels.value.filter((channel) => !!channel.userId);
  });

  const hubRoles = ref([]);
  const activeHubRoles = computed(() => {
    if (activeHubId.value) {
      return hubRoles.value.filter(({ hubId }) => hubId === activeHubId.value);
    } else {
      return [];
    }
  });
  function patchHubRoles(roles) {
    hubRoles.value = _.unionBy(roles, hubRoles.value, "id");
  }
  function dropHubRole(role) {
    hubRoles.value = hubRoles.value.filter((r) => r.id !== role.id);
  }
  const hubMembers = ref([]);
  function patchHubMembers(members) {
    hubMembers.value = _.unionBy(members, hubMembers.value, "memberId");
  }
  const topicPermissionOverwrites = ref([]);
  function patchTopicPermissionOverwrites(members) {
    topicPermissionOverwrites.value = _.unionBy(
      members,
      topicPermissionOverwrites.value,
      function ({ targetType, targetId }) {
        return `${targetType}_${targetId}`;
      },
    );
  }

  const joinedHubs = ref([]);
  function dropHub(hub) {
    joinedHubs.value = joinedHubs.value.filter((h) => h.id !== hub.id);
    changeView();
    activeOption.value = null;
  }
  function patchHub(hub) {
    joinedHubs.value = _.unionBy([hub], joinedHubs.value, "id");
  }

  function hubOptionsFor(hub, context) {
    if (!hub) return [];

    const team = hub.team;
    const hubOptions = () => {
      const invitableChannels = channels.value.filter(
        (channel) =>
          channel.hubId === hub.id &&
          channel.contentType !== "category" &&
          !channel.isPrivate,
      );
      const inviteChannel = _.find(
        invitableChannels,
        function ({ name, isPrivate }) {
          return name === "general" || !isPrivate;
        },
      );
      const createInviteOption = {
        name: "Invite People",
        compactName: "Invite",
        confirmation: false,
        handler: () => {
          openSecondaryContentPanel({
            component: AddDiscussionTopicInvitees,
            props: {
              channel: inviteChannel,
            },
          });
        },
        icon: UserPlusIcon,
      };
      const notificationSettingsOption = {
        name: "Notification Settings",
        compactName: "Notifications",
        confirmation: false,
        handler: () => {},
        icon: BellIcon,
      };
      const createTopicOption = {
        name: "Create Topic",
        compactName: "Create Topic",
        confirmation: false,
        handler: () => {
          openSecondaryContentPanel({
            component: DiscussionTopicForm,
            props: {},
          });
        },
        icon: SolidPlusCircleIcon,
      };
      const createCategoryOption = {
        name: "Create Category",
        compactName: "Create Category",
        confirmation: false,
        handler: () => {
          openSecondaryContentPanel({
            component: DiscussionCategoryForm,
            props: {},
          });
        },
        icon: FolderPlusIcon,
      };
      const hubSettingsOption = {
        name: "Hub Settings",
        compactName: "Settings",
        confirmation: false,
        handler: () => {
          openSecondaryContentPanel({
            component: expandedView.value
              ? TwoColumnHubSettingsForm
              : BottomEdgeHubSettingsForm,
            props: {
              overflowYOverride: true,
            },
          });
        },
        icon: Cog8ToothIcon,
      };
      let rawOptions = [];

      switch (context) {
        case "header":
          {
            if (expandedView.value) {
              rawOptions = [
                createInviteOption,
                createTopicOption,
                createCategoryOption,
                notificationSettingsOption,
                hubSettingsOption,
              ];
            } else {
              break;
            }
          }
          break;
        case "detailsMenu":
          {
            rawOptions = [
              createInviteOption,
              notificationSettingsOption,
              hubSettingsOption,
            ];
          }
          break;
        case "detailsMenuSecondaryTabs":
          {
            rawOptions = [createTopicOption, createCategoryOption];
          }
          break;
        default:
          break;
      }

      return _.compact(rawOptions);
    };
    const teamOptions = () => {
      if (team) {
        const leaveTeamOption = {
          name: "Leave Team",
          compactName: "Leave",
          confirmation: true,
          handler: () => {
            const leaveCallback = () => {
              api
                .delete(
                  `teams/${team.id}/team_memberships/${team.membershipId}`,
                )
                .then(() => {
                  dropHub(hub);
                });
            };
            confirmationPayload.value = {
              title: "Leave Team",
              message:
                "Are you sure you want to leave this team? This action cannot be undone.",
              affirmText: "Leave",
              affirmCallback: leaveCallback,
            };
          },
          icon: XMarkIcon,
        };
        const teamSettingsOption = {
          name: "Team Settings",
          compactName: "Settings",
          confirmation: false,
          handler: () => {
            openSecondaryContentPanel({
              component: TeamForm,
              props: {
                teamId: team.id,
              },
            });
          },
          icon: Cog8ToothIcon,
        };
        const isManager = _.includes(
          ["Primary owner", "Billing member"],
          team.role,
        );
        const rawOptions = [
          {
            name: "Team Details",
            compactName: "Details",
            confirmation: false,
            handler: () => {
              openSecondaryContentPanel({
                component: TeamDetails,
                props: {
                  teamId: team.id,
                },
              });
            },
            icon: ListBulletIcon,
          },
          isManager ? teamSettingsOption : null,
          team?.canLeave ? leaveTeamOption : null,
        ];

        return _.compact(rawOptions);
      } else {
        return [];
      }
    };
    const rawTabs = [
      {
        order: 1,
        name: "hubOptions",
        options: hubOptions(),
      },
      team
        ? {
            order: 2,
            name: "teamOptions",
            options: teamOptions(),
          }
        : null,
    ];

    return _.orderBy(_.compact(rawTabs), ["order"], ["asc"]);
  }

  function toggleChannelMembersList() {
    if (rightEdgeLevelTwoComponent.value) {
      closeRightEdgeLevelTwoPanel();
    } else {
      rightEdgeLevelTwoComponent.value = markRaw(ChannelMembersList);
      rightEdgeLevelTwoProps.value = {
        context: {
          location: "levelTwo",
          scenario: "displayingMembersList",
        },
      };
      levelTwoMainContent.value = null;
    }
  }

  const displayingMembersList = computed(
    () =>
      rightEdgeLevelTwoProps.value?.context?.scenario ===
      "displayingMembersList",
  );

  function topicOptionsFor(topic, context) {
    if (!topic) return [];

    const threadsOption = {
      name: "Threads",
      compactName: "Threads",
      confirmation: false,
      dropdown: true,
      handler: () => {
        if (!expandedView.value) {
          bottomEdgeLevelTwoPosition.value = "full";
          bottomEdgeLevelTwoComponent.value = markRaw(ThreadsList);
          bottomEdgeLevelTwoProps.value = {};
        }
      },
      menuComponent: markRaw(ThreadsList),
      icon: ChatBubbleLeftRightIcon,
    };
    const notificationSettingsOption = {
      name: "Notification Settings",
      compactName: "Mute",
      confirmation: false,
      dropdown: true,
      handler: () => {},
      menuComponent: markRaw(ComingSoon),
      icon: BellIcon,
    };
    const starredMessagesOption = {
      name: "Starred Messages",
      compactName: "Starred",
      confirmation: false,
      dropdown: true,
      handler: () => {},
      menuComponent: markRaw(ComingSoon),
      icon: StarIcon,
    };
    const membersListOption = {
      name: displayingMembersList.value
        ? "Hide Members List"
        : "Show Members List",
      compactName: "Members",
      confirmation: false,
      dropdown: false,
      handler: () => {
        if (expandedView.value) {
          toggleChannelMembersList();
        }
      },
      menuComponent: markRaw(ChannelMembersList),
      icon: UsersIcon,
    };
    const topicSettingsOption = {
      name: "Topic Settings",
      compactName: "Settings",
      confirmation: false,
      handler: () => {
        openSecondaryContentPanel({
          component: expandedView.value
            ? TwoColumnTopicSettingsForm
            : BottomEdgeTopicSettingsForm,
          props: {
            overflowYOverride: true,
          },
        });
      },
      menuComponent: markRaw(ComingSoon),
      icon: Cog8ToothIcon,
    };
    let rawOptions = [];

    switch (context) {
      case "header":
        {
          if (expandedView.value) {
            rawOptions = [
              threadsOption,
              notificationSettingsOption,
              starredMessagesOption,
              membersListOption,
            ];
          } else {
            break;
          }
        }
        break;
      case "detailsMenu":
        {
          if (topic.contentType === "thread") {
            rawOptions = [notificationSettingsOption, topicSettingsOption];
          } else {
            rawOptions = [
              threadsOption,
              notificationSettingsOption,
              topicSettingsOption,
            ];
          }
        }
        break;
      case "detailsMenuSecondaryTabs":
        {
          rawOptions = [membersListOption, starredMessagesOption];
        }
        break;
      default:
        break;
    }

    return _.compact(rawOptions);
  }

  const expandedView = computed(
    () => isDesktop.value && workspaceLayout.value === "topAndBottom",
  );
  const rightEdgeComponent = ref(null);
  const mainContent = ref(null);
  const rightEdgeLevelTwoComponent = ref(null);
  const rightEdgeLevelTwoProps = ref({});
  const levelTwoMainContent = ref(null);

  const bottomEdgeComponent = ref(null);
  const bottomEdgeProps = ref({});
  const bottomEdgePosition = ref("half");

  const bottomEdgeLevelTwoComponent = ref(null);
  const bottomEdgeLevelTwoProps = ref({});
  const bottomEdgeLevelTwoPosition = ref("half");

  const rightEdgeLevelThreeComponent = ref(null);
  const rightEdgeLevelThreeProps = ref({});
  const levelThreeMainContent = ref(null);

  function selectionIsActive(selectionObject) {
    if (!!mainContent.value && !!rightEdgeComponent.value) {
      // console.log("selectionIsActive", selectionObject, mainContent.value);
      if (_.isObject(mainContent.value)) {
        const regionMatch =
          !!mainContent.value.coordinates &&
          mainContent.value.id === selectionObject.id;
        const channelMatch =
          mainContent.value.contentType === "channel" &&
          selectionObject.contentType === "channel" &&
          activeTopicId.value === selectionObject.id;
        const threadMatch =
          mainContent.value.contentType === "thread" &&
          selectionObject.contentType === "thread" &&
          activeTopicId.value === selectionObject.id;
        const dmMatch =
          mainContent.value.contentType === "directMessage" &&
          activeTopicId.value === selectionObject.id;
        return regionMatch || channelMatch || threadMatch || dmMatch;
      } else {
        return selectionObject === mainContent.value;
      }
    } else {
      return false;
    }
  }
  function activeSelectionOptionClass(option) {
    return selectionIsActive(option) ? "bg-gray-300" : "bg-white";
  }

  const networkTab = ref("Online");
  const networkTabs = ref([
    { name: "Online", href: "" },
    { name: "All", href: "" },
    { name: "Pending", href: "" },
  ]);

  const defaultView = {
    component: markRaw(WeShell),
    props: {
      selectionComponent: markRaw(NearbyMessagesSelector),
    },
  };
  const lastView = ref({
    component: null,
    props: {},
  });
  const activeOption = ref(null);
  const activeView = ref(defaultView);
  function changeView(newView = defaultView) {
    lastView.value = activeView.value;
    activeView.value = newView;
    closeRightEdgePanel();
    closeRightEdgeLevelTwoPanel();
    closeBottomEdgePanel();
  }
  function selectActiveOption(option) {
    if (option.container) {
      activeOption.value = option;
    }

    option.handler();
  }
  function closeRightEdgeLevelTwoPanel() {
    rightEdgeLevelTwoComponent.value = null;
    cleanUpLevelTwo();
    rightEdgeLevelTwoProps.value = {};
    levelTwoMainContent.value = null;
  }
  function cleanUpLevelTwo() {
    if (rightEdgeLevelTwoProps.value?.context?.scenario === "createThread") {
      weThreadFormStore.dropDraftThread(activeTopicId.value);
    }
  }
  function closeRightEdgeLevelThreePanel() {
    rightEdgeLevelThreeComponent.value = null;
    rightEdgeLevelThreeProps.value = {};
    levelThreeMainContent.value = null;
  }
  function closeRightEdgePanel() {
    rightEdgeComponent.value = null;
    mainContent.value = null;
  }
  function closeBottomEdgePanel() {
    bottomEdgeComponent.value = null;
    bottomEdgeProps.value = {};
    bottomEdgePosition.value = "half";
  }
  function closeBottomEdgeLevelTwoPanel() {
    bottomEdgeLevelTwoComponent.value = null;
    bottomEdgeLevelTwoProps.value = {};
    bottomEdgeLevelTwoPosition.value = "half";
  }
  function closeTakeoverPanel() {
    if (
      activeOption.value?.container &&
      activeOption.value?.takeover?.component
    ) {
      activeOption.value.takeover = {
        component: null,
        props: {},
      };
    }
  }
  function closeSecondaryContentPanel() {
    if (expandedView.value) {
      closeTakeoverPanel();
    } else {
      closeBottomEdgePanel();
    }
  }
  function openSecondaryContentPanel({ component, props }) {
    if (signedIn.value) {
      if (expandedView.value) {
        activeOption.value.takeover = {
          component: markRaw(component),
          props,
        };
      } else {
        bottomEdgePosition.value = "full";
        bottomEdgeComponent.value = markRaw(component);
        bottomEdgeProps.value = props;
      }
    } else {
      secondaryPanelStore.promptToRegister();
    }
  }

  const dragging = ref(false);
  const instantUpload = ref(false);
  function instantUploadHandler() {
    if (dragging.value) {
      instantUpload.value = !instantUpload.value;
    } else {
      instantUpload.value = false;
    }
  }

  function optionIconClass(option) {
    return activeOption.value?.name === option.name
      ? "text-gray-700 bg-gray-300"
      : "text-gray-500 hover:bg-gray-200";
  }

  const nearbyMessagesTab = computed(() => {
    return {
      name: "Nearby Intel",
      container: true,
      href: "",
      handler: () => {
        changeView({
          component: markRaw(WeShell),
          props: {
            selectionComponent: markRaw(NearbyMessagesSelector),
          },
        });
      },
      icon: MapPinIcon,
      iconProps: {},
      iconClass: (option) => {
        return optionIconClass(option);
      },
    };
  });
  const directMessagesTab = computed(() => {
    return {
      name: "Direct Messages",
      container: true,
      href: "",
      handler: () => {
        changeView({
          component: markRaw(WeShell),
          props: {
            selectionComponent: markRaw(DirectMessagesSelector),
          },
        });
      },
      icon: ChatBubbleOvalLeftIcon,
      iconProps: {},
      iconClass: (option) => {
        return optionIconClass(option);
      },
    };
  });
  function fieldHubPreviewEligible(dataField) {
    if (!dataField) return false;

    const joinedHubFieldIds = _.compact(
      joinedHubs.value.map((hub) => hub.dataField?.localId),
    );
    return !_.includes(joinedHubFieldIds, dataField.localId);
  }
  const companyPreviewTab = computed(() => {
    return companyIdParam.value &&
      fieldHubPreviewEligible(companyDataField.value)
      ? {
          name: companyDataField.value.fieldContent.name,
          matchType: companyDataField.value.fieldContentType,
          matchId: companyDataField.value.fieldContentId,
          matchContent: companyDataField.value,
          container: true,
          previewMode: true,
          href: "",
          handler: () => {
            changeView({
              component: markRaw(WeShell),
              props: {
                selectionComponent: markRaw(DatafieldMessagesSelector),
              },
            });
          },
          icon: markRaw(WeHeroImageIcon),
          iconProps: { dataField: companyDataField.value },
          iconClass: (option) => {
            return optionIconClass(option);
          },
        }
      : null;
  });
  const contactPreviewTab = computed(() => {
    return contactIdParam.value &&
      fieldHubPreviewEligible(contactDataField.value)
      ? {
          name: contactDataField.value.fieldContent.name,
          matchType: contactDataField.value.fieldContentType,
          matchId: contactDataField.value.fieldContentId,
          matchContent: contactDataField.value,
          container: true,
          previewMode: true,
          href: "",
          handler: () => {
            changeView({
              component: markRaw(WeShell),
              props: {
                selectionComponent: markRaw(DatafieldMessagesSelector),
              },
            });
          },
          icon: markRaw(WeHeroImageIcon),
          iconProps: { dataField: contactDataField.value },
          iconClass: (option) => {
            return optionIconClass(option);
          },
        }
      : null;
  });
  const detailPageTabs = computed(() => {
    return _.compact([companyPreviewTab.value, contactPreviewTab.value]);
  });
  const propertyDiagramPreviewTabs = computed(() => {
    if (
      propertyIdParam.value &&
      _.size(propertyDiagramPropertyDataFields.value) > 0
    ) {
      const eligibleFields = _.filter(
        propertyDiagramPropertyDataFields.value,
        fieldHubPreviewEligible,
      );
      return eligibleFields.map((dataField) => {
        return {
          name: dataField.fieldContent.name,
          matchType: dataField.fieldContentType,
          matchId: dataField.fieldContentId,
          matchContent: dataField,
          container: true,
          previewMode: true,
          href: "",
          handler: () => {
            changeView({
              component: markRaw(WeShell),
              props: {
                selectionComponent: markRaw(DatafieldMessagesSelector),
              },
            });
          },
          icon: markRaw(WeHeroImageIcon),
          iconProps: { dataField },
          iconClass: (option) => {
            return optionIconClass(option);
          },
        };
      });
    } else {
      return [];
    }
  });

  watch(contactIdParam, () => {
    if (activeOption.value?.matchType === "Contact" && !contactIdParam.value) {
      changeView();
      activeOption.value = null;
    }
  });
  watch(
    propertyDiagramPropertyDataFields,
    () => {
      if (activeOption.value?.matchType === "Property") {
        if (
          !_.includes(
            propertyDiagramPropertyIds.value,
            activeOption.value.matchId,
          )
        ) {
          changeView();
          activeOption.value = null;
        }
      }
    },
    { deep: true },
  );

  const discoverView = ref(false);

  const primaryNavigationOptions = computed(() => {
    const teamTabs = {
      order: 3,
      name: "joinedHubs",
      options: joinedHubs.value.map((hub) => {
        return {
          name: hub.name,
          matchType: "DiscussionHub",
          matchId: hub.id,
          matchContent: hub,
          container: true,
          takeover: {
            component: null,
            props: {},
          },
          href: "",
          handler: () => {
            changeView({
              component: markRaw(WeShell),
              props: {
                selectionComponent: markRaw(TeamMessagesSelector),
              },
            });
          },
          icon: hub.dataField ? markRaw(WeHeroImageIcon) : markRaw(WeTeamIcon),
          iconProps: hub.dataField
            ? { dataField: hub.dataField }
            : { team: hub },
          iconClass: () => {
            return activeOption.value?.matchType === "DiscussionHub" &&
              activeOption.value?.matchId === hub.id
              ? "text-gray-700 bg-gray-300"
              : "text-gray-500 hover:bg-gray-200";
          },
        };
      }),
    };
    const defaultMessagesTabs = _.concat(
      propertyDiagramPreviewTabs.value,
      detailPageTabs.value,
      _.compact([nearbyMessagesTab.value, directMessagesTab.value]),
    );
    const rawTabs = [
      {
        order: 1,
        name: "headerActions",
        options: [
          {
            name: "Nearby",
            label: nearby.value ? "Nearby" : "All",
            container: false,
            href: "",
            handler: () => {
              nearby.value = !nearby.value;
            },
            icon: ArrowsPointingInIcon,
            iconProps: {},
            iconClass: () => {
              return nearby.value
                ? "bg-emerald-400"
                : "text-gray-500 hover:bg-gray-200";
            },
          },
        ],
      },
      {
        order: 2,
        name: "defaultMessages",
        options: defaultMessagesTabs,
      },
      joinedHubs.value.length > 0 ? teamTabs : null,
      {
        order: 4,
        name: "footerOptions",
        options: _.compact([
          {
            name: "Add a Team",
            container: false,
            href: "",
            handler: () => {
              if (signedIn.value) {
                changeView({
                  component: markRaw(TeamForm),
                  props: { teamId: null },
                });
              } else {
                secondaryPanelStore.promptToRegister();
              }
            },
            icon: PlusCircleIcon,
            iconProps: {},
            iconClass: () => {
              return "text-gray-500 hover:bg-gray-200";
            },
          },
          discoverView.value
            ? {
                name: "Discover",
                container: true,
                href: "",
                handler: () => {
                  changeView();
                },
                icon: SparklesIcon,
                iconProps: {},
                iconClass: (option) => {
                  return activeOption.value?.name === option.name
                    ? "text-white bg-indigo-600"
                    : "text-gray-500 hover:bg-gray-200";
                },
              }
            : null,
        ]),
      },
    ];

    return _.orderBy(_.compact(rawTabs), ["order"], ["asc"]);
  });

  function cleanUrl(url) {
    return url.replace("/api/v1/", "");
  }

  async function fetchHubs() {
    if (signedIn.value) {
      const hubsResponse = await api.get(`discussion_hubs`);

      if (hubsResponse?.data) {
        joinedHubs.value = hubsResponse.data;
      }
    }
  }

  async function joinHub({ contentType, contentId }) {
    if (signedIn.value) {
      const hubsResponse = await api.post(
        `discussion_hubs/${contentType}/${contentId}/members/@me?preview=false`,
      );

      if (hubsResponse?.data) {
        notificationsStore.addNotification("discussionHubJoined", 5);
        const hubId = hubsResponse.data.id;
        patchHub(hubsResponse.data);
        await nextTick();
        const joinedHubs = _.find(primaryNavigationOptions.value, {
          name: "joinedHubs",
        });
        const newActiveOption = _.find(joinedHubs.options, { matchId: hubId });
        activeOption.value = newActiveOption;
        newActiveOption.handler();
      }
    } else {
      secondaryPanelStore.promptToRegister();
    }
  }

  async function fetchTopics() {
    if (
      signedIn.value &&
      _.includes(
        ["DiscussionHub", "Property", "Company", "Contact"],
        activeOption.value?.matchType,
      )
    ) {
      const response = await api.get(
        `discussion_hubs/${activeHubId.value}/discussion_topics`,
      );

      if (response?.data) {
        channels.value = _.unionBy(response.data, channels.value, "id");
      }
    }
  }

  async function searchThreads() {
    if (activeTopicId.value) {
      const actionableTopicId =
        mainContent.value.content.threadParentTopicId ||
        activeTopicId.value;
      const response = await api.get(
        `discussion_topics/${actionableTopicId}/threads/search`,
      );

      return response?.data;
    }
  }

  async function fetchHubRoles() {
    if (
      signedIn.value &&
      _.includes(
        ["DiscussionHub", "Property", "Company", "Contact"],
        activeOption.value?.matchType,
      )
    ) {
      const response = await api.get(
        `discussion_hubs/${activeHubId.value}/discussion_roles`,
      );

      if (response?.data) {
        patchHubRoles(response.data);
      }
    }
  }

  async function fetchHubMembers() {
    if (
      signedIn.value &&
      _.includes(
        ["DiscussionHub", "Property", "Company", "Contact"],
        activeOption.value?.matchType,
      )
    ) {
      const response = await api.get(
        `discussion_hubs/${activeHubId.value}/hub_members`,
      );

      if (response?.data) {
        patchHubMembers(response.data);
      }
    }
  }

  async function fetchTopicPermissionOverwrites() {
    if (signedIn.value && activeTopicId.value) {
      const response = await api.get(
        `discussion_topics/${activeTopicId.value}/topic_members`,
      );

      if (response?.data) {
        patchTopicPermissionOverwrites(response.data);
      }
    }
  }

  function memberOptionsFor(member, context) {
    if (!member) return [];

    const addFriendOption = {
      name: "Add to Network",
      compactName: "Network",
      confirmation: false,
      handler: async () => {
        await userNetworkingRequest(member.userId);
        const updatedMember = _.cloneDeep(member);
        updatedMember.networked = "pending";
        patchHubMembers([updatedMember]);
      },
      menuComponent: null,
      icon: UserPlusIcon,
    };
    const pendingFriendOption = {
      name: "Request Pending",
      compactName: "Pending",
      confirmation: false,
      handler: () => {},
      menuComponent: null,
      icon: ClockIcon,
    };
    const alreadyFriendsOption = {
      name: "Friend",
      compactName: "Friend",
      confirmation: true,
      handler: () => {},
      menuComponent: markRaw(ComingSoon),
      icon: CheckIcon,
    };
    const directMessageOption = {
      name: "Message",
      compactName: "Message",
      confirmation: false,
      handler: () => {
        directMessage(member);
      },
      menuComponent: null,
      icon: SolidChatBubbleOvalLeftIcon,
    };
    const memberSettingsOption = {
      name: "More",
      compactName: "More",
      confirmation: false,
      handler: () => {},
      menuComponent: markRaw(ComingSoon),
      icon: EllipsisHorizontalIcon,
    };
    let rawOptions = [];

    switch (context) {
      case "detailsMenu":
        {
          if (member.networked === "accepted") {
            rawOptions = [
              isDesktop ? alreadyFriendsOption : null,
              memberSettingsOption,
            ];
          } else if (member.networked === "pending") {
            rawOptions = [
              isDesktop ? pendingFriendOption : null,
              memberSettingsOption,
            ];
          } else if (member.userId !== currentUser.value?.id) {
            rawOptions = [
              isDesktop ? addFriendOption : null,
              memberSettingsOption,
            ];
          } else {
            {
              rawOptions = [memberSettingsOption];
            }
          }
        }
        break;
      case "detailsMenuSecondaryButtons":
        {
          if (member.networked === "accepted") {
            rawOptions = [
              member.userId !== currentUser.value?.id
                ? directMessageOption
                : null,
              isDesktop ? null : alreadyFriendsOption,
            ];
          } else if (member.networked === "pending") {
            rawOptions = [
              member.userId !== currentUser.value?.id
                ? directMessageOption
                : null,
              isDesktop ? null : pendingFriendOption,
            ];
          } else if (member.userId !== currentUser.value?.id) {
            rawOptions = [
              directMessageOption,
              isDesktop ? null : addFriendOption,
            ];
          } else {
            rawOptions = [];
          }
        }
        break;
      default:
        break;
    }

    return _.compact(rawOptions);
  }

  async function patchTopicDisplayStatus(topicId, status) {
    if (signedIn.value) {
      const response = await api.post(
        `discussion_topics/${topicId}/discussion_topic_display_statuses`,
        { status },
      );

      if (response?.data?.id) {
        patchChannel(response.data);
      }
    }
  }

  async function fetchInvitations() {
    if (signedIn.value) {
      const response = await api.get(`discussion_hub_invitations`);

      if (response?.data) {
        hubInvitations.value = _.unionBy(
          response.data,
          hubInvitations.value,
          "id",
        );
      }
    }
  }

  function populateTopicInvitation(channel) {
    openSecondaryContentPanel({
      component: AddDiscussionTopicInvitees,
      props: {
        channel,
      },
    });
  }

  async function createTopicInvitation({ topicId, maxAge, maxUses }) {
    const payload = {
      maxAge,
      maxUses,
    };

    const response = await api.post(
      `discussion_topics/${topicId}/invitations`,
      payload,
    );

    if (response?.data) {
      patchInvitation(response.data);

      return response.data;
    }
  }

  async function sendTopicInvitation({ invitation, inviteeUser }) {
    const payload = {
      recipientToken: inviteeUser.token,
    };

    await api.patch(`discussion_hub_invitations/${invitation.token}`, payload);
  }

  async function fetchDirectMessages() {
    if (signedIn.value && activeOption.value?.name === "Direct Messages") {
      const response = await api.get(`direct_messages`);

      if (response?.data) {
        channels.value = _.unionBy(response.data, channels.value, "id");
      }
    }
  }

  async function fetchNetworkings() {
    if (signedIn.value) {
      const response = await api.get(`networkings`);

      if (response?.data) {
        const { data, pagy } = response.data;
        networkingPagyContainer.value.data = data;
        networkingPagyContainer.value.pagy = pagy;
      }
    }
  }

  async function loadNetworkings(paginationState) {
    if (signedIn.value && networkingsPagy.value.next) {
      const endpoint = cleanUrl(networkingsPagy.value.next_url);
      try {
        api.get(endpoint).then((json) => {
          const { data, pagy } = json.data;

          networkingPagyContainer.value.data = _.concat(
            networkingPagyContainer.value.data,
            data,
          );
          networkingPagyContainer.value.pagy = pagy;
          if (data.length < 50) paginationState.complete();
          else {
            paginationState.loaded();
          }
        });
      } catch (error) {
        paginationState.error();
      }
    } else {
      paginationState.complete();
    }
  }

  function openDirectMessage(topic) {
    rightEdgeComponent.value = markRaw(DirectMessagesContainer);
    mainContent.value = {
      contentType: "directMessage",
      content: topic,
    };
  }

  async function directMessage(user) {
    const response = await api.post(`direct_messages/${user.token}`);

    if (response?.data) {
      activeOption.value = directMessagesTab.value;
      directMessagesTab.value.handler();
      await nextTick();
      patchChannel(response.data);
      openDirectMessage(response.data);
      if (expandedView.value) {
        closeRightEdgeLevelTwoPanel();
      } else {
        closeBottomEdgeLevelTwoPanel();
      }
    }
  }

  async function groupDirectMessage(members) {
    const response = await api.post(`group_direct_messages`, {
      members,
    });

    if (response?.data) {
      closeSecondaryContentPanel();
      patchChannel(response.data);
      openDirectMessage(response.data);
    }
  }

  async function leaveDirectMessage(directMessage) {
    const response = await api.delete(`direct_messages/${directMessage.id}`);

    if (response?.data) {
      dropChannel(directMessage);
    }
  }

  function populateGroupDirectMessage() {
    openSecondaryContentPanel({
      component: AddDirectMessageMembers,
      props: {},
    });
  }

  async function fetchNetworkingRequests() {
    if (signedIn.value) {
      const response = await api.get(`pending_networking_requests`);

      if (response?.data) {
        networkingRequests.value = response.data;
      }
    }
  }

  async function userNetworkingRequest(userId) {
    const response = await api.post(`networkings`, {
      userId,
    });

    if (response?.data) {
      fetchNetworkings();
      notificationsStore.addNotification("linkRequestSubmitted");
    }
  }

  async function revokeNetworkingRequest(networkingId) {
    const response = await api.delete(
      `pending_networking_requests/${networkingId}`,
    );

    if (response?.data) {
      networkingRequests.value.sent = networkingRequests.value.sent.filter(
        (request) => request.id !== networkingId,
      );
    }
  }

  function dropReceivedNetworkingRequest(networkingId) {
    networkingRequests.value.received =
      networkingRequests.value.received.filter(
        (request) => request.id !== networkingId,
      );
  }

  async function acceptNetworkingRequest(networkingId) {
    const response = await api.patch(
      `networking_request_acceptances/${networkingId}`,
    );

    if (response?.data) {
      dropReceivedNetworkingRequest(networkingId);
      fetchNetworkings();
    }
  }

  async function rejectNetworkingRequest(networkingId) {
    const response = await api.patch(
      `networking_request_rejections/${networkingId}`,
    );

    if (response?.data) {
      dropReceivedNetworkingRequest(networkingId);
      fetchNetworkings();
    }
  }

  async function fetchRegions() {
    if (signedIn.value) {
      const regionsResponse = await api.get(`geography_regions`);

      if (regionsResponse?.data) {
        regionPagyContainer.value = regionsResponse.data;
      }
    }
  }

  async function loadRegions(paginationState) {
    if (signedIn.value && regionsPagy.value.next) {
      const endpoint = cleanUrl(regionsPagy.value.next_url);
      try {
        api.get(endpoint).then((json) => {
          const { data, pagy } = json.data;

          regionPagyContainer.value.data = _.concat(
            regionPagyContainer.value.data,
            data,
          );
          regionPagyContainer.value.pagy = pagy;
          if (data.length < 25) paginationState.complete();
          else {
            paginationState.loaded();
          }
        });
      } catch (error) {
        paginationState.error();
      }
    } else {
      paginationState.complete();
    }
  }

  async function hideRegion(region) {
    const hideResponse = await api.patch(
      `geography_region_hidings/${region.id}`,
    );

    if (hideResponse?.data) {
      regionPagyContainer.value.data = regionPagyContainer.value.data.filter(
        (r) => r.id !== region.id,
      );
    }
  }

  return {
    nearby,
    regionPagyContainer,
    regions,
    regionsPagy,
    fetchRegions,
    loadRegions,
    hideRegion,
    patchRegion,
    networkingPagyContainer,
    networkings,
    acceptedNetworkings,
    focalNetworkings,
    networkingCounterpart,
    networkingsPagy,
    fetchNetworkings,
    loadNetworkings,
    directMessage,
    groupDirectMessage,
    networkingRequests,
    fetchNetworkingRequests,
    revokeNetworkingRequest,
    acceptNetworkingRequest,
    rejectNetworkingRequest,
    categories,
    activeCategories,
    activeHubId,
    activeTopicId,
    channels,
    patchChannel,
    dropChannel,
    directHubChannels,
    threads,
    directMessages,
    leaveDirectMessage,
    openDirectMessage,
    populateGroupDirectMessage,
    primaryNavigationOptions,
    activeOption,
    activeView,
    lastView,
    rightEdgeComponent,
    mainContent,
    rightEdgeLevelTwoComponent,
    rightEdgeLevelTwoProps,
    levelTwoMainContent,
    rightEdgeLevelThreeComponent,
    rightEdgeLevelThreeProps,
    levelThreeMainContent,
    closeRightEdgeLevelThreePanel,
    selectionIsActive,
    activeSelectionOptionClass,
    bottomEdgeComponent,
    bottomEdgeProps,
    bottomEdgePosition,
    bottomEdgeLevelTwoComponent,
    bottomEdgeLevelTwoProps,
    bottomEdgeLevelTwoPosition,
    networkTabs,
    networkTab,
    expandedView,
    nearbyMessagesTab,
    directMessagesTab,
    dragging,
    instantUpload,
    instantUploadHandler,
    changeView,
    fetchHubs,
    fetchHubRoles,
    patchHubRoles,
    dropHubRole,
    fetchHubMembers,
    searchThreads,
    fetchTopicPermissionOverwrites,
    patchTopicPermissionOverwrites,
    presenceFor,
    propertyAppearancesFor,
    hubRoles,
    activeHubRoles,
    hubMembers,
    topicPermissionOverwrites,
    toggleChannelMembersList,
    joinHub,
    patchHub,
    dropHub,
    hubOptionsFor,
    topicOptionsFor,
    memberOptionsFor,
    fetchTopics,
    patchTopicDisplayStatus,
    hubInvitations,
    fetchInvitations,
    populateTopicInvitation,
    createTopicInvitation,
    sendTopicInvitation,
    fetchDirectMessages,
    selectActiveOption,
    closeRightEdgePanel,
    closeRightEdgeLevelTwoPanel,
    closeBottomEdgePanel,
    closeBottomEdgeLevelTwoPanel,
    closeTakeoverPanel,
    closeSecondaryContentPanel,
    openSecondaryContentPanel,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWePanelStore, import.meta.hot));
}
