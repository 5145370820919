<template>
  <div class="relative h-full flex flex-col">
    <button
      v-if="isDesktop"
      type="button"
      class="absolute top-2 right-2 rounded-full p-2 flex items-center justify-center text-gray-900 shadow-sm hover:text-gray-700"
      @click="cancel"
    >
      <XMarkIcon class="size-6" aria-hidden="true" />
    </button>
    <div class="p-4 flex flex-col items-center">
      <div class="flex items-center gap-x-2">
        <TopicTypeIcon
          v-if="mainContent.content?.topicType"
          :topic="mainContent.content"
          lock-size="size-3"
          icon-size="size-5"
        />
        <span class="text-base font-semibold text-gray-900">
          {{ mainContent.content?.name }}
        </span>
      </div>
      <a
        v-if="threadParentTopic"
        href=""
        class="mt-1 p-1 rounded-lg bg-gray-200 flex items-center gap-x-2"
        @click.prevent="viewParent"
      >
        <TopicTypeIcon
          :topic="threadParentTopic"
          lock-size="size-2"
          icon-size="size-4"
        />
        <span class="text-sm font-medium text-gray-500 hover:text-gray-400">
          {{ threadParentTopic.name }}
        </span>
      </a>

      <ol class="m-6 flex gap-x-8">
        <a
          v-for="option in wePanelStore.topicOptionsFor(
            mainContent.content,
            'detailsMenu',
          )"
          :key="option.name"
          class="flex flex-col items-center space-y-1 text-gray-500 hover:text-gray-600"
          href=""
          @click.prevent="option.handler"
        >
          <div class="flex items-center rounded-full bg-gray-200 p-2">
            <component :is="option.icon" class="size-6" />
          </div>
          <div class="text-xs text-center font-medium">
            {{ option.compactName }}
          </div>
        </a>
      </ol>
    </div>

    <div class="">
      <div class="border-b border-gray-200 flex justify-center">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <a
            v-for="option in wePanelStore.topicOptionsFor(
              mainContent.content,
              'detailsMenuSecondaryTabs',
            )"
            :key="option.name"
            href=""
            :class="[
              selectedTab?.name === option.name
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
              'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
            ]"
            :aria-current="
              selectedTab?.name === option.name ? 'page' : undefined
            "
            @click.prevent="selectedTab = option"
            >{{ option.compactName }}</a
          >
        </nav>
      </div>
    </div>

    <div class="flex-grow overflow-y-auto">
      <KeepAlive>
        <component :is="selectedTab.menuComponent" />
      </KeepAlive>
    </div>
  </div>
</template>

<script setup>
import TopicTypeIcon from "@/components/we/channels-format/TopicTypeIcon.vue";
import ChannelMessagesContainer from "@/components/we/main-content-views/ChannelMessagesContainer.vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed, markRaw, ref } from "vue";

const wePanelStore = useWePanelStore();
const { mainContent, rightEdgeComponent, channels } = storeToRefs(wePanelStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const secondaryTabs = computed(() =>
  wePanelStore.topicOptionsFor(
    mainContent.value?.content,
    "detailsMenuSecondaryTabs",
  ),
);
const selectedTab = ref(secondaryTabs.value[0]);

const threadParentTopic = computed(() => {
  if (mainContent.value.contentType === "thread") {
    const parentId = mainContent.value.content.threadParentTopicId;

    return _.find(channels.value, { id: parentId });
  } else {
    return null;
  }
});

function cancel() {
  wePanelStore.closeSecondaryContentPanel();
}

function viewParent() {
  rightEdgeComponent.value = markRaw(ChannelMessagesContainer);
  mainContent.value = {
    contentType: "channel",
    content: threadParentTopic.value,
  };
  cancel();
}
</script>
