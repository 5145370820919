<template>
  <div class="p-2 flex flex-col justify-end gap-y-4">
    <div
      class="rounded-full size-14 bg-gray-300 flex items-center justify-center"
    >
      <ChatBubbleLeftRightIcon class="text-white size-8" />
    </div>

    <div>
      <label for="thread-name" class="block text-sm/6 font-medium text-gray-900"
        >Thread Name</label
      >
      <div class="mt-1">
        <input
          id="thread-name"
          v-model="threadName"
          type="text"
          name="thread-name"
          class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
          placeholder="New Thread"
        />
      </div>
    </div>

    <div>
      <SwitchGroup as="div" class="flex items-center justify-between">
        <span class="flex grow flex-col">
          <SwitchLabel
            as="div"
            class="flex items-center space-x-1 text-sm/6 font-medium text-gray-900"
            passive
          >
            <LockClosedIcon class="size-5" />
            <span>Private Thread</span>
          </SwitchLabel>
          <SwitchDescription as="span" class="text-sm text-gray-500"
            >Only people you invite can see.</SwitchDescription
          >
        </span>
        <Switch
          v-model="privateThread"
          :class="[
            privateThread ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
          ]"
        >
          <span class="sr-only">Private topic</span>
          <span
            :class="[
              privateThread ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none relative inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            ]"
          >
            <span
              :class="[
                privateThread
                  ? 'opacity-0 duration-100 ease-out'
                  : 'opacity-100 duration-200 ease-in',
                'absolute inset-0 flex size-full items-center justify-center transition-opacity',
              ]"
              aria-hidden="true"
            >
              <svg class="size-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span
              :class="[
                privateThread
                  ? 'opacity-100 duration-200 ease-in'
                  : 'opacity-0 duration-100 ease-out',
                'absolute inset-0 flex size-full items-center justify-center transition-opacity',
              ]"
              aria-hidden="true"
            >
              <svg
                class="size-3 text-indigo-600"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path
                  d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                />
              </svg>
            </span>
          </span>
        </Switch>
      </SwitchGroup>
    </div>
  </div>
</template>

<script setup>
import {
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import { LockClosedIcon } from "@heroicons/vue/24/solid";
import { computed } from "vue";
import _ from "lodash";
import { ChatBubbleLeftRightIcon } from "@heroicons/vue/20/solid";
import { useWeThreadFormStore } from "@/stores/weThreadForm";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";

const props = defineProps(["context"]);

const wePanelStore = useWePanelStore();
const { activeTopicId, mainContent } = storeToRefs(wePanelStore);
const weThreadFormStore = useWeThreadFormStore();
const { draftThreads } = storeToRefs(weThreadFormStore);

const activeDraft = computed(() => {
  if (mainContent.value?.content) {
    return _.find(draftThreads.value, {
      parentTopicId: activeTopicId.value,
    });
  } else {
    return null;
  }
});
const threadName = computed({
  get() {
    return activeDraft.value?.name;
  },
  set(str) {
    const newDraft = _.cloneDeep(activeDraft.value);
    newDraft.name = str;
    weThreadFormStore.patchDrafts(newDraft);
  },
});
const privateThread = computed({
  get() {
    return activeDraft.value?.isPrivate;
  },
  set(bool) {
    const newDraft = _.cloneDeep(activeDraft.value);
    newDraft.isPrivate = bool;
    weThreadFormStore.patchDrafts(newDraft);
  },
});
</script>
