<template>
  <SwitchGroup as="div" class="flex pt-6">
    <SwitchLabel
      as="dt"
      class="text-sm flex items-center space-x-1 pr-6 font-medium text-gray-500"
      :class="workspaceLayout === 'sideBySide' ? '' : 'w-64'"
      passive
    >
      <span>{{ networkingConsentType }}</span></SwitchLabel
    >
    <dd class="flex flex-auto items-center justify-end">
      <Switch
        v-model="internalModelValue"
        :class="[
          internalModelValue ? 'bg-indigo-600' : 'bg-gray-200',
          'flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
        ]"
      >
        <span
          aria-hidden="true"
          :class="[
            internalModelValue ? 'translate-x-3.5' : 'translate-x-0',
            'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out',
          ]"
        />
      </Switch>
    </dd>
  </SwitchGroup>
</template>

<script setup>
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

import { storeToRefs } from "pinia";
import { ShareIcon } from "@heroicons/vue/24/outline";
import { ref, watch, watchEffect } from "vue";

const props = defineProps(["modelValue", "networkingConsentType"]);
const emits = defineEmits(["update:modelValue"]);

const internalModelValue = ref(props.modelValue);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);

watchEffect(() => {
  emits("update:modelValue", internalModelValue.value);
});

watch(
  () => props.modelValue,
  (newValue) => {
    internalModelValue.value = newValue;
  },
);
</script>
