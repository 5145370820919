<template>
  <div class="text-center font-semibold" :class="isDesktop ? '' : 'text-lg'">{{ $filters.initials(team.name) }}</div>
</template>

<script setup>
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";

defineProps(["team"]);

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
</script>
