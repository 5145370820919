<template>
  <li
    class="w-full"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <button
      v-close-popper
      class="w-full rounded-lg border border-gray-300 flex items-center justify-between p-3 hover:bg-gray-100"
      @click.prevent="view"
    >
      <div class="flex flex-col items-start">
        <div class="text-sm font-bold truncate">{{ thread.name }}</div>
        <div class="flex items-center space-x-2 text-xs text-gray-500">
          <div>Started by...</div>
          <div>Last active...</div>
        </div>
      </div>
      <div v-if="expandedView" class="isolate flex -space-x-1 overflow-hidden">
        <AvatarPhoto
          v-for="membership in _.take(directMembers, 5)"
          :key="membership.memberId"
          :person="
            membership.linkedContactDataField?.fieldContent || membership
          "
          shape-size="size-7"
          text-size="sm"
          :hide-presence="true"
        />
        <div
          v-if="overflowMembers"
          class="flex-shrink-0 relative z-50 inline-flex items-center justify-center px-0.5 h-6 min-w-6 rounded-full bg-gray-700 text-white text-xs font-medium"
        >
          +{{ overflowDelta }}
        </div>
      </div>
    </button>
  </li>
</template>

<script setup>
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import { computed, markRaw, ref } from "vue";
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import ChannelMessagesContainer from "@/components/we/main-content-views/ChannelMessagesContainer.vue";
import _ from "lodash";

const props = defineProps(["thread", "members"]);
const wePanelStore = useWePanelStore();
const { expandedView, rightEdgeComponent, mainContent, hubMembers } =
  storeToRefs(wePanelStore);

const isHovered = ref(false);

const directMembers = computed(() => {
  const memberUserIds = props.members
    .filter(({ threadId }) => threadId === props.thread.id)
    .map(({ userId }) => userId);

  return hubMembers.value.filter(({ userId }) =>
    _.includes(memberUserIds, userId),
  );
});

const overflowMembers = computed(() => {
  return directMembers.value.length > 5;
});
const overflowDelta = computed(() => directMembers.value.length - 5);

function view() {
  rightEdgeComponent.value = markRaw(ChannelMessagesContainer);
  mainContent.value = {
    contentType: "thread",
    content: props.thread,
  };
  if (!expandedView.value) {
    wePanelStore.closeBottomEdgeLevelTwoPanel();
    wePanelStore.closeBottomEdgePanel();
  }
}
</script>
