<template>
  <div class="h-full p-8 space-y-4 overflow-y-auto">
    <button
      v-if="!expandedView && isDesktop"
      type="button"
      class="absolute top-2 right-2 rounded-full p-2 flex items-center justify-center text-gray-900 shadow-sm hover:text-gray-700"
      @click="cancel"
    >
      <XMarkIcon class="size-6" aria-hidden="true" />
    </button>
    <div class="w-full flex flex-col space-y-2">
      <WeTeamIcon
        :team="hub"
        class="flex items-center justify-center rounded-lg size-16 bg-gray-200"
      />
      <h1 class="text-xl font-medium text-gray-700">
        {{ hub.name }}
      </h1>
      <div class="flex items-center gap-x-3">
        <div class="flex items-center gap-x-0.5 text-xs text-gray-500">
          <div class="rounded-full size-2 bg-gray-400" />
          <div>{{ hub.membersCount }} Members</div>
        </div>
        <div class="flex items-center gap-x-0.5 text-xs text-gray-500">
          <div class="rounded-full size-2 bg-green-400" />
          <div>{{ hub.onlineMembersCount }} Online</div>
        </div>
      </div>
    </div>
    <ol class="m-6 flex justify-around gap-x-8">
      <a
        v-for="option in horizontalOptions"
        :key="option.name"
        class="flex flex-col items-center space-y-1 text-gray-500 hover:text-gray-600"
        href=""
        @click.prevent="option.handler"
      >
        <div class="flex items-center rounded-full bg-gray-200 p-2">
          <component :is="option.icon" class="size-6" />
        </div>
        <div class="text-xs text-center font-medium">
          {{ option.compactName }}
        </div>
      </a>
    </ol>
    <ul
      v-for="menuActions in wePanelStore.hubOptionsFor(
        hub,
        'detailsMenuSecondaryTabs',
      )"
      :key="menuActions.name"
      class="divide-y divide-gray-300"
    >
      <li
        v-for="(action, index) in menuActions.options"
        :key="action.name"
        class="w-full flex p-3"
        :class="[
          menuActions.options.length === 1 ? 'rounded-md' : '',
          menuActions.options.length > 1 && index === 0 ? 'rounded-t-md' : '',
          menuActions.options.length > 1 &&
          index === menuActions.options.length - 1
            ? 'rounded-b-md'
            : '',
          action.confirmation ? 'bg-red-100' : 'bg-gray-100',
        ]"
      >
        <button
          type="button"
          class="w-full flex items-center font-medium gap-x-3"
          :class="[action.confirmation ? 'text-red-400' : 'text-gray-500']"
          @click.prevent="action.handler"
        >
          <component :is="action.icon" class="size-6" aria-hidden="true" />
          <div class="">{{ action.name }}</div>
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { storeToRefs } from "pinia";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import WeTeamIcon from "@/components/we/WeTeamIcon.vue";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["hub"]);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const wePanelStore = useWePanelStore();
const { expandedView } = storeToRefs(wePanelStore);

const horizontalOptions = computed(() => {
  const optionsObject = _.find(
    wePanelStore.hubOptionsFor(props.hub, "detailsMenu"),
    { name: "hubOptions" },
  );

  return optionsObject.options;
});

function cancel() {
  wePanelStore.closeSecondaryContentPanel();
}
</script>
