<template>
  <div
    class="p-2 flex flex-col gap-y-3"
    :class="[expandedView ? 'w-72' : 'w-full']"
  >
    <div class="flex justify-end items-center gap-x-2">
      <button
        v-for="option in wePanelStore.memberOptionsFor(
          membership,
          'detailsMenu',
        )"
        :key="option.name"
        v-tooltip="option.name"
        type="button"
        class="flex items-center rounded-full bg-gray-200 p-2 text-gray-500 hover:text-gray-600"
        @click.prevent="option.handler"
      >
        <component :is="option.icon" class="size-4" />
      </button>
      <button
        v-if="isDesktop && !expandedView"
        type="button"
        class="rounded-full p-2 flex items-center justify-center text-gray-900 shadow-sm hover:text-gray-700"
        @click="cancel"
      >
        <XMarkIcon class="size-6" aria-hidden="true" />
      </button>
    </div>

    <div class="w-full px-2 relative flex flex-col gap-1">
      <div v-if="membership.linkedContactDataField" class="absolute inset-0" />
      <DataField
        v-if="membership.linkedContactDataField"
        :data-field="membership.linkedContactDataField"
        primary-text-path="fieldContent.name"
        text-classes="text-sm font-semibold"
        text-styles=""
      />
      <template v-else>
        <AvatarPhoto
          :person="membership"
          shape-size="h-12 w-12"
          text-size="xl"
        />
      </template>
      <h2 class="text-lg text-gray-700 font-semibold">
        {{ membership.name }}
      </h2>
    </div>

    <ol v-if="!expandedView" class="px-2 flex gap-x-8 self-center">
      <a
        v-for="option in wePanelStore.memberOptionsFor(
          membership,
          'detailsMenuSecondaryButtons',
        )"
        :key="option.name"
        class="flex flex-col items-center space-y-1 text-gray-500 hover:text-gray-600"
        href=""
        @click.prevent="option.handler"
      >
        <div class="flex items-center rounded-full bg-gray-200 p-2">
          <component :is="option.icon" class="size-6" />
        </div>
        <div class="text-xs text-center font-medium">
          {{ option.compactName }}
        </div>
      </a>
    </ol>

    <div
      v-if="wePanelStore.propertyAppearancesFor(membership).length > 0"
      class="mx-1 p-2 rounded-lg bg-gray-100 flex flex-col space-y-3"
    >
      <div class="flex items-center text-gray-500 space-x-1">
        <BuildingOffice2Icon class="size-4" />
        <span class="text-xs">Viewing Properties</span>
      </div>
      <div class="flex items-center flex-wrap gap-1">
        <DataField
          v-for="dataField in wePanelStore.propertyAppearancesFor(membership)"
          :key="dataField.localId"
          :data-field="dataField"
          :no-dropdown="!isDesktop"
          text-classes="text-xs font-medium"
          text-styles=""
        />
      </div>
    </div>

    <MessageInputContainer
      v-if="expandedView && currentUser?.id !== membership.userId"
      context="channelMemberDetail"
      :membership="membership"
      class=""
      @message-sent="emit('message-sent')"
    />
  </div>
</template>

<script setup>
import AvatarPhoto from "@/components/users/AvatarPhoto.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import MessageInputContainer from "@/components/we/message-input/MessageInputContainer.vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { useWePanelStore } from "@/stores/wePanel";
import { useUserStore } from "@/stores/user";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { BuildingOffice2Icon } from "@heroicons/vue/20/solid";
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";

const props = defineProps(["membership"]);
const emit = defineEmits(["message-sent"]);

const wePanelStore = useWePanelStore();
const { expandedView } = storeToRefs(wePanelStore);
const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

function cancel() {
  wePanelStore.closeBottomEdgeLevelTwoPanel();
}
</script>
