<template>
  <div class="flex h-full w-full flex-col overflow-hidden">
    <div
      class="mx-auto flex h-full w-full max-w-7xl items-start gap-x-8"
      :class="[expandedView ? 'px-8 pt-10' : 'p-4']"
    >
      <main class="flex-1 h-full overflow-y-auto">
        <!-- Main area -->
        <div class="space-y-12 pb-12">
          <div
            v-if="sortableRoles.length === 0"
            class="flex flex-col items-center gap-y-3"
            :class="[expandedView ? 'border-b border-gray-900/10 pb-12' : '']"
          >
            <h1 class="text-lg/7 font-semibold text-gray-900">
              Organize your members
            </h1>
            <h2 class="text-sm text-gray-500">
              Use roles to group your Hub members and assign permissions.
            </h2>
            <button
              type="button"
              class="rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              @click="weHubSettingsStore.createRole()"
            >
              Create Role
            </button>
          </div>
          <div class="">
            <template v-if="sortableRoles.length > 0">
              <h2 class="text-base/7 font-semibold text-gray-900">Roles</h2>
              <p class="mt-1 text-sm/6 text-gray-600">
                Use roles to group your Hub members and assign permissions.
              </p>
            </template>

            <button
              class="mt-10 relative w-full rounded-lg flex justify-between gap-x-6 p-5 border border-gray-300 bg-gray-50 hover:bg-gray-100"
              @click="weHubSettingsStore.viewRoleDetails()"
            >
              <div class="flex min-w-0 gap-x-4">
                <div class="rounded-full p-2 bg-white">
                  <UsersIcon class="size-8 text-gray-400" aria-hidden="true" />
                </div>
                <div class="min-w-0 flex-auto">
                  <p class="text-sm/6 font-semibold text-gray-900">
                    Default Permissions
                  </p>
                  <p class="mt-1 flex text-xs/5 text-gray-500">
                    @everyone&nbsp;&middot;&nbsp;applies to all Hub members
                  </p>
                </div>
              </div>
              <div class="flex shrink-0 items-center gap-x-4">
                <ChevronRightIcon
                  class="size-5 flex-none text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </button>
          </div>
        </div>

        <ul
          v-if="sortableRoles.length > 0"
          role="list"
          class="w-full divide-y divide-gray-100"
        >
          <h2 class="flex items-center text-gray-500 font-medium">
            <span
              class="uppercase text-sm"
              :class="[expandedView ? 'w-1/2' : '']"
              >Roles&nbsp;&dash;&nbsp;{{ sortableRoles.length }}</span
            >
            <span v-if="expandedView" class="uppercase text-sm">Members</span>
          </h2>
          <li
            v-for="role in sortableRoles"
            :key="role.id"
            class="relative flex justify-between py-5"
            @mouseenter="hover(role.id)"
            @mouseleave="stopHovering"
          >
            <div
              class="flex gap-x-4 pr-6"
              :class="[expandedView ? 'w-1/2 flex-none' : 'items-center']"
            >
              <button
                v-if="hovering === role.id"
                type="button"
                class="pl-2 text-gray-400 cursor-grab z-10"
              >
                <i class="fa-solid fa-grip-vertical"></i>
              </button>
              <TagIcon
                class="size-6 flex-none"
                :class="[weHubSettingsStore.colorFor(role)]"
              />
              <div class="min-w-0 flex-auto">
                <p class="text-sm/6 font-semibold text-gray-900">
                  <a
                    href=""
                    @click.prevent="weHubSettingsStore.viewRoleDetails(role)"
                  >
                    <span class="absolute inset-x-0 -top-px bottom-0" @click="weHubSettingsStore.viewRoleDetails(role)" />
                    {{ role.name }}
                  </a>
                </p>
                <p
                  v-if="!expandedView"
                  class="mt-1 flex text-xs/5 text-gray-500"
                >
                  <a href="" class="relative truncate" @click.prevent
                    >{{ role.membersCount }} Members</a
                  >
                </p>
              </div>
            </div>
            <div
              class="flex items-center justify-between gap-x-2"
              :class="[expandedView ? 'w-1/2' : 'flex-none']"
            >
              <div class="" :class="[expandedView ? '' : 'hidden']">
                <div class="flex items-center gap-x-1">
                  <span class="text-sm/6 text-gray-900">{{
                    role.membersCount
                  }}</span>
                  <UserIcon class="size-6 text-gray-700" />
                </div>
              </div>
              <div class="flex items-center gap-x-4">
                <DiscussionRoleOptionsDropdown
                  v-if="expandedView"
                  :role="role"
                />
                <ChevronRightIcon
                  class="size-5 flex-none text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </li>
        </ul>
      </main>
    </div>
  </div>
</template>

<script setup>
import { ChevronRightIcon, UserIcon } from "@heroicons/vue/20/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useWeHubSettingsStore } from "@/stores/weHubSettings";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import _ from "lodash";
import { TagIcon, UsersIcon } from "@heroicons/vue/24/solid";
import DiscussionRoleOptionsDropdown from "@/components/we/settings-views/discussion-hub/discussion-role/DiscussionRoleOptionsDropdown.vue";

const wePanelStore = useWePanelStore();
const { expandedView } = storeToRefs(wePanelStore);
const weHubSettingsStore = useWeHubSettingsStore();
const { sortableRoles } = storeToRefs(weHubSettingsStore);

const hovering = ref(null);

function hover(id) {
  stopHovering.cancel();
  hovering.value = id;
}
const stopHovering = _.debounce(function () {
  setTimeout(() => {
    hovering.value = null;
  }, 250);
}, 750);
</script>
