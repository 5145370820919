<template>
  <ChannelHeader :context="context" class="" />
  <CreateThreadForm v-if="creatingThread" class="flex-grow" />
  <MessageListContainer v-else :context="context" class="flex-grow" />
  <MessageInputContainer :context="context" class="" />
</template>

<script setup>
import ChannelHeader from "@/components/we/main-content/ChannelHeader.vue";
import MessageListContainer from "@/components/we/main-content/MessageListContainer.vue";
import MessageInputContainer from "@/components/we/message-input/MessageInputContainer.vue";
import { computed } from "vue";
import CreateThreadForm from "../main-content/CreateThreadForm.vue";

const props = defineProps(["context"]);

const creatingThread = computed(
  () => props.context?.scenario === "createThread",
);
</script>
