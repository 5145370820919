<template>
  <div class="px-3 divide-y divide-gray-300">
    <ol
      v-for="{ options } in wePanelStore.hubOptionsFor(
        activeOption?.matchContent,
        'header',
      )"
      :key="options.name"
      class="flex flex-col py-2 gap-y-2"
    >
      <li v-for="option in options" :key="option.name">
        <DiscussionTopicInvitationDropdown
          v-if="expandedView && option.name === 'Invite People'"
          :channel="inviteChannel"
          placement="right"
          @open="popUpOpen = true"
          @closed="popUpOpen = false"
        >
          <template #button>
            <button
              v-tooltip.top-end="'Create Invite'"
              type="button"
              class="w-full flex items-center gap-x-2"
              @click.stop.prevent
            >
              <component
                :is="option.icon"
                class="size-5"
                :class="[
                  option.confirmation
                    ? 'text-red-400 hover:text-red-500'
                    : 'text-gray-400 hover:text-gray-500',
                ]"
              />
              <span
                class="text-sm font-semibold"
                :class="[
                  option.confirmation
                    ? 'text-red-400 hover:text-red-500'
                    : 'text-gray-400 hover:text-gray-500',
                ]"
                >{{ option.name }}</span
              >
            </button>
          </template>
        </DiscussionTopicInvitationDropdown>
        <a
          v-else
          v-close-popper
          class="flex items-center gap-x-2"
          href=""
          @click.prevent="option.handler"
        >
          <component
            :is="option.icon"
            class="size-5"
            :class="[
              option.confirmation
                ? 'text-red-400 hover:text-red-500'
                : 'text-gray-400 hover:text-gray-500',
            ]"
          />
          <span
            class="text-sm font-semibold"
            :class="[
              option.confirmation
                ? 'text-red-400 hover:text-red-500'
                : 'text-gray-400 hover:text-gray-500',
            ]"
            >{{ option.name }}</span
          >
        </a>
      </li>
    </ol>
  </div>
</template>

<script setup>
import DiscussionTopicInvitationDropdown from "@/components/we/channels-format/DiscussionTopicInvitationDropdown.vue";
import { useWePanelStore } from "@/stores/wePanel";
import { storeToRefs } from "pinia";
import _ from "lodash";

const wePanelStore = useWePanelStore();
const { activeOption, expandedView, channels } = storeToRefs(wePanelStore);

const invitableChannels = channels.value.filter(
  (channel) =>
    channel.hubId === activeOption.value.matchContent.id &&
    channel.contentType !== "category" &&
    !channel.isPrivate,
);
const inviteChannel = _.find(invitableChannels, function ({ name, isPrivate }) {
  return name === "general" || !isPrivate;
});
</script>
