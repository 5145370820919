<template>
  <div
    class="relative h-full overflow-y-auto"
    :class="[expandedView ? 'px-2 py-6' : 'p-6']"
  >
    <div class="space-y-6">
      <div
        class="w-full flex"
        :class="[
          expandedView ? 'items-center space-x-2' : 'flex-col space-y-2',
        ]"
      >
        <div class="" :class="[expandedView ? 'flex-grow' : 'w-full']">
          <div class="grid w-full grid-cols-1">
            <input
              v-model="query"
              type="search"
              name="search"
              class="col-start-1 row-start-1 block w-full rounded-md bg-white py-1.5 pl-10 pr-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
              placeholder="Search Members"
            />
            <MagnifyingGlassIcon
              class="pointer-events-none col-start-1 row-start-1 ml-3 size-5 self-center text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
        <button
          type="button"
          class="rounded-md bg-indigo-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          :class="[expandedView ? '' : 'w-full']"
          @click="addMembers"
        >
          Add Members
        </button>
      </div>

      <div class="flex flex-col space-y-3">
        <div
          v-if="loaded && filteredMembers.length === 0"
          class="flex items-center space-x-2 text-gray-500"
        >
          <UsersIcon class="size-6" />
          <div class="text-sm font-medium">No members were found.</div>
        </div>
        <template v-if="expandedView">
          <ChannelMemberListElement
            v-for="member in filteredMembers"
            :key="member.id"
            :membership="member"
            :removable="true"
            @remove="confirmRemove(member)"
          />
        </template>
        <template v-else>
          <CompactChannelMemberListElement
            v-for="member in filteredMembers"
            :key="member.id"
            :membership="member"
            :removable="true"
            context="addMembersForm"
            @remove="confirmRemove(member)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import DiscussionAddMembersForm from "@/components/we/channels-format/DiscussionAddMembersForm.vue";
import ChannelMemberListElement from "@/components/we/channels-format/ChannelMemberListElement.vue";
import CompactChannelMemberListElement from "@/components/we/channels-format/CompactChannelMemberListElement.vue";
import { MagnifyingGlassIcon, UsersIcon } from "@heroicons/vue/24/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useWeHubSettingsStore } from "@/stores/weHubSettings";
import { useModalStore } from "@/stores/modal";
import { storeToRefs } from "pinia";
import { computed, markRaw, onMounted, ref } from "vue";
import api from "@/router/api";
import _ from "lodash";

const wePanelStore = useWePanelStore();
const { expandedView, activeHubId, rightEdgeLevelThreeComponent } =
  storeToRefs(wePanelStore);
const weHubSettingsStore = useWeHubSettingsStore();
const { editableRole, selectedTabLayeredComponents } =
  storeToRefs(weHubSettingsStore);

const roleMembers = ref([]);
const query = ref("");
const filteredMembers = computed(() =>
  query.value === ""
    ? roleMembers.value
    : roleMembers.value.filter((person) => {
        const userNameMatch = person.name
          .toLowerCase()
          .includes(query.value.toLowerCase());
        const contactNameMatch = person.linkedContactDataField?.fieldContent
          ?.name
          ? person.linkedContactDataField.fieldContent.name
              .toLowerCase()
              .includes(query.value.toLowerCase())
          : false;

        return userNameMatch || contactNameMatch;
      }),
);
const loaded = ref(false);

onMounted(async () => {
  await fetchMembers();
});

async function fetchMembers() {
  loaded.value = false;
  const response = await api.get(
    `discussion_hubs/${activeHubId.value}/discussion_roles/${editableRole.value.id}/role_members`,
  );

  if (response?.data) {
    roleMembers.value = response.data;
    loaded.value = true;
  }
}

const modalStore = useModalStore();
const { modalPayload, confirmationPayload } = storeToRefs(modalStore);

function addMembers() {
  if (expandedView.value) {
    modalPayload.value = {
      size: "base",
      theme: "light",
      component: markRaw(DiscussionAddMembersForm),
      props: {
        context: "roleMembersList",
      },
      afterClose: fetchMembers,
      afterCloseDestination: null,
    };
  } else {
    weHubSettingsStore.addLayeredComponent(DiscussionAddMembersForm);

    rightEdgeLevelThreeComponent.value = _.last(
      selectedTabLayeredComponents.value,
    );
  }
}

function confirmRemove(membership) {
  const callback = async () => {
    await weHubSettingsStore.deleteRoleMember(membership);

    roleMembers.value = roleMembers.value.filter(
      (member) => member.memberId !== membership.memberId,
    );
  };

  confirmationPayload.value = {
    title: `Remove Role Member: ${membership.linkedContactDataField?.fieldContent?.name || membership.name}`,
    message:
      "Are you sure you want to remove this member from the role? This action cannot be undone.",
    affirmText: "Remove",
    affirmCallback: callback,
  };
}
</script>
