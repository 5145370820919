<template>
  <li v-if="isVisible" class="w-full flex flex-col px-2">
    <div class="flex">
      <a
        href=""
        class="w-full p-1 rounded-md flex items-center justify-between hover:bg-gray-300 gap-x-2 z-10"
        :class="[wePanelStore.activeSelectionOptionClass(channel)]"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
        @click.prevent="view"
      >
        <div
          class="w-full overflow-hidden flex items-center space-x-2 text-sm text-gray-700 font-medium hover:text-gray-600"
        >
          <TopicTypeIcon
            :topic="channel"
            lock-size="size-3"
            icon-size="size-5"
            class="my-1"
          />
          <div v-tooltip="channel.name" class="truncate">{{ channel.name }}</div>
        </div>
        <div
          v-if="
            popUpOpen || isHovered || wePanelStore.selectionIsActive(channel)
          "
          class="flex items-center gap-x-1"
        >
          <DiscussionTopicInvitationDropdown
            v-if="expandedView"
            :channel="channel"
            @open="popUpOpen = true"
            @closed="popUpOpen = false"
          >
            <template #button>
              <button
                v-tooltip.top-end="'Create Invite'"
                type="button"
                class="flex-shrink-0 inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                @click.stop.prevent
              >
                <UserPlusIcon class="size-5" />
              </button>
            </template>
          </DiscussionTopicInvitationDropdown>
          <button
            v-else-if="isDesktop"
            v-tooltip.top-end="'Create Invite'"
            type="button"
            class="flex-shrink-0 inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            @click.stop.prevent="wePanelStore.populateTopicInvitation(channel)"
          >
            <UserPlusIcon class="size-5" />
          </button>
          <button
            v-if="isDesktop && canManage"
            v-tooltip.top-end="'Edit Channel'"
            type="button"
            class="flex-shrink-0 inline-flex items-center text-gray-500 hover:text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            @click.stop.prevent="viewSettings"
          >
            <Cog8ToothIcon class="size-5" />
          </button>
        </div>
      </a>
    </div>
    <ol class="relative">
      <span
        class="hidden absolute top-0 left-3 -ml-px h-full w-0.5 bg-gray-300"
        aria-hidden="true"
      ></span>
      <ThreadListElement
        v-for="thread in threadsFor(channel)"
        :key="thread.id"
        :thread="thread"
      />
    </ol>
  </li>
</template>

<script setup>
import { Cog8ToothIcon, UserPlusIcon } from "@heroicons/vue/24/solid";
import { useWePanelStore } from "@/stores/wePanel";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import { computed, markRaw, nextTick, ref } from "vue";
import TwoColumnTopicSettingsForm from "@/components/we/channels-format/TwoColumnTopicSettingsForm.vue";
import BottomEdgeTopicSettingsForm from "@/components/we/channels-format/BottomEdgeTopicSettingsForm.vue";
import DiscussionTopicInvitationDropdown from "@/components/we/channels-format/DiscussionTopicInvitationDropdown.vue";
import ChannelMessagesContainer from "@/components/we/main-content-views/ChannelMessagesContainer.vue";
import ThreadListElement from "@/components/we/channels-format/ThreadListElement.vue";
import TopicTypeIcon from "@/components/we/channels-format/TopicTypeIcon.vue";
import _ from "lodash";

const props = defineProps(["channel"]);
const wePanelStore = useWePanelStore();
const {
  expandedView,
  rightEdgeComponent,
  rightEdgeLevelTwoComponent,
  mainContent,
  categories,
  threads,
} = storeToRefs(wePanelStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const popUpOpen = ref(false);
const isHovered = ref(false);
const storeCategory = computed(() =>
  _.find(categories.value, { id: props.channel.categoryId }),
);
const isVisible = computed(
  () =>
    !storeCategory.value?.categoryCollapsed ||
    wePanelStore.selectionIsActive(props.channel) ||
    (props.channel.contentType !== "category" && !props.channel.categoryId),
);
function threadsFor(channel) {
  return threads.value.filter(
    (thread) => thread.threadParentTopicId === channel.id,
  );
}

const canManage = computed(() => true);

function view() {
  rightEdgeComponent.value = markRaw(ChannelMessagesContainer);
  mainContent.value = {
    contentType: "channel",
    content: props.channel,
  };
  if (expandedView.value && !rightEdgeLevelTwoComponent.value) {
    wePanelStore.toggleChannelMembersList();
  }
}

async function viewSettings() {
  view();
  await nextTick();

  wePanelStore.openSecondaryContentPanel({
    component: expandedView.value
      ? TwoColumnTopicSettingsForm
      : BottomEdgeTopicSettingsForm,
    props: {
      overflowYOverride: true
    },
  });
}
</script>
